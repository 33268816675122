import { useEffect, useRef, useState } from "react"
import { Control, ControlContent } from "../component/control.jsx"
import { styled } from "../styled.js"
import { useSurfControl } from "../context.jsx"

const FormStyled = styled( 'form', {

})

const InputStyled = styled('input', {
  border: 'none !important',
  margin: '0.125em',
  background: 'transparent',
  color: 'inherit',
  '&:focus': {
    outline: 'none !important'
  },
})

export function Input( { path, ...remain } ) {

  const [ focus, setFocus ] = useState( false )
  const transform =  a => a
  const { state, emitEvent, mergeData } = useSurfControl( { path, transform, delay: 5 } )
  const value = String( state )

  const inputRef = useRef()

  const onFocus = () => {
    setFocus( true  )
  }

  const onBlur = () => {
    setFocus( false  )
  }

  const onChange = () => {

  }

  const onSubmit = ( event ) => {
    const { value } = inputRef.current
    mergeData( value, path )
    event.preventDefault()
  }

  useEffect( () => {
    console.log('input value', value )
    if ( inputRef.current && !focus ) {
      inputRef.current.value = value
    }
  }, [ value ] )

  return (<Control {...remain}>
    <ControlContent selected={focus}>
      <FormStyled {...{onSubmit}}>
        <InputStyled ref={inputRef} {...{onFocus,onBlur,onChange}}/>
      </FormStyled>
    </ControlContent>
  </Control>)
}