import { useMemo } from "react"
import { isRealNumber, max, min } from "./math"
import { isAngleClosed, resolveRoundingAndOffset } from "./radialUtil"
import { isDefined, isNumber, isObject, isString } from "../util"

import elementStyles from './elements.module.css'

type OffsetRoundingArgs = {
  offset?:number,

  offsetX?:number,
  offsetY?:number,

  offsetT?:number,
  offsetB?:number,
  offsetL?:number,
  offsetR?:number,

  round?:number,

  roundT?:number,
  roundL?:number,
  roundB?:number,
  roundR?:number,

  roundTL?:number,
  roundTR?:number,
  roundBL?:number,
  roundBR?:number,
}

type ElementStrokeArgs = {
  strokeWidth?:number,
  strokeStyle?:string,
}

type ElementShiftAxisArgs = {
  pointIndex:number,
  axisIndex:number,
  offset:number,
}

type ElementShiftArgs = {
  offsetL?:ElementShiftAxisArgs,
  offsetR?:ElementShiftAxisArgs,
  offsetT?:ElementShiftAxisArgs,
  offsetB?:ElementShiftAxisArgs,
}

type ElementArgsObject = {
  element?:string,
  shift?:ElementShiftArgs,
} & OffsetRoundingArgs & ElementStrokeArgs 

type ElementArgs = string | ElementArgsObject

type ElementsArg = ElementArgs[]

function parseElements( elements:ElementsArg, gutter:number = 0 ) {
  const result = []

  const eachElement = elem => {
    if ( !elem ) return
    elem = { ...elem, ...resolveRoundingAndOffset( elem ) }
    let { offsetT, offsetB, offsetL, offsetR, strokeWidth } = elem
    strokeWidth = ( parseFloat(strokeWidth) || 0 ) / 2
    gutter = max( gutter, offsetT+strokeWidth, offsetB+strokeWidth, offsetL+strokeWidth, offsetR+strokeWidth )
    const { elements } = elem
    if ( elements ) {
      elem.elements = elements.map( eachElement )
    }

    return elem
  }

  
  elements.map( ( elem ) => {
    const parsed = eachElement( elem )
    if ( parsed )
      result.push( parsed )
  } )


  return { elements:result, gutter }
}



function firstToMatch( array, test ) {
  for ( let item of array ) {
    if ( test( item ) ) {
      return item
    }
  }
}




function computeElementsRadial( { radius, radial, axes, pointCoords, rounding } ) {
  console.log( 'computeElementsRadial', { radius, radial, axes, pointCoords, rounding } )
  const { minA, maxA, minR, maxR, hole } = radial

  const closed = isAngleClosed( minA, maxA )

  const axisA = firstToMatch( axes, ( axis ) => axis.radial == 'angle' )
  const axisR = firstToMatch( axes, ( axis ) => axis.radial == 'distance' )

  // function computeValue( axis, value, defaultValue ) {
  //   if ( !axis ) return defaultValue

  //   if ( typeof value == 'object' ) {
  //     const { index, radial } = axis

  //     let { pointIndex, offset } = value
  //     offset = parseFloat( offset ) || 0

  //     let point = pointCoords[pointIndex]
  //     value = defaultValue
  //     if ( point ) {
  //       if ( radial == 'angle' ) {
  //         value = minA + ( point[index] * ( maxA - minA ) )
  //         value += offset * ( maxA - minA )
  //       }
  //       if ( radial == 'distance' ) {
  //         value = ( hole + ( point[index] * ( 1 - hole ) ) ) * radius
  //         value += offset * ( radius * ( 1 - hole ) )

  //       }
  //     }
  //   } else if ( value == undefined ) {
  //     value = defaultValue
  //   }

  //   if ( isNaN( value ) ) {
  //     value = defaultValue
  //   }


  //   return value
  // }

  const gradients = []

  const trackRadial = ( item, index ) => {
    console.log( 'trackRadial', { item, index } )
    item = item ||  {}
    // item.minR *= radius
    // item.maxR *= radius
    // item.maxR *= 10

    // item.maxA = item.maxA * ( radial.maxA - radial.minA ) + radial.minA
    // item.minA = item.minA * ( radial.maxA - radial.minA ) + radial.minA


    // if ( !closed && isAngleClosed( item.minA, item.maxA ) && !item.offset ) {
    //   return 
    // }

    return item
  }

  return trackRadial
}

const OffsetKeyToBoundsAxisKey = {
  offsetL: [null,'width',false],
  offsetR: [null,'width',true],
  offsetT: [null,'height',false],
  offsetB: [null,'height',true],
  minA: ['angle',null,false],
  maxA: ['angle',null,true],
  minR: ['distance',null,false],
  maxR: ['distance',null,false],
}

function shiftKeyForAxis( shiftKey, axis ) {
  switch ( shiftKey ) {
    case 'offsetH': 
      return axis.vertical ? 
        axis.reversed ? 'offsetT' : 'offsetB' :
        axis.reversed ? 'offsetL' : 'offsetR'
    
    case 'offsetL': 
      return axis.vertical ?
        axis.reversed ? 'offsetB' : 'offsetT' :
        axis.reversed ? 'offsetR' : 'offsetL'
  }
  return shiftKey
}


export function computeElementsForPoints( props ) {
  const { radius, elements, gutter, axes, pointCoords, bounds, radial, status:statusState } = props
  function computeValue( shiftKey, shiftItem ) {
    if ( isNumber( shiftItem ) ) shiftItem = { offset:shiftItem }
    let { pointIndex, axisIndex, offset } = shiftItem
    offset = parseFloat( offset ) || 0
    const axis = axes[axisIndex]

    shiftKey = shiftKeyForAxis( shiftKey, axis )

    if ( !axis ) return
    let point = pointCoords[pointIndex]
    if ( !point ) return
    let value = point[axisIndex]
    let boundsItem = OffsetKeyToBoundsAxisKey[shiftKey]
    if ( !boundsItem ) return

    const [ radialAxis, boundsAxis, boundsInvert ] = boundsItem

    if ( boundsInvert ) value = 1 - value
    if ( axis.reversed ) value = 1 - value

    let boundsValue = bounds[boundsAxis]
    if ( isRealNumber( boundsValue ) ) 
      value = (boundsValue - gutter * 2) * value - offset

    if ( radialAxis == 'angle' ) {
      // value = radial.minA + value * ( radial.maxA - radial.minA )
    }

    if ( radialAxis == 'distance' ) {
      // value *= 100
    }

    return [ shiftKey, value ]
  }

  const trackRadial = radial ? computeElementsRadial( props ) : a => a
  const gradients = []
  const eachTrack = ( item, index ) => {
    item = item ||  {}
    let track = {
      ...item,
    }

    const { shift, status } = item
    let hide = false

    if ( status ) {
      for ( const statusKey in statusState ) {
        if ( !statusState[statusKey] ) continue

        const statusItem = status[statusKey]
        if ( isObject( statusItem ) ) {
          track = { ...track, ...statusItem }
        }
      }
    }


    if ( !hide && shift && pointCoords ) {
      const shiftKeys = Object.keys( shift )
      for ( const shiftKey of shiftKeys ) {
        const shiftItem = shift[shiftKey]
        const computed = computeValue( shiftKey, shiftItem )
        const [ setKey, shiftValue ] = computed || []

        if ( isRealNumber( shiftValue ) ) {
          track[setKey] -= shiftValue 
        }
      }
    }

    track = trackRadial( track, index )

    track.hide = hide


    if ( track.elements ) {
      track.elements = computeElementsForPoints( { ...props, elements:track.elements } )
    }

    return track
  }

  const tracksNext = (elements||[]).map( eachTrack )
  return tracksNext
}



export function useParsedElements( props ) {
  const parse = props.elements || elementsDefault
  const { elements, gutter:gutterDefault } = useMemo( () => parseElements( parse ), [ parse ] )

  const gutter = isDefined( props.gutter ) ? props.gutter : gutterDefault

  return { elements, gutter }
}


function BoxElement( props ) {
  const { shadingToStyle, gutter, children, content, hide, bounds, absolute, fitText } = props

  let { roundBL = 0, roundBR = 0, roundTL = 0, roundTR = 0, textSize = 20, size } = props
  let { offsetT, offsetL, offsetR, offsetB } = props

  let top = gutter - offsetT
  let left = gutter - offsetL
  let right = gutter - offsetR
  let bottom = gutter - offsetB

  if ( isString( size ) ) {
    size = parseFloat( size )
  }

  if ( isNumber( size ) ) {
    size = [ size, size ]
  }

  if ( bounds ) {
    let { width, height } = bounds
    let [ sizex, sizey ] = size || []
    let fontScale = min( width / 16 / (sizex+0.5), height / 20 / (sizey+0.5) )
    if ( fitText && fontScale > 0 ) {
      textSize *= fontScale
    }
  }

  if ( textSize > 0 ) {
    var fontSize = `${textSize}px`
  }

  const boxStyle = {
    borderRadius: `${roundTL}px ${roundTR}px ${roundBR}px ${roundBL}px`,
    fontSize,
    ...shadingToStyle( props ),
  }

  if ( absolute ) {
    boxStyle.inset = `${top}px ${right}px ${bottom}px ${left}px`
  } 

  if ( hide ) {
    boxStyle.display = 'none'
  }

  const contents = <>
    { content }
    { children }
  </>

  const className = absolute ? `${elementStyles.absoluteElement}` : `${elementStyles.boxElement}`

  return <div {...{className}}  style={boxStyle}>
    { contents }
  </div>
}

function MaskPlacement( props ) {
  const { gutter, offsetB, offsetL, offsetR, offsetT, children } = props
  let top = gutter - offsetT
  let left = gutter - offsetL
  let right = gutter - offsetR
  let bottom = gutter - offsetB
  return <div className={`${elementStyles.maskElements}`} style={{ inset: `-${top}px -${right}px -${bottom}px -${left}px` }}>
    { children }
  </div>
}

export function ElementsBoxes( props ) {
  let {
    elements,
  } = props

  const {
    shadingToStyle,
    gutter,
    bounds,
    size,
    fontSize,
    content:contentProp,
    absolute = true,
  } = props

  const elementProps = { shadingToStyle, gutter, bounds, size, fontSize }

  function Element( element ) {
    const { index, elements } = element

    let showContent = null
    if ( element.content ) {
      showContent = contentProp
    }

    return <BoxElement {...elementProps} {...element} {...{absolute}}>
      { elements && !!(elements.length) && <MaskPlacement gutter={gutter} {...element}>
        <ElementsBoxes {...elementProps} content={contentProp} elements={elements} {...{absolute}} />
      </MaskPlacement> }
      { showContent }
    </BoxElement>
  }

  return elements.map( ( element, key ) => <Element key={key} {...element} /> )
}

import { elementsDefault } from "../presetDefaults.ts" 
export { elementsDefault }