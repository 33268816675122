// Auto-generated by library_common.js - Do not modify;
const { noop,str_length,ob_length,clock_now } = require('./constant');
const { is_Array,is_defined,is_function,is_null,is_empty,is_plain_object,is_arraylike,is_number,for_key_value,new_Promise,ob_map,ob_indexOf,ob_reduce,ob_add,ob_replace,ob_sort,ob_pop,ob_push,ob_getImageData,ob_userData,ob_set_width_height,ob_keys,for_each,for_count,for_mirror_count,new_Symbol,array_create } = require('./object');
// order: 100
const lru_cache = ( max_len = 10 ) => {
  const dir = {}
  const arr = []

  const func = ( key, gen ) => {
    const item = dir[key]
    const time = clock_now()
    if ( item ) {
      item[1] = time
      return item[2]
    }

    const val = gen( key )
    const next = [key,time,val]

    while( ob_length( arr ) >= max_len - 1 ) {
      ob_sort( arr, (a,b) => b[0] - a[0] )
      const trash = ob_pop( arr )
      delete dir[trash[0]]
    }

    ob_push( arr, next )
    dir[key] = next

    return val
  }

  func.arr = arr
  func.dir = dir

  return func
};
module.exports = { lru_cache }