import React from 'react'
import { useRef, useState, useEffect } from "react"

import { Button, IconButton, Stack, HStack, Select, Grid, Flex, Spacer, GridItem, Box, Image, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { FaUpload, FaVideo, FaCamera, FaUndo, FaDice, FaDownload } from 'react-icons/fa/index'
import { Controls } from 'g84react'
const { buttonStyle } = Controls


import { useCollection } from 'react-firebase-hooks/firestore'
import { getAuth } from "firebase/auth"


const now = () => new Date().getTime()


async function doCapture( state ) {
  if ( !state || state.get() != '$camera' ) return

  const source = state.parent.nav('source').get()
  console.log('doCapture', source )

  
  var canvas = document.createElement('canvas')
  const box = document.getElementById('unseen')
  box.appendChild(canvas)

  canvas.width = source.videoWidth
  canvas.height = source.videoHeight
  canvas.getContext('2d').drawImage(source, 0, 0, canvas.width,canvas.height)  

  const data = await new Promise( resolve => {
    canvas.toDataURL( resolve, 'image/jpeg', 0.9 )
  })

  const media = await uploadToMedia( data, {} )
  
  return url
}

async function actionUploadImage( file, fields = {} ) {

  const auth = getAuth()
  const { currentUser = {} } = auth
  const { uid = 'anon' } = currentUser || {}

  const data = {
    public: true,
    ...fields,
  }
  // const media = await uploadToMedia( file, data )
  const media = await addMedia( file, data )
    

  
  // const mediaCollection = collection( firestore, 'media' )

  // const timestamp = new Date().toJSON().substr( 0, 11)
  // const path = `media/user/${uid}/${timestamp}/${file.name}`

  // const ext = extname( file.name )
  // const name = basename( file.name, ext )

  // const fileRef = ref( storage, path )
  // const fileSnap = await uploadBytes( fileRef, file )
  // const url = await getDownloadURL( fileSnap.ref )


  // const media_data = {
  //   time_created: Timestamp.fromDate(new Date()),
  //   url,
  //   name,
  //   file_path: path,
  //   user_id: uid,
  //   is_public: true,
  // }

  // const doc = await addDoc( mediaCollection, media_data )

  // return url
}

function UploadMedia( { state, fields  }) {
  const inputRef = useRef()
  const [file, setFile] = useState(null)
  const [isLoading,setIsLoading] = useState(false)
  function handleChange(e) {
    if (e.target.files[0])
        setFile(e.target.files[0]);
  }

  async function handleUpload(e) {
    inputRef.current?.click()
  }

  useEffect( () => {
    (async () => {
      if ( file ) {
        setIsLoading( true )
        const url = await actionUploadImage( file, fields )
        state.merge( url, 'upload' )
        setFile(null)
        setIsLoading( false )
      }
    })()
  }, [ file ] )

  return <>
    <Button isLoading={isLoading} leftIcon={<FaUpload/>} onClick={handleUpload}>Upload</Button>
    <form hidden onSubmit={handleUpload}>
      <input hidden type="file" accept="video/*,image/*" ref={inputRef} onChange={handleChange} size={10} />
    </form>
  </>
}


function mediaToPlayableFile( media ) {
  const { type, variants = {} } = media
  let variant = type == 'video' ? 'mp4' : 'jpeg'
  const file = variants[variant] || ''
  const { link } = file

  return link
}

function mediaFitSize( media, size, format = 'jpeg' ) {
  const { variants = {} } = media || {}
  let result
  let best = 0

  for( let key in variants ) {
    if ( key.endsWith( `.${format}`) ) {
      let [ _, fitSize ] = /^fit(\d+)/.exec( key ) || []
      fitSize = parseInt(fitSize)
      if ( fitSize && fitSize <= size && fitSize > best ) {
        result = variants[key]
        best = fitSize
      }
    }
  }

  return result && result.link
}


function MediaOption( { onSelect, media, size = 128 } ) {
  const jpeg = mediaFitSize( media, size * 2 )

  return <Box 
    onClick={()=>onSelect( media ) } 
  >
    <Image 
      src={ jpeg }     
      boxSize={`${size}px`}
      objectFit='cover'
    />
  </Box>
}


import { FormControl, FormLabel, Switch } from '@chakra-ui/react'

function MediaCreate( { state, onCreated } ) {

  const [ fields, setFields ] = useState( {
    public: false,
    hidden: false,
  })

  const mergeField = ( key, value ) => setFields( { ...fields, [key]: value } )

  return <Box>
    <FormControl display='flex' alignItems='center'>
      <FormLabel htmlFor='set-public' mb='0'>
        Public
      </FormLabel>
      <Switch id='set-public' onChange={ e => mergeField('public', !!e.target.value) } />
    </FormControl>
    <UploadMedia {...{state,fields}}/>
  </Box>
}

export function MediaList({state, onSelect, query }) {

  const [value, loading, error] = useCollection(
    query,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )


  return (
    <div>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Collection: Loading...</span>}
        {value && (<>
          <Flex flexWrap={"wrap"}>
            {value.docs.map((doc) => <MediaOption key={doc.id} media={doc.data()} onSelect={(media)=>{
              onSelect( `media:///${doc.id}` )
            }}/>)}
          </Flex>
        </>)}
    </div>
  )
}


import { addMedia, uploadToMedia } from '../lib/media.js'
import { Region, RegionContent } from './ui/Region.jsx'
import { queryPublicMedia, queryUserMedia } from '../lib/queries.js'
import MediaPrompt from './media/MediaPrompt.jsx'


import { auth } from '../firebase.js'
import { useAuthState } from 'react-firebase-hooks/auth'

import { useStateMeta } from 'trancer'

export function MediaControl( { state } ) {

  const meta = useStateMeta( { state })
  const [ user, authStateLoading, authStateerror ] = useAuthState(auth)
  const { uid = '' } = user || {}

  async function onDefault() {
    const url = '/assets/refractionbg_refrac_02.png'
    state.merge( url, 'choose' )
  }

  async function onLiveCamera() {
    state.merge( '$camera', 'choose' )
  }

  async function onCaptureCamera() {
    // const parent_state = state.parent
    // parent_state.merge( { link: '$camera', capture: now() }, 'capture')
    doCapture( state )
  }

  const onSelect = (url)=>state.merge( url, 'choose' )

  return <Tabs variant="soft-rounded" colorScheme="teal">
    <TabList>
      <Tab>Text To Image</Tab>
      <Tab>Upload</Tab>
      <Tab>Media</Tab>
      {/* <Tab>Camera</Tab> */}
    </TabList>

    <TabPanels>
      <TabPanel>
        <MediaPrompt {...{state,onSelect}}/>
      </TabPanel>
      <TabPanel>
        <MediaCreate {...{state}}/>
      </TabPanel>
      <TabPanel>
        <Tabs>
          <TabList>
            <Tab>Public</Tab>
            <Tab>Private</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <MediaList {...{state,onSelect}} query={queryPublicMedia( uid )}/>
            </TabPanel>

            <TabPanel>
              <MediaList {...{state,onSelect}} query={queryUserMedia( uid )}/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </TabPanel>
      <TabPanel>
        <Button {...buttonStyle} leftIcon={<FaVideo/>} onClick={onLiveCamera} color='white'>Live Camera</Button>
      </TabPanel>
    </TabPanels>
  </Tabs>
}


// export function MediaCapture( { state, hasCaptureCallback } ) {

//   const [ capturedTime, setCapturedTime ] =  useState( 0 )

//   async function onDefault() {
//     const url = '/assets/refractionbg_refrac_02.png'
//     state.merge( url, 'choose' )
//   }


//   async function doCapture( state ) {
//     if ( !state || state.get() != '$camera' ) return

//     const source = state.parent.nav('source').get()
//     console.log('doCapture', source )

    
//     var canvas = document.createElement('canvas')
//     const box = document.getElementById('unseen')
//     box.appendChild(canvas)

//     canvas.width = source.videoWidth
//     canvas.height = source.videoHeight
//     canvas.getContext('2d').drawImage(source, 0, 0, canvas.width,canvas.height)  

//     const data = await new Promise( resolve => {
//       canvas.toBlob( resolve, 'image/jpeg', 0.9 )
//     })

//     const media = await uploadToMedia( data, {} )


//     // const time = new Date().toJSON()
//     // const timestamp = time.substr( 0, 11 )
//     // const path = `/captured_media/cd${timestamp}/cm${time.substr( 11 ) }`

//     // const fileRef = ref( storage, path )
//     // const fileSnap = await uploadBytes( fileRef, data )
//     // const url = await getDownloadURL( fileSnap.ref )


//     // const media_data = {
//     //   time_created: Timestamp.fromDate(new Date()),
//     //   url,
//     //   name:`capture@${time}`,
//     // }
//     // const mediaCollection = collection( firestore, 'media' )
//     // const doc = await addDoc( mediaCollection, media_data )

//     // console.log( 'url', url, state.path)
//     // state.merge( url, 'captured')
//     return url
//   }

//   useEffect( () => {
//     const capture_state = state.parent.nav('capture')
//     const onState = ( state, event ) => {
//       const value = state.get()
//       console.log('onState', value, capturedTime )
//       if ( value > capturedTime ) {
//         doCapture()
//       }
//     }

//     capture_state.sub( onState )

//     return () => {
//       capture_state.unsub( onState )
//     }
//   })

//   hasCaptureCallback( doCapture )

//   return null
// }
