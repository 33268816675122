.boxElement {
  /* position: absolute; */
  /* width: fit-content;
  height: fit-content; */
  display: block;
  /* flex-grow: 1; */
  overflow: hidden;
  box-sizing: border-box;
}



.absoluteElement {
  position: absolute;
  overflow: hidden;
}

.maskElements {
  position: absolute;
  overflow: hidden;
}