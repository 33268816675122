import { auth } from '../firebase.js'
import { useAuthState } from 'react-firebase-hooks/auth'

import { LinkButton } from './LinkButton.jsx'
import { FaDoorOpen } from 'react-icons/fa'

export default function SignedInArea( { children } ) {
  const [ user, authStateLoading, authStateerror ] = useAuthState(auth)
  
  return <>
    { !user &&  <LinkButton leftIcon={<FaDoorOpen/>} to={`/signin`}>Sign in</LinkButton> }
    { user && children }
  </>
}