export function isNumber( value ) {
  return !isNaN( parseFloat( value ) )
}

const saturate = v => v > 1 ? 1 : v < 0 ? 0 : v 

export function toNormalized( value, config = {} ) {
  
  value = parseFloat( value )
  if ( isNaN( value ) )
    return value

  const {
    zero = 0,
    expo = 0,
    min = 0,
    max = 1,
    step = 1 / 1000,
    clamp = false,
  } = config

  value = ( value - min ) / ( max - min )

  if ( expo ) {

  }

  if ( step ) {
    
  }

  if ( clamp ) {
    value = saturate( value )
  }

  return value
}

export function fromNormalized( value, config = {} ) {
  const {
    zero = 0,
    expo = 0,
    min = 0,
    max = 1,
    step = 1 / 1000,
    clamp = false,
  } = config

  value =  min + ( max - min ) * value

  return value
}

export function normalToString( ) {

}

export function metricUnit( num ) {

}

export function toNumberString(num, options ) {
  let { 
    digits = 4, 
    unit = '', 
    metric,
    precision = 0,
    negative = '-',
    positive = '',
  } = options || {}

  if ( metric ) {
    var abs = Math.abs( num )
    if ( abs > 1000000000 ) {
      metric = 'G'
      num /= 1000000000
    } else  if ( abs > 1000000 ) {
      metric = 'M'
      num /= 1000000
    } else  if ( abs > 1000 ) {
      metric = 'k'
      num /= 1000
    } else if ( abs )  {
      if ( abs < 1 ) {
        metric = 'm'
        num *= 1000
      }
    }
  } else {
    metric = ''
  }

  if ( unit == '%' ) {
    num *= 100
  }

  if ( isNaN( num ) )
    return 'NaN'

  var sign = num < 0 ? negative : positive
  var int = num.toFixed(0)
    , prefix = num > 0 && sign ? '+' : num < 0 ? '' : ''

  digits -= prefix.length + int.length + metric.length
  return prefix +
    ( num.toFixed( Math.max( digits, precision ) ) ) + metric + unit
}

export function valueNumberString( value, options ) {
  return isNumber( value ) ? toNumberString( value, options ) : value
}

export function stringToNumber( value, options ) {

}