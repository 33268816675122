import React from "react-dom"
import { useMemo, useState } from "react"

import { combineVariants, styled } from "../styled" 
import { stringify } from "yaml"
import { makeColouredVariants } from "../styled"

export const ControlBounds = styled('div', {
  // width: 'calc( 100% - 0.5em )',
  // height: 'calc( 100% - 0.5em )',
  margin: '0.125em',
  display: 'flex',
  alignItems: 'stretch',
  justifyItems: 'stretch', 
  flexDirection: 'column',
  flexGrow: 0.5,
  variants: {
    shape: {
      circle: {
        aspectRatio: '1'
      },
      wide: {
        // width: '100%',
        // aspectRatio: '30',
      }
    },
  },
})

export const ControlLabelStyle = styled('div', {
  display: 'inline-block',
  justifyContent: 'center',
  // flexGrow: 1,
  textAlign: 'center',
  fontFamily: 'sans-serif',
  fontSize: '80%',
  marginTop: '0.0625em',
  marginBottom: '-0.125em',
  ...makeColouredVariants( 
    color => ({ color: `${color}3`}),
  ),
})

const ControlBody = styled('div', {
  // width: '100%',
  // height: '100%',
  minWidth: '2em',
  minHeight: '1em',
  boxSizing: 'border-box',
  // height: 'auto',
  display: 'flex',
  justifyItems: 'stretch',
  alignItems: 'stretch',
  flexGrow: 1,
  boxSizing: 'border-box',
  fontFamily: 'sans-serif',
  borderRadius: '0.5em',
  overflow: 'hidden',
  border: '0.1em solid',
  // borderImageSlice: 1,
  ...combineVariants( makeColouredVariants( 
    color => ({ 
      borderColor: `${color}3`,
      // borderImageSource: `linear-gradient(to bottom, $colors${color}0, $colors${color}1 )`,
    }),
  ), { variants: {
    shape: {
      circle: {
        aspectRatio: '1',
        borderRadius: '50%',
        height: 'auto',
      },
      wide: {
        minHeight: '2em',
        width: '100%',
        // minWidth: '64px',
      },
      tall: {
        minWidth: '2em',
        width: '100%',
        height: '100%',
        // minWidth: '64px',
      },
    },
    direction: {
      up: {
        minHeight: '4em',
      },
      down: {
        minHeight: '4em',
      },
      left: {
        minWidth: '4em', 
      },
      right: {
        minWidth: '4em', 
      },
    }
  }})
})

export function Control( { label, children, color, shape, boundsRef, style, direction } ) {
  const [ active, setActive ] = useState()

  return <ControlBounds ref={boundsRef} color={color} shape={shape} style={style}>
    { label && <ControlLabelStyle color={color}>{ label }</ControlLabelStyle> }
    <ControlBody  color={color} shape={shape} direction={direction} >
      { children }
    </ControlBody>
  </ControlBounds>
}


const ControlContentStyled = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  // containerType: 'inline-size',
  width: '100%',
  height: '100%',
  placeItems: 'stretch',
  flexWrap: 'wrap',
  // background: '$grey1',
  background: `linear-gradient(to bottom, $grey0, $grey1 )`,
  color: '$grey2',
  ...makeColouredVariants( 
    // color => ({ background: `${color}1`, color: `${color}5`}),
    color => ({ background: `linear-gradient(150deg, $colors${color}2, $colors${color}0 )`, color: `${color}5`}),
    color => ({ background: `linear-gradient(150deg, $colors${color}6, $colors${color}4 )`, color: `${color}1`}),
  ),
})

const ContentTextItem = styled('div', {
  display: 'block',
  fontFamily: 'monospace',
  fontSize: '1.2em',
  margin: '0.2em 0.5em',

})

const ContentIconItem = styled('div', {
  // flexGrow: 3,
  // fontSize: '200%',
  margin: '0.5em 0.5em',
  display: 'flex',
  justifyItems: 'center', 
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

const ContentBackgroundItem = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  minWidth: '100px',
  zIndex: '-1',
})


const ContentValueItem = styled('div', {
  // flexGrow: 3,
  display: 'flex',
  justifyItems: 'center', 
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '1em',
})



function isDefined( value ) {
  return typeof( value ) != 'undefined'
}

function stringifyValue( value ) {
  return stringify( value )
}

export function ControlContent( { icon, value, text, children, background, ...remain } ) {
  return <ControlContentStyled { ...remain } >
    { background && <ContentBackgroundItem style={{background}}/>}
    { icon && <ContentIconItem>{icon}</ContentIconItem>}
    { text && <ContentTextItem>{text}</ContentTextItem>}


    { isDefined( value ) && <ContentValueItem>{ stringifyValue( value ) }</ContentValueItem> }
    { children }
  </ControlContentStyled>
}


export const ControlInner = styled('div', {
  overflow: 'hidden',
  position: 'relative',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'stretch',
  width: '100%',
  // height: '100%',
})
