
const round = 6

export const elementsDefault = [
  { 
    element:'box', 
    round, 
    offset:0, 
    fill: { gradient: [ 1, -1 ], shift: { value: 0.1 } },
    text: { offset: { value: 0.8, saturation: -0.8, alpha:1 } },
    status: {
      over: {
        stroke: [1,-1]
      }
    },
    stroke: { offset: { value: 0.1, saturation: -0.5} }, 
    strokeWidth:1, 
    content:true,
    fontSize: 3,
  }
]

const presetsDefaults = {
  control: {
    colour: "404040",
    gutter: 4,
  }
}



const sliderElementDefaults = [
  { 
    element: 'box',
    round: 16,
    offset: 0,
    stroke: { offset: { value: 0.1, saturation: -0.5} }, 
    strokeWidth:1, 
    text: "#e0e0e0",
    fill: { gradient: [ -1,-1.5 ] },
    box: true,
    content: true,
    elements: [
      { 
        fill: "#e0e0e0",
        offset: 0,
        round: 0,
        shift: {
          offsetH: {
            pointIndex: 0,
            axisIndex: 0,
          }
        },
        elements: [
          { 
            text: "#202020",
            content: true,
          }
        ]
      }
    ]
  },
]


export { presetsDefaults, sliderElementDefaults }