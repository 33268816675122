import React from 'react'
import { useEffect, useMemo, useRef, useState } from "react"

import { state_roll_dice } from 'g8blockparty/dist/common_js/state_high'
import { IconButton, Switch, Stack, HStack, Select, Grid, Flex, Spacer, GridItem } from '@chakra-ui/react'
import { FaDice, FaUpload, FaDiceD20 } from 'react-icons/fa/index'

export const buttonStyle = {
  background: 'blackAlpha.800',
  border: '1px',
  borderColor: 'whiteAlpha.700',
  _hover: {
    background: 'blackAlpha.500',
  },
  _active: {
    background: 'whiteAlpha.600',
    color: 'black'
  }
}

function stateHasControls( state ) {
  const { meta = {}, branch } = state
  const { control, hide } = meta
  if ( hide ) return false
  if ( control ) return true
  
  for ( let key in branch ) {
    const sub = branch[key]
    if ( stateHasControls( sub ) )
      return true
  }

  return false
}

function controlSetStateValue( state, value, event = 'control' ) {
  state.merge( value, event )
}

import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react'

function Range ( { state } ) {
  const { meta } = state
  const [ value, setValue ] = useState( parseFloat( state.value ) )

  function onInputChange( value ) {
    controlSetStateValue( state, value )
  }

  function onState( state ) {
    let { value } = state
    value = parseFloat( value )
    if ( !isNaN( value ) ) 
      setValue( value )
  }

  const { 
    min = 0,
    max = 1,
    step = 0.001,
  } = meta

  useEffect( () => {
    state.sub( onState )
    onState( state )
    return () => state.unsub( onState )
  })


  return <Slider colorScheme="blackAlpha" aria-label='slider-ex-1' defaultValue={30} minWidth="200" value={value} onChange={onInputChange} {...{min,max,step}}>
    <SliderTrack>
      <SliderFilledTrack/>
    </SliderTrack>
    <SliderThumb/>
  </Slider>

}

function Options ( { state } ) {
  const inputRef = useRef()
  const [ selected, set_selected ] = useState()

  const { meta } = state
  let { options = {} } = meta
  let keys = Object.keys( options )

  function onInputChange( event ) {
    let key = event.target.value
    let value = options[key]
    state.merge( value, 'input' )
  }

  function onState( state ) {
    const { value } = state
    var new_select 
    for ( let key in options ) {
      if ( value == options[key] ) {
        new_select = key
        break
      }
    }
    if ( selected != new_select )
      set_selected( new_select )
  }

  useEffect( () => {
    state.sub( onState )
    onState( state )
    return () => state.unsub( onState )
  })
  function roll() {
    state_roll_dice( state, 1 )
  }
  return <HStack className="wrap-input">
    <Select {...buttonStyle} onChange={onInputChange} value={selected} colorScheme='blackAlpha'>
      { keys.map( key => <option value={key} key={key}>{ key }</option>)}
    </Select>
    <IconButton {...buttonStyle} aria-label='Randomize control' icon={<FaDice/>} {...buttonStyle}  onClick={roll}/>
  </HStack>
}



function Checkbox ( { state } ) {

  function coerce( value ) {
    return !!value
  }

  const [ value, setValue ] = useState( state && coerce( state.value ) )  

  function onInputChange( event ) {
    let value = event.target.checked ? 1 : 0
    state.merge( value, 'input' )
  }

  function onState( state ) {
    let { value } = state
    value = !!value
    setValue( value )
  }

  useEffect( () => {
    state.sub( onState )
    return () => state.unsub( onState )
  })


  return <div className="wrap-input">
    <Switch size="lg" colorScheme="green"  isChecked={value} onChange={onInputChange}/>
  </div>
}



export function Dice( { state, ...remain } ) {
  function roll() {
    state_roll_dice( state, 1 )
  }
  return <IconButton aria-label='Randomize control' icon={<FaDice/>} {...buttonStyle} onClick={roll} {...remain} />
}

export function MegaDice( { state, ...remain } ) {
  function roll() {
    state_roll_dice( state, 12 )
  }
  return <IconButton aria-label='Randomize control' icon={<FaDiceD20/>} {...buttonStyle} onClick={roll} {...remain}/>
}

export function DiceAuto( { state, active, ...remain } ) {

  const [ isActive, setActive ] = useState( active )

  function roll() {
    state_roll_dice( state, 1 )
  }

  function onTimer() {
    if ( isActive )
      roll()
  }

  function onInputChange( event ) {
    let value = event.target.checked ? 1 : 0
    setActive( value )
    if ( value )
      roll()
  }

  useEffect( () => {
    const timer = setInterval( onTimer, 5000 )
    return () => clearInterval( timer )
  })
 


  return <Switch colorScheme="blackAlpha" onChange={onInputChange} {...remain}/>
}

import { MediaControl as MediaControl_import } from "./MediaControl.jsx"
export const MediaControl = MediaControl_import

export function ControlPrimitive( { state } ) {
  const { meta } = state
  const { control } = meta
  let ControlClass
  switch ( control ) {
    case 'range': ControlClass = Range; break
    case 'options': ControlClass = Options; break
    case 'media': ControlClass = MediaControl; break
    case 'checkbox': ControlClass = Checkbox; break
  }

  return ControlClass && <ControlClass key="control" state={state}/>
}

function flattenControls( state ) {

  const result = []
  function addResult( state, path ) {
    const { meta, key } = state
    const { hide } = meta
    if ( hide ) return
    
    state.each( ( child ) => {
      const { key } = child
      const childPath = path ? `${path}/${key}` : key
      addResult( child, childPath )
    })
    
    const { control } = meta

    if ( control && path ) {
      result.push( [ state, path ] )
    }
  }

  addResult( state, '' )
  return result
}

function useStateMeta( state ) {
  const [ stateMeta, setStateMeta ] = useState( state.meta )
  function onStateReset() {
    setStateMeta( { ...state.meta } )
  }

  useEffect( () => {
    function onStateEvent( nil, event ) {
      if ( event == 'reset' )
        onStateReset()
    }

    state.sub( onStateEvent )
    return () => state.unsub( onStateEvent )
  })

  return stateMeta
}


export function ControlList( { state, itemClass = ControlUnit, itemArgs } ) {
  const stateMeta = useStateMeta( state )

  const [ list ] = useMemo( () => {
    let list = flattenControls( state )
    return [ list ]
  }, [ stateMeta ] )


  return list.map( ( item, index ) => {
    const ItemClass = itemClass
    return <ItemClass {...itemArgs } key={index}/> 
  } ).flat()
}
import { AccordionIcon, Text, Heading, Box } from '@chakra-ui/react'

const labelStyle = {
  padding: 0,
}

export function ControlLabel( { state, WrapLabel = Flex, showDice = true, showEnable = false, showLabel = true, showAccordion } ) {

  const [ label, enabled, hasDice ] = useMemo( () => {
    const hasDice = showDice && stateHasDice( state )
    const enabled = showEnable && state.branch['enabled'] 
    const label = state.meta.label || ''
    return [ label, enabled, hasDice ]
  }, [ state ] )

  return <WrapLabel align='stretch' {...labelStyle}>
    { label && <Heading size='sm'>{ label }</Heading> }
    { ( label && ( enabled || hasDice || showAccordion ) ) && <Spacer/> }
    { enabled && <Checkbox key="enable" state={enabled} /> }
    { hasDice && <Dice key="dice" state={state} /> }
    { showAccordion && <AccordionIcon/>}
  </WrapLabel>
}

import { TableContainer, Table, Tbody, Tr, Td } from '@chakra-ui/react'

export function ControlItem( { state, As=Grid, childWrap=GridItem, childType=ControlPair } ) {
  const meta = useStateMeta( state )
  const { control } = meta

  const children = flattenControls( state )

  return <Flex width="100%">
    <ControlPrimitive state={state}/>
    <TableContainer>
      <Table variant='unstyled'>
        <Tbody>
          { childType && children.map( ( [state], key ) => <Tr key={key}> { <ControlPair WrapLabel={Td} WrapControl={Td} state={state}/> }</Tr>)  }
        </Tbody>
      </Table>
    </TableContainer>
  </Flex>
}

import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@chakra-ui/react'

const controlStyle = {
  padding: 4,
}

export function ControlPair( props ) {
  const { ItemClass = ControlItem, LabelClass = ControlLabel, WrapControl = AccordionPanel, ...remaining } = props

  return <>
    <LabelClass  {...remaining}/>
    <WrapControl key="control" {...controlStyle}><ItemClass {...remaining}/></WrapControl>
    </>
}

function controlTypeToClass( control ) {
  switch( control ) {
    case 'range': return Range
    case 'options': return Options
    case 'media': return MediaControl
    case 'checkbox': return Checkbox
  }
}

function stateHasDice( state ) {
  const { meta } = state
  if ( !meta ) return false
  const { control, dice } = meta
  if ( dice ) return true

  switch( control ) {
    // case 'options': return true
  }
  return false
}

export function ControlUnit( { state, meta, children, as = Flex } ) {
  if ( !state ) return null
  console.log('controlUnit', state.path )

  const [ state_meta, set_state_meta ] = useState( state.meta || {} )

  const [ rendered_meta, subcontrols ] = useMemo( () => {
    
    const rendered_meta = { ...state_meta, ...(meta||{}) }
    const subcontrols = []

    if ( !stateHasControls( state ) ) return []

    state.each( ( child ) => {
      const patch_meta = {}

      const child_meta = { ...child.meta, ...patch_meta }
      const { hide } = child_meta
      const { key } = child
      if ( key == 'enabled' ) {
        rendered_meta.enabled = child        
      } else if ( !hide ) {
        subcontrols.push( { state: child, meta: child_meta } )
      }
    })

    const { control } = rendered_meta

    return [ rendered_meta, subcontrols ]
  }, [ state, state_meta ] )

  const { label, hide = false, control, ControlClass, dice, enabled } = rendered_meta 

  if ( hide ) return null

  const As = as

  return <As className="control">


    <Grid className="subcontrols">
        { subcontrols.map( ( child, key ) => <ControlUnit key={key} {...child}/> )}
    </Grid>
    { children }
  </As>
}

export function ControlsAccordion( { state, filter } ) {
  const [ rootControls ] = useMemo( () => {
    const rootControls = []
    if ( state ) {
      state.each( ( sub ) => {
        const { key, meta } = sub
        const { label } = meta
        if ( label )
          rootControls.push( sub )
      })
    }
    
    return [ rootControls ]

  }, [ state ] )

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      { rootControls.map( ( state, key ) => <AccordionItem key={key}>
        
        <ControlLabel WrapLabel={AccordionButton} state={state} showAccordion/>
        <AccordionPanel>
          <ControlItem state={state}/>
        </AccordionPanel>
        {/* <ControlPair state={state} 
        WrapLabel={AccordionButton} 
        WrapControl={AccordionPanel}/> */}
      </AccordionItem>)}
    </Accordion>
  )
}
