import { saturate, floor, pow, isRealNumber } from "./shared/math"
import { useDebouncedCallback } from 'use-debounce'
import { useEffect, useState } from "react"


enum Encoding {
  Hex = 'hex',
  Float = 'float',
  
}

enum StringCase {
  Lower = 0,
  Upper = 1,
}

type VectorFormatType = {
  encoding?:Encoding,
  prefix?:string,
  hexDigits?:number,
  hexCase?:StringCase,
}

const EncodingDefaults = {
  hexDigits:2,
  case:StringCase.Lower,
}

function hexToVector( value:string, format?:VectorFormatType ) {
  const {
    hexDigits = EncodingDefaults.hexDigits,
  } = format || {}

  const regex_hex_greedy = /[a-fA-F0-9]/g
  let result = []
  let accum = ''
  let digit = 0
  value.replace( regex_hex_greedy, ( val ) => {
    accum += val
    digit ++ 
    if ( digit == hexDigits ) {
      result.push( parseInt( accum, 16 ) / ( Math.pow( 16, hexDigits ) - 1 ))
      digit = 0
      accum = ''
    }

    return ''
  } )

  return result
}


function valueToVector( value:string | number[], format?:VectorFormatType ) {
  // if ( value as number[] ) return value
  if ( !value ) return []
  console.log("valueToHex", value )
  const { encoding = Encoding.Hex } = format || {}
  switch( encoding ) {
    default:
    case Encoding.Hex:
      return hexToVector( value, format )
  }

}

function valueToHex( value:number, digits:number = 2 ) {
  value = floor( saturate( value ) * ( pow( 16, digits ) - 1) )
  value = value.toString( 16 )
  while ( value.length < digits )
    value = '0'+value
  
  return value
}

function vectorToHex( vector:VectorType, format?:VectorFormatType ) {
  const {
    hexDigits = EncodingDefaults.hexDigits,
    hexCase = EncodingDefaults.case,
  } = format || {}
  
  let str = vector.map( ( value ) => valueToHex( value, hexDigits ) ).join('')
  if ( hexCase == StringCase.Upper )
    str = str.toUpperCase()
  return str
}


function vectorToValue( vector:VectorType, format:VectorFormatType ) {
  const {
    encoding = Encoding.Hex,
    prefix = '',
  } = format || {}


  let value
  switch ( encoding ) {
    case Encoding.Hex:
      value = prefix+vectorToHex( vector, format )
    break
  }

  return value
}

type VectorType = number | number[]

function resolveVector( value:VectorType ) {
  if ( typeof value == 'number' ) 
    return [ value ]
  return value
}

export function useVectorValue( props ) {
  const {
    value,
    onValue,
    debounce = 10,
    format,
  } = props
  const [ state, setState ] = useState( () => ({
    source: value,
    vector: valueToVector( value )
  })  )

  const { vector, source } = state

  useEffect( () => {
    if ( source != value )
      setState( {
        source: value,
        vector: valueToVector( value, format )
      } )
  }, [ value ])

  const onChange = useDebouncedCallback( () => {
    onValue && onValue( vectorToValue( vector, format ))
  }, debounce, { maxWait: debounce } )

  const getVector = ( index:number, count:number ) => {
    let result = []
    for ( let resultIndex = 0; resultIndex < count; resultIndex ++ ) {
      result[resultIndex] = vector[index+resultIndex]
    }
    return result
  }

  const setVector = ( value:VectorType, index:number ) => {
    let changed = false
    value = resolveVector( value )
    for ( let valueIndex = 0; valueIndex < value.length; valueIndex ++ ) {
      let sumIndex = valueIndex + index
      const subValue = value[valueIndex]
      if ( isRealNumber( subValue ) && vector[sumIndex] != subValue ) {
        vector[sumIndex] = subValue
        changed = true
      }
    }

    if ( changed )
      onChange()
  }

  return { setVector, getVector }
}