import { collection, query, where, limit, orderBy } from 'firebase/firestore'
import { firestore } from '../firebase.js'


export function queryUserProjects( uid ) {
  return query( 
    collection( firestore, 'projects' ), 
    where('uid','==',uid ),
    where('public','==',false ),
    where('hidden', '==', false ),
    orderBy('updated','desc'),
    limit(20) 
  )
}

export function queryUserMedia( uid ) {
  return query( 
    collection( firestore, 'media' ), 
    where('public','==',false ),
    where('uid','==',uid ),
    orderBy('updated','desc'),
    limit(20) 
  )
}

export function queryPublicMedia( uid ) {
  return query( 
    collection( firestore, 'media' ), 
    where('public','==',true ),
    orderBy('updated','desc'),
    limit(20) 
  )
}

export function queryPublicProjects() {
  return query( 
    collection( firestore, 'projects' ), 
    where('public', '==', true ),
    where('hidden', '==', false ),
    orderBy('updated','desc'),
    limit(20) 
  )
}

export function queryRecentPrompts() {
  return query( 
    collection( firestore, 'prompt' ),
    orderBy('updated','desc'),
    limit(10) 
  )
}

export function queryPromptMedia( prompt_id ) {
  return query( 
    collection( firestore, 'media' ), 
    // where('public','==',true ),
    where('prompt_id','==',prompt_id ),
    orderBy('updated','desc'),
    limit(6) 
  )
}