import { useProjectStore } from "../lib/store"
import { useCollection } from 'react-firebase-hooks/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'

import { Stack, HStack, Text, Button, IconButton, Flex, Card, Spacer } from "@chakra-ui/react"
import { useNavigate } from 'react-router-dom'
import { FaEdit, FaEye, FaDice, FaPlus } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'

import { LinkButtonStyled } from "../components/LinkButton.jsx"
import { UIAppMain } from "../components/UIAppMain"
import { StatefulProgram } from "../components/StatefulProgram"
import { Region, RegionContent } from "../components/ui/Region"
import { Viewport } from "../components/Viewport"

function ProjectListing( { query } ){
  const loadProject = useProjectStore( state => state.loadProject )
  const navigate = useNavigate()

  const [value, loading, error] = useCollection(
    query,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )
  if ( error )
    console.error( error )
  return <>
    {error && <strong>Error: {JSON.stringify(error)}</strong>}
    {loading && <span>Loading...</span>}
    {value && (
      <Stack>
        {value.docs.map((doc) => {
          const pid = doc.id
          const data = doc.data()
          const { slug_name = '', title = '' } = data || {}
          const name = title || slug_name || pid
          return <Card key={pid} py={1} px={2}>
            <HStack>
              <Text>{ name }</Text>
              <Spacer/>
              <IconButton aria-label='Load' icon={<FiEdit/>} onClick={()=>navigate( `/project/${pid}`)}></IconButton>
              <IconButton aria-label='Preview' icon={<FaEye/>} onClick={()=>loadProject(pid)}></IconButton>
            </HStack>
          </Card>
        })}
      </Stack>
    )}
  </>
}

import { auth } from '../firebase'
import { queryUserProjects, queryPublicProjects } from "../lib/queries"

export default function ViewProjectIndex() {
  const [ user, authStateLoading, authStateerror ] = useAuthState(auth)
  const { uid = '' } = user || {}

  const userProjectQuery = queryUserProjects( uid )
  const publicProjectQuery = queryPublicProjects()
  

  const display = <Viewport showMenu>
    <StatefulProgram/>
  </Viewport>
  
  const actionSaveProjectAsNew = useProjectStore( state => state.actionSaveProjectAsNew )
  const navigate = useNavigate()

  const controls = <>
    <Button variant="tool" colorScheme="blackAlpha" leftIcon={<FaPlus/>} onClick={ async () => { 
      const pid = await actionSaveProjectAsNew() 
      navigate(`/project/${pid}`)
    } }>New Project</Button>
    <Region label={'Community Projects'}>
      <RegionContent>
          <ProjectListing query={publicProjectQuery}/>
      </RegionContent>
    </Region>
     <Region label={'My Projects'}>
      <RegionContent>
        <ProjectListing query={userProjectQuery}/>
      </RegionContent>
    </Region>
  </>
  return <UIAppMain {...{display,controls}}></UIAppMain>

}