import React from "react"
import { useState, useRef, useEffect, useMemo } from "react"
import * as THREE from 'three'
const { stringify } = JSON
import { mergeRefs } from 'react-merge-refs'
import programs from 'g8blockparty/dist/common_js/programs'
import { lru_cache } from 'g8blockparty/dist/common_js/lru_cache.js'
import { useMediaSource } from "../lib/media"

export function G84Program({
  program = 'g84gateway',
  data = {},
  fps = 24,
  onInit,
  onTap,
  onClick,
  linkTransform,
  linkTo,
}) {

  const dataRef = useRef(data)
  const [loadedProgram, setLoadedProgram] = useState({})
  const canvasRef = useRef()

  const load_link_to_texture_source = useMediaSource()

  const loadProgram = async (program, initial) => {
    const item = { ...programs[program] }
    console.log("starting", { THREE, global_state_merge: initial, load_link_to_texture_source })
    const boot = item.program({ THREE, global_state_merge: initial, load_link_to_texture_source })()
    const canvas = canvasRef.current

    canvas.overrideBounds = { width: 160, height: 320 }

    const [loop, state, resize] = await boot(canvas)


    return { state, loop, item, canvas, resize }
  }

  useEffect(() => {
    let active = true
    const data = dataRef.current
    console.log('****** loading program', program, data)
    loadProgram(program, data).then((loaded) => {
      if (!active) return

      setLoadedProgram(loaded)
      onInit && onInit(loaded)
    })
  }, [program])

  useEffect(() => {
    dataRef.current = data

    const { state } = loadedProgram || {}
    if (state) {
      state.merge(data)
    }
  }, [data])

  useEffect(() => {
    var active = true
    const { loop } = loadedProgram

    const tick = () => {
      if (!active || !loop) return
      loop()
      if (fps) {
        setTimeout(tick, 1000 / fps)
      } else {
        requestAnimationFrame(tick)
      }
    }

    tick()

    return () => {
      active = false
    }
  }, [loadedProgram])

  const doResize = () => {
    const { resize, canvas } = loadedProgram || {}
    if ( resize && canvas ) {

      const outerDiv = canvas
      const wrapper = outerDiv && outerDiv.parentNode
      const dataset = wrapper && wrapper.dataset
      const dataBounds = dataset && dataset.realsize
      const datawh = dataBounds && dataBounds.split('x').map( (v)=> parseInt(v))

      if ( datawh ) {
        const newBounds = { width: datawh[0], height: datawh[1] }
        if ( stringify( newBounds ) != stringify( canvas.overrideBounds ) ) {
          canvas.overrideBounds = newBounds
          resize()
        }
      }
    }
  }

  useEffect( () => {
    doResize()
    const timer = setInterval( doResize, 100 )
    return () => {
      clearInterval( timer )
    }
  })


  // return 'foo'
  return (
    // <div ref={measureRef} style={{height:'100%'}}>
      <canvas
        ref={mergeRefs([canvasRef])}
        onClick={onClick}
      />
    // </div>
  )
}


export function BackgroundProgram({ ...remain }) {

  return <div
    style={{
      position: 'fixed',
      zIndex: 10,
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
    }}>
    <G84Program {...remain} />
  </div>
}