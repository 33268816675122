
.slider {
  position: relative;
  flex-grow: 1;
  min-width: 2em;
  min-height: 2em;
  -webkit-user-select: none;  /* Safari */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* Internet Explorer/Edge */
  user-select: none;          /* Standard syntax */
  -webkit-user-drag: none;    /* Safari */
  user-drag: none;            /* Standard syntax */
  overflow: hidden;
}
