.labeledStyle {
  display: flex;
  position: relative;
  flex-direction: column;
  vertical-align: top;
  flex-grow: 1;
  margin: 4px;
  padding: 1px;
  /* margin-top: 1.5em; */
  /* padding-top: 6px; */
  /* border: solid 1px red; */
  border-radius: 0.75em;
}