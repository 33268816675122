import { useEffect, useMemo, useRef, useState } from "react"

import inputStyles from './input.module.css'

import { TriggerMode } from "../types"
import { numberToString, stringToNumber } from "numerick"
import { ControlContent, propsToContent } from "../content"
import { useTrancerPresets } from "../presets"
import { useComponentShading } from "../shades"
import { elementsDefault } from "../presetDefaults"
import { computeElementsForPoints, useParsedElements } from "../shared/elements"
import { ControlBox } from "../box"
import { isArray } from "../util"
import { useSizedControl } from "../shared/sizes"

type InputProps = {
  name?: string,
  type?: string,
  readonly?: boolean,
}



function useValueConversion( props ) {
  const { value, numeric } = props
  const valueToString = ( value ) => {
    if ( numeric ) {
      value = numberToString( value, numeric )
    }
    return value || ''
  }
  const stringToValue = ( value ) => {
    if ( numeric ) {
      value = stringToNumber( value, numeric )
    }
    return value
  }

  return { valueToString, stringToValue }
}

function useInputEvents( props ) {
  const { value, trigger, onValue } = props
  const { valueToString, stringToValue } = useValueConversion( props )

  const inputRef = useRef()
  const inputFocusRef = useRef( false )

  const onInputValue = ( value ) => {
    onValue( stringToValue( value ) )
  }

  const onChange = ( event ) => {

    if ( trigger == TriggerMode.Change ) {
      const { target } = event
      const nextValue = target.value
      console.log('onChange', target.value, stringToValue( nextValue ) )
      // setInputValue( nextValue )
      onValue && onValue( stringToValue( nextValue ) )
    }
  }

  const onSubmit = ( event ) => {
    event.preventDefault()
    inputFocusRef.current = false
    const inputValue = inputRef.current && inputRef.current.value

    switch( trigger ) {
      case TriggerMode.Blur:
      case TriggerMode.Submit:
        onValue && onValue( stringToValue( inputValue ) )
        break
    }

    return false
  }

  const onFocus = () => {
    inputRef.current && inputRef.current.select()
    inputFocusRef.current = true
  }

  const onBlur = () => {
    console.log('onBlur')
    inputFocusRef.current = false
    if ( trigger == TriggerMode.Blur ) {
      const inputValue = inputRef.current && inputRef.current.value
      onValue && onValue( stringToValue( inputValue ) )
    }
  }

  useEffect( () => {
    const valueString = valueToString( value )
    const inputValue = inputRef.current && inputRef.current.value
    console.log('useEffect', inputFocusRef.current, inputRef.current, inputValue != valueString )

    if ( !inputFocusRef.current && inputRef.current  ) {
      console.log('set')
      // inputRef.current.value = valueString
    }
  } )

  // useEffect( () => {
  //   if ( inputFocusRef.current ) {
  //     if ( inputRef.current && inputRef.current === document.activeElement ) {
  //       inputRef.current.focus()
  //     }
  //   }
  // })

  return { onChange, onSubmit, onFocus, onBlur, inputRef }
}


export function Input( propsArg ) {
  const props = useTrancerPresets( propsArg, 'input', {
    trigger: "blur",
    elements: elementsDefault,
    invert: false,
    matchInvert: true,
    invertFalse: true,
    size: 8,
  } )


  const {
    name = 'foo',
    value,
    onValue,
    readOnly = false,
    trigger = TriggerMode.Submit,
    numeric,
    className,
    focusInvert = true,
    type = 'text',
    size = 8,
  } = props

  const { shadingToStyle } = useComponentShading( props )
  const { elements, gutter } = useParsedElements( props )





  const contentProps = propsToContent(  { ...props, showValue: false }, value )
  const inputSize = isArray( size ) ? size[0] : size




  const { onChange, onSubmit, onFocus, onBlur, inputRef, focused } = useInputEvents( props )

  const contentForm = <form {...{onSubmit}} className={`${inputStyles.formStyled} ${className || ''}`}>
    <input className={`input ${inputStyles.inputStyled}`} ref={inputRef} {...{ onChange, onFocus, onBlur, type, size:inputSize, name }}/>
  </form>

  const content = <ControlContent {...contentProps}>
    { contentForm}
  </ControlContent>

  
  let { invert } = props

  if ( focusInvert && focused ) {
    invert = !invert
  }

  let status = {
    over: false,
    active: false,
    invert,
  }


  const { sizeStyles, sizeClassname } = useSizedControl( props, contentProps.size )
  return <ControlBox
    {...props}
    style={ sizeStyles }
    className={`input ${inputStyles.input} ${sizeClassname}`}
    {...{elements,content,status,size}}
    // events = { pointerEvents }
  >

  </ControlBox>


  // return <>
  //   
  //     <ControlContent {...contentProps}>
  //       
  //     </ControlContent>
  //   </form>
  // </>
}



// export default function Button( propsArg ) {
//   const props = useTrancerPresets( propsArg, 'button', {
//     trigger: "down",
//     invert: false,
//     matchInvert: true,
//     invertFalse: true,
//     elements: buttonElementDefaults,
//   } )

//   const {
//     value,
//     onValue,
//     valueIsArray = true,
//     slide,
//     numeric,
//     onClick,
//     triggerValue,
//     triggerFunction,
//     label,
//     labelTriggerValue = true,
//     matchInvert = true,
//     invertFalse = true,
//     className,
//   } = props

//   const { shadingToStyle } = useComponentShading( props )
//   const { elements, gutter } = useParsedElements( props )
//   const { over, overEvents } = usePointerOverStatus()


//   let { invert } = props
//   let active = true
//   invert = !!invert

//   if ( matchInvert )  {
//     if ( triggerMatches( value, triggerValue ) ) 
//       invert = !invert
//   } else if ( invertFalse && !value ) 
//     invert = !invert

//   const triggerValueActual = () => {
//     let val = triggerFunction ? triggerFunction( value ) : triggerValue
//     console.log( 'triggerValueActual', triggerValue, props )

//     if ( isDefined( val ) && onValue )
//       onValue( val )
//   }

//   const onTrigger = ( event ) => {
//     triggerValueActual()
//     onClick && onClick( event )
//   }

//   const contentProps = propsToContent( props, isDefined( triggerValue ) ? triggerValue : value )
//   const { bounds, coordFromEvent, eventIsInside, boundsRef } = useBoundsTranslateCartesian( { gutter } ) 
//   const { pointerEvents } = useTriggerListeners( props, onTrigger )
//   const content = <ControlContent {...contentProps}/>
//   let status = {
//     over,
//     active,
//     invert,
//   }
  

//   let elementsComputed = computeElementsForPoints( { elements, gutter, bounds, status } )
//   return <div 
//     className={`button ${styles.slider}`} 
//     {...pointerEvents}
//   >
//     <ElementsBoxes  elements={elementsComputed} {...{shadingToStyle, bounds, gutter, content }} />
//   </div>


// }