.formStyled {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.inputStyled {
  color: inherit;
  font-size: inherit;
  box-sizing: border-box;
  /* font-family: 'Source Code Pro', monospace; */
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
}