export const isNumber = ( val : any ) => 'number' == typeof val
export const isString = ( value:any ) => 'string' == typeof value
export const isArray = ( value:any ) => Array.isArray( value )
export const isUndefined = ( value ) => typeof value == 'undefined'
export const isDefined = ( value ) => !isUndefined( value )

export const isObject = ( ob ) => ob && 'object' == typeof ob
export const isPlainObject = ( ob ) => isObject( ob )  && Object.getPrototypeOf( ob )  == Object.getPrototypeOf( {} )

export const deepMerge = (target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isPlainObject(target) && isPlainObject(source)) {
    for (const key in source) {
      if (isPlainObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        deepMerge(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return deepMerge(target, ...sources)
}

// Mutates target!
export const mutateObject = ( target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()
  if ( !isPlainObject( target ) )
    target = {}

  if ( isPlainObject(source)) {
    for (const key in source) {
      if (isPlainObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        deepMerge(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return deepMerge(target, ...sources)
}


export function triggerMatches( value, trigger ) {
  if ( value == trigger )
    return true

  if ( isObject( value ) && isObject( trigger ) ) {
    for ( let key in trigger ) {
      if ( !triggerMatches( value[key], trigger[key] ) )
        return false
    }
    return true
  }

  return false
}