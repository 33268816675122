import { Children, cloneElement, isValidElement } from "react"
import Button from "./button.js"
import { isArray, isObject } from "../util.js"
import { floor, isRealNumber } from "../shared/math.js"
import { resolveOptions } from './options.js'

import groupStyles from './group.module.css'


export function OptionsList( props ) {
  const {
    options,
    ...remain
  } = props

  const optionsResolved = resolveOptions( options )

  return optionsResolved.map( ( option, key ) => (<Button key={key} {...remain} {...option}/> ) )
}






type GroupProps = {
  children?: React.ReactNode
  options?: any
  count?: number
  rows?: number
  cols?: number
  layout?: 'row' | 'column' | 'stack'
  attach?: boolean
}

function layoutToClassname( layout ) {
  switch ( layout ) {

    case 'row':
      return groupStyles.groupRow
    case 'stack':
      return groupStyles.groupStack
    case 'inline':
      return groupStyles.groupInline

    default:
    case 'column':
      return groupStyles.groupColumn
  }
}

export function Tabs( props : GroupProps ) {
  const {
    children,
    attach,
    ...remain
  } = props

  let { tabs } = props

  const options = resolveOptions( optionsProps )
  const childCount = Children.count( children )
  const optionsCount = options.length


  const childProps = ( index ) => {
    const props = { ...remain }
    return props
  }


  const showTabIndex = 0
  const showChild = children[showTabIndex]
  const showCloned = isValidElement( showChild ) ? cloneElement( showChild, { ...childProps( showTabIndex ), ...showChild.props, key: showTabIndex } ) : showChild

  return <div className={`tabs `}>
    { showCloned }
  </div>

}