
import { max, round, abs, PI, floor, mix, min } from "../shared/math"
import { pathRadialCoord, pathRadius } from "../shared/svgUtil"
import Colour from "deepcolour"
import { computeColourOffsets } from "../shades"
import { isAngleClosed } from "../shared/radialUtil"

function ArcTriangle( props ) {
  const {
    color = "red",
    radA = 80,
    radB = 70,
    angA = 0,
    angB = 10,
    split = 0, 
  } = props

  const angC = ( angA + angB ) / 2
  let path = `M ${pathRadialCoord( radA, angA )} A ${pathRadius(radA)} 0 0 1 ${pathRadialCoord( radA, angB)} L ${pathRadialCoord(radB,angC)} Z`

  return <path d={path} fill={color} />
}

function ArcGradient( props ) {
  const {
    colorFunc,
    minR = 80,
    maxR = 30,
    minA = 0,
    maxA = 360,
    segSize = 60,
    pathProps = {},
  } = props



  const radSegs = max(1, round( abs( maxR - minR ) / segSize ) )

  const paths = []
  let index = 0

  const closed = isAngleClosed( minA, maxA )
  const angDif = closed ? 360 : abs( maxA - minA )

  for ( let radSeg = 0; radSeg < radSegs; radSeg++ ) {
    const segRadA = minR + radSeg * ( maxR - minR ) / radSegs
    const segRadB = minR + ( radSeg + 1 ) * ( maxR - minR ) / radSegs - 1

    const cy = (radSeg + 0.5) / radSegs

    const angSegs = max(1, round( (segRadA+segRadB)/2 * angDif / 180 * PI / segSize ) )

    for ( let angSeg = 0; angSeg < angSegs; angSeg++ ) {
      const segAngA = minA + angSeg * ( maxA - minA ) / angSegs
      const segAngB = minA + ( angSeg + 1 ) * ( maxA - minA ) / angSegs + 1
      const cx = (angSeg + 0.5) / angSegs


      const path = 
        `M ${pathRadialCoord( segRadA, segAngA )} `
        +`A ${pathRadius(segRadA)} 0 0 1 ${pathRadialCoord( segRadA, segAngB)} `
        +`L ${pathRadialCoord( segRadB,segAngB)} `
        +`A ${pathRadius(segRadB)} 0 0 0 ${pathRadialCoord( segRadB, segAngA)} `
        +`Z`
      const color = colorFunc( cx, cy )
      paths.push( <path key={index++} d={path} fill={color} {...pathProps} /> )

    }
  }

  return paths
}

export const mixLoop = ( a, b, t ) => {
  const dif = b - a
  if ( dif > 0.5 ) return mix( a, b - 1, t )
  if ( dif < -0.5 ) return mix( a, b + 1, t )
  return mix( a, b, t )
}

export function ColorWheel( props ) {
  const {
    minA = 90,
    maxA = 360,
    minR = 80,
    maxR = 30,
    maskId,
    maskRead = false,
    palette = [
      'red',
      // 'orange',
      'yellow',
      '#0f0',
      'cyan',
      'blue',
      'purple',
      'magenta',
      // 'magenta',
      // 'pink',
    ],
    mixHSV = true,
    yOffset = 0,
    colourOffsets = {
      sat: -0.0,
      val: -0.0,
    },
  } = props

  const closed = isAngleClosed( minA, maxA )

  const paletteSize = palette.length
  const loopSize = closed ? 
    paletteSize 
    : paletteSize - 1

  const tmpA = new Colour()
  const tmpB = new Colour()
  const tmpC = new Colour()

  const colorFunc = ( x, y ) => {

    let xInd = floor( x * loopSize )
    let xIndB = xInd + 1
    xInd = closed ? xInd % loopSize : max( 0, min( loopSize, xInd ) )
    xIndB = closed ? xIndB % loopSize: max( 0, min( loopSize, xIndB ) )

    tmpA.set( palette[xInd] )
    tmpB.set( palette[xIndB] )
    let xMix = x * loopSize - xInd

    if ( mixHSV ) {
      let hue = mixLoop( tmpA.getHSVHue(), tmpB.getHSVHue(), xMix )
      let sat = mix( tmpA.getHSVSaturation(), tmpB.getHSVSaturation(), xMix )
      let val = mix( tmpA.getHSVValue(), tmpB.getHSVValue(), xMix )
      tmpC.setHSV( hue, sat, val )
    } else {
      tmpC.set( tmpA )
      tmpC.mix( tmpB, xMix )
    }

    if ( colourOffsets ) {
      computeColourOffsets( tmpC, colourOffsets, y )
    }


    // tmpC.setChannel( 'value', y/2+0.5 )
    return tmpC.toHexString()
  }

  const {
    radA = 80,
    radB = 30,
    angA = 0,
    angB = 260,
  } = {} 

  const pathProps = {

  }
  if ( maskRead ) {
    pathProps.mask = `url(#_mask_${maskId})`
  }

  return <ArcGradient {...{minA, maxA, minR, maxR, colorFunc, pathProps }} /> 

}