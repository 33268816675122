import Colour from "deepcolour"
import { useEffect, useState } from "react"
import { compressLine, lineNormalizeField, fadeLine } from "../../src/func/lines.mjs"
import { useSurfListener } from "../context.jsx"

const { min } = Math

export function DrawHandler( props ) {
  const { 
    path, 
    colourPath,
    mergePath,
    onLine,
  } = props
  const [ state, setState ] = useState( () => ({
    lines: [],
    pointers: {},
    colour: new Colour(),
  }))

  const { colour } = state
  const { pointers } = state

  const delay = 0

  useSurfListener( { path: colourPath, type: 'change'}, event => {
    const { value } = event
    colour.set( value )
  } )

  const compressLines = ( lines ) => {
    const scheme = [
      { count: 2, size: 128, fade: 1 },
      { count: 2, size: 64, fade: 0.5 },
      { count: 2, size: 32, fade: 0.25 },
      { count: 2, size: 16, fade: 0.125 },
    ]

    let schemeId = 0
    let block
    let count
    let size

    for ( let index = 0; index < lines.length; index ++ ) {
      if ( !count ) {
        block = scheme[schemeId++]
        if ( block ) {
          count = block.count
        }
      }
      if ( block ) {
        const { size, fade } = block
        let item = lines[index]
        if ( item.length > size ) {
          item = compressLine( item, size )
        }
        if ( fade ) {
          for( let vert of item ) {
            vert[7] = fade
          }
        }

        lines[index] = item
        count --
      } else {
        while( lines.length > index ) 
          lines.pop()
      }
    }
  }

  const addLine = ( line ) => {
    line = lineNormalizeField( line, 3 )
    line = compressLine( line, min( line.length, 256 ))
    if ( onLine ) {
      onLine( serializeLines( [ line ] )[0] )
    }

    const { lines } = state

    lines.unshift( line )
    compressLines( lines )
    // const current = getData( path ) || []
    // const faded = fadeLine( current )
    // console.log( 'faded', faded )

    console.log( 'lines', lines )

    // const compLine = compressLine( [ ...faded, ...line ], compressLength )
    if ( mergePath ) {
      const value = serializeLines( lines )
      mergeData( value, mergePath )
    }
  }

  const { emitEvent, mergeData, getData } = useSurfListener( { path, delay, type:'pointer' }, ( event ) => {
    const { type } = event
    console.log('pointer?', event )
    switch ( type ) {
      case 'pointer':
        const { pointer, time } = event
        const { type, pointerId, pointerType, padX, padY, pressure, width, height } = pointer
        const pointerKey = `${pointerType}:${pointerId}`
        const pointerState = pointers[pointerKey] = pointers[pointerKey] || {}
        // console.log('colour', colour )
        let vertex = [ padX, padY, 1, time, ...colour.toArray() ]
        

        switch( type ) {
          case 'pointerdown':
            vertex[2] = 0
            pointerState.line = [ vertex ]
          break

          case 'pointermove':
            if ( pointerState.line ) {
              pointerState.line.push( vertex )
            } else if ( pressure ) {
              pointerState.line = [ vertex ]
            }
          break

          case 'pointerup':
            if ( pointerState.line ) {
              vertex[2] = 0

              pointerState.line.push( vertex )
              const line = pointerState.line
              pointerState.line = null
              addLine( line )
            }
          break
        }
      break
    }
  } )
}

function serializeNumber( value ) {
  value = parseFloat( value )
  value = Math.round( value * 1000 ) / 1000
  return value
}

function serializeLines( lines ) {
  return lines.map( line => line.map( vert => vert.map( serializeNumber ) ) )
  
  // return `[${lines.map( line => `[${line.map( vert => `[${ vert.map( serializeNumber ).join(',')}]`)}]` ).join(',')}]`
}