import { useEffect, useRef, useState } from "react"
import { ControlContent, propsToContent } from "../content"
import { optionsMatchIndex, resolveOptions } from "./options"
import { isDefined, triggerMatches } from "../util"
import { PiCaretDown } from "react-icons/pi"

import Styles from './control.module.css'
import { ControlBox } from "../box"
import { useTrancerPresets } from "../presets"
import { useParsedElements } from "../shared/elements"
import { useSizedControl } from "../shared/sizes"

export function Select( propsArg ) {
  const props = useTrancerPresets( propsArg, 'select', {
    size: 10,
    iconRight: PiCaretDown,
  })

  const {
    value,
    onValue,
    options,
    size,
    ...remain
  } = props


  const { elements, gutter } = useParsedElements( props )
  const optionsResolved = resolveOptions( options, { addBlank: true } )

  const [ optionIndex, setOptionIndex ] = useState( () => optionsMatchIndex( optionsResolved, value ) )
  const optionCurrent = optionsResolved[optionIndex] || {}

  const selectRef = useRef()

  const onChange = ( event ) => {
    const index = +event.target.value
    const nextOption = optionsResolved[index]
    onValue && onValue( nextOption.triggerValue )
    setOptionIndex( index )
  }

  useEffect( () => {
    console.log('Find Option', value )

    setOptionIndex( optionsMatchIndex( optionsResolved, value ) )
  }, [ value ])

  const onClick = ( event ) => {
    const { current } = selectRef
  }
  const displayValue = optionCurrent.label ||
    ( isDefined( optionCurrent.triggerValue ) ? optionCurrent.triggerValue :
      undefined )

  // console.log('props', optionIndex, optionCurrent.label, optionCurrent.value, displayValue )

  const contentProps = propsToContent( props, displayValue )
  
  const selectElem = <select value={optionIndex} onChange={onChange} ref={selectRef}>
    { optionsResolved.map( (option,index) => { 
      const { label, triggerValue, disabled } = option
      const contentProps = propsToContent( { ...option, showValue: !label }, triggerValue )
      const { text } = contentProps
      return <option value={index} key={index} disabled={disabled}>{ text }</option> 
    } ) }
  </select>
  const content = <ControlContent {...contentProps}></ControlContent>


  let status = {
    over : false,
    active : false,
    invert : false,
  }

  const { sizeStyles, sizeClassname } = useSizedControl( props, contentProps.size )


  return <ControlBox {...props} className={`${Styles.select} ${sizeClassname}`} style={sizeStyles} {...remain}
    {...{gutter,elements,content,status,size}}
  >
    {selectElem }
  </ControlBox>
}