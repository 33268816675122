import create from 'zustand'
import Url from 'url-parse'
import * as THREE from 'three'
import { collection, getDoc, updateDoc, addDoc, doc, Timestamp } from 'firebase/firestore'
import { app, firestore, storage } from '../firebase.js'
const mediaCollection = collection( firestore, 'media' )

export function useMediaCapture( {  } ) {
  const { enable } = {}
}

export const useMediaGlobalStore = create( ( set, get ) => ({
  _inputs: {},
  _sources: {},
  getCachedInput( key ) {
    const { _inputs } = get()
    const item = _inputs[ key ] = _inputs[ key ] || {
      key,
      usage: 0,
      loaded: false,
      element: null,
    }
    return item
  },

  getCachedSourceState( link ) {
    const { _sources } = get()
    const item = _sources[ link ] = _sources[ link ] || {
      link,
      usage: 0,
      loaded: false,
      element: null,
    }
    return item
  },
  async updateInputLink( item, link ) {
    const { getCachedSourceState, refreshSource } = get()
    let { source } = item
    if ( item.link != link ) {
      const url = Url( link )
      nextSource = getCachedSourceState( link )
    }
  
    if ( nextSource != source ) {
      if ( source ) {
        source.usage--
      }

      if ( nextSource ) {
        nextSource.usage ++
      }

      source = nextSource
    }

    await refreshSource( source )

    item.element = source.element

  },
  async refreshSource( source ) {
    const { refreshImage, refreshMedia } = get()

    const { link } = source
    const url = Url( link )

    switch ( url.protocol ) {
      case 'http:':
      case 'https:':
        source.imageLink = link
        await refreshImage( source )
      break
      
      case 'media:':
        await refreshMedia( source )
      break
    }


  },

  async refreshImage( sourceItem ) {
    const { imageLink } = sourceItem
    let { loadPromise } = sourceItem
    sourceItem.loadPromise = loadPromise = loadPromise || new Promise(async (resolve, reject) => {
      const loader = new THREE.ImageLoader()
      loader.setCrossOrigin('anonymous')
      const onLoad = (image) => {
        console.log('loaded', image)
        resolve( [ null, image ] )
      }
      const onError = ( error ) => resolve( [ error ] )
      loader.load( imageLink, onLoad, null, onError )
    })

    const [ err, element ] = await loadPromise

    sourceItem.element = element
  },

  async refreshVideo( sourceItem ) {
    const { videoLink } = sourceItem
    let { loadPromise } = sourceItem
    sourceItem.loadPromise = loadPromise = loadPromise || new Promise(async (resolve, reject) => {
      // Create video element
      const box = document.getElementById('unseen')
      var video = document.createElement('video')
      video.id = "myVideo"
      video.width = 160
      video.height = 120
      video.controls = true
      video.autoplay = true
      video.loop = true
      video.crossOrigin = 'anonymous'


      // Create source element and set attributes
      var source = document.createElement('source')
      source.src = videoLink
      source.type = "video/mp4"
      source.crossOrigin = 'anonymous'

      // Append source to video
      video.appendChild(source)

      // Append video to body or any other container
      box.appendChild(video)

      // Add event listeners
      video.addEventListener('canplay', function() {
          console.log('Video is ready to play!')
      })

      video.addEventListener('error', function() {
          console.error('Error occurred while loading video:', video.error)
      })

      // To start loading the video, you can set the video's 'src' or simply call the 'load' method
      video.load()
      resolve( [ null, video ])
    })

    const [ err, element ] = await loadPromise

    sourceItem.element = element
  },

  async refreshMedia( sourceItem ) {
    const { refreshImage, refreshVideo } = get()

    const { link } = sourceItem
    let { document } = sourceItem
    const url = Url( link )
    const mediaId = url.pathname.substring( 1 )

    if ( !document ) {
      const docRef = doc( mediaCollection, mediaId )
      const docSnap = await getDoc( docRef )

      const mediaData = docSnap.exists() ? docSnap.data() : null
      document = sourceItem.document = mediaData
    }

    if ( document ) {
      const { variants, type } = document
      const variantKey = type == 'video' ? 'mp4' : 'jpeg'
      const variant = variants[variantKey]

      if ( type == 'video' ) {
        sourceItem.videoLink = variant.link
      } else {
        sourceItem.imageLink = variant.link
      }
    } 

    if ( sourceItem.imageLink ) {
      await refreshImage( sourceItem )
    } else if ( sourceItem.videoLink ) {
      await refreshVideo( sourceItem )
    }
  }
}))

async function mediaLoadSource( link ) {
  console.log( url )


  await loadPromise

}
