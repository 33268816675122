import { Input, Group, MutantValue, Button, Slider } from 'trancer'
import { PiPlay, PiRepeat } from 'react-icons/pi'

export function ImageSource( props ) {
  const {
    state
  } = props

  return (      
    <Group color="green" layout="row" >
      <MutantValue path="video/playing">
        <Button label='Play' showLabel={false} icon={<PiPlay/>} />
      </MutantValue>
      <Slider value={0} label="progress"/>
      <Button label='Loop' showLabel={false} icon={<PiRepeat/>}/>
    </Group>
  )
}