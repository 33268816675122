// Auto-generated by library_common.js - Do not modify;
const { noop,str_length,ob_length,clock_now } = require('./constant');
// order: -5
const is_Array = a=>Array.isArray( a )
const is_Boolean = a => 'boolean' == typeof a

const is_defined = a=>a!==undefined
const is_typeof = (a,t) => ((typeof a) == t)
const is_object = ( a ) => is_typeof( a, str_object ) && a != null
const is_function = a => is_typeof( a, str_function )
const is_string = a => is_typeof( a, str_string )
const is_primitive = ( ob ) => is_Array( ob ) ? true : ob == null ? true : 'object' != typeof ob
const is_plain_object = ( ob ) => ob && ob.__proto__ == {}.__proto__
const is_null = val => val == null
const is_empty = ( ob ) => {
  for ( let key in ob ) 
    return false

  return true
}

const str_number = 'number'
const is_number = ob => is_typeof( ob, str_number )
const is_arraylike = ( ob ) => ob && is_object( ob ) && is_number( ob_length( ob ) )

const ob_keys = ob => Object.keys( ob )

const ob_map = (ob,callback) => ob.map( callback )
const ob_sort = (ob,callback) => ob.sort( callback )
const ob_pop = (ob) => ob.pop()
const ob_filter = (ob,callback) => ob.filter( callback )
const ob_filter_true = (ob) => ob_filter( ob, a => !!a )
const ob_contains = (haystack, needle ) => haystack.indexOf( needle ) != -1

const ob_indexOf = (ob,...args) => ob.indexOf( ...args )
const ob_reduce = (ob,...args) => ob.reduce( ...args )
const ob_splice = (ob,...args) => ob.splice( ...args )
const ob_split = (ob,...args) => ob.split( ...args )
const ob_split_number_list = ( ob ) => {
  ob = ob_split( ob, ',' )
  ob = ob_map( ob, a => parseFloat( a ) || 0 )
  return ob
}

const ob_replace = (ob,...args) => ob.replace( ...args )
const ob_add = (ob,...args) => ob.add( ...args )
const ob_push = (ob,...args) => ob.push( ...args )
const ob_includes = ( ob, key ) => ob.includes( key )
const ob_defineProperty = ( ...args ) => Object.defineProperty( ...args )
const ob_assign = ( ...args ) => Object.assign( ...args )

const for_key_value = ( list, func ) => {
  for ( let key in list ) {
    func( key, list[key] )
  }
}


const ob_getImageData = (ob,...args) => ob[str_getImageData]( ...args )
const ob_userData = (ob) => ob[str_userData]


const ob_set_width_height = ( ob,width,height) => {
  ob[str_width] = width
  ob[str_height] = height
}


const ob_set_needsUpdate = ( ob, value = true ) => ob.needsUpdate = value

const ob_get_width_height = ( ob ) => [ ob[str_width], ob[str_height] ]

const for_count = ( count, cb ) => {
  for ( let i = 0; i < count; i ++ ) { cb( i ) }
}

const for_count_async = async ( count, cb ) => {
  for ( let i = 0; i < count; i ++ ) { await cb( i ) }
}

const for_each = ( list, func ) => for_count( ob_length( list ), ( index ) => func( list[index], index ) )


const for_mirror_count = ( count, cb ) => {
  for ( let i = -count; i <= count; i ++ ) { cb( i ) }
}

const new_Symbol = () => Symbol()
const new_Promise = ( cb ) => new Promise( cb )


/*
  Execute func and return result.
  Ignore any thrown exceptions.
*/
const func_ignore_exception = ( func ) => {
  try {
    return func()
  } catch ( e ) {
    // 
  }
}

// -----------------------
// object.js DRY constants
// -----------------------

const str_Data = 'Data'
const str_getImageData = 'getImage'+str_Data
const str_width = 'width'
const str_height = 'height'
const str_function = 'function'
const str_string = 'string'
const str_object = 'object'
const str_userData = 'user'+str_Data


//
//  Array utilities
//
const array_push_xy = ( arr, x,y ) => {
  ob_push( arr, x ), ob_push( arr, y )
}
const array_push_xyz = ( arr, x,y,z ) => {
  array_push_xy( arr, x,y ), ob_push( arr, z )
}

const array_push_xyzw = ( arr, x,y,z,w ) => {
  array_push_xyz( arr, x,y,z ), ob_push( arr, w )
}

const array_create = ( length, func ) => Array.from({[str_length]:length},func )


//
// Unsorted wrappers
//

const Uint8Array_from = ( from ) => Uint8Array.from( from );
module.exports = { is_Array,is_defined,is_function,is_null,is_empty,is_plain_object,is_arraylike,is_number,for_key_value,new_Promise,ob_map,ob_indexOf,ob_reduce,ob_add,ob_replace,ob_sort,ob_pop,ob_push,ob_getImageData,ob_userData,ob_set_width_height,ob_keys,for_each,for_count,for_mirror_count,new_Symbol,array_create }