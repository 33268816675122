// Auto-generated by library_common.js - Do not modify;
const { noop,str_length,ob_length,clock_now } = require('./constant');
const { is_Array,is_defined,is_function,is_null,is_empty,is_plain_object,is_arraylike,is_number,for_key_value,new_Promise,ob_map,ob_indexOf,ob_reduce,ob_add,ob_replace,ob_sort,ob_pop,ob_push,ob_getImageData,ob_userData,ob_set_width_height,ob_keys,for_each,for_count,for_mirror_count,new_Symbol,array_create } = require('./object');
// order: 0
const _Math = Math
const E = _Math.E
const PI = _Math.PI
const PHI = 1.618
const half = 0.5
const EPS = 1/(2<<10)
const third = 1/3
const sixth = 1/6


const round = _Math.round
const floor = _Math.floor
const ceil = _Math.ceil
const sin = _Math.sin
const cos = _Math.cos
const acos = _Math.acos
const pow = _Math.pow
const log = _Math.log
const sqrt = _Math.sqrt
const min = _Math.min
const max = _Math.max
const atan2 = _Math.atan2
const clamp = (a,b,c) => a < b ? b : a > c ? c : a

const SQRT2 = sqrt(2)
const SQRT3 = sqrt(3)

const powE = a=>pow(E,a)
const sqr = (a)=>a*a

const _360 = 360

const degrees_to_rad = a => a / _360 * PI * 2 
const rad_to_degrees = a => a * _360 / PI / 2 
const rad_to_circ = a => a / PI / 2 

const cosD = a => cos( degrees_to_rad( a ) )
const sinD = a => sin( degrees_to_rad( a ) )
const atan2D = (a,b) => rad_to_degrees( atan2( a, b ) )
const atan2R = (a,b) => rad_to_circ( atan2( a, b ) )


const sinF = a => sinD( a * _360 )
const cosF = a => cosD( a * _360 )
const fract = a => a - floor(a)
const mod = (a,b) => a - (b * floor(a/b))

const sin_60 = sinD( 60 )
const cos_60 = cosD( 60 )


const rotate2_deg = ( x,y,r ) => {
  let s = sinD(r), c = cosD(r)
  return [x * c -y * s,x * s +y * c ]

}


const saturate = (a) => a > 1 ? 1 : a > 0 ? a : 0
const mix = ( a, b, c ) => a + (b-a) * c
const float_to_8 = ( a ) => floor( saturate( a ) * 255 )

const round_quant = ( a, b ) => round( a / b ) * b


const length_xy =(x,y) => sqrt( (x*x) + (y*y) )

const normalize_xy = (x,y) => {
  var len = length_xy(x,y)
  return [x/len,y/len]
}

const distance_xy = (a,b) => length_xy( a[0]-b[0], a[1]-b[1] )

const _parseInt = parseInt

const float_to_hex2 = ( num ) => {
  num = float_to_8( num )
  num = ob_toString_16( num )
  if ( ob_length( num ) < 2 ) 
    num = '0'+num
  return num
}

const vec3_add = ( a, b ) => ([a[0]+b[0],a[1]+b[1],a[2]+b[2]])
const vec3_mult_num = ( a, b ) => ([a[0]*b,a[1]*b,a[2]*b])
const ob_toString_16 = ob => ob.toString( 16 )
const regex_hex_greedy = /[a-fA-F0-9]/g
;
module.exports = { E,PI,PHI,half,EPS,third,sixth,round,floor,ceil,sin,cos,acos,pow,log,sqrt,min,max,clamp,SQRT2,SQRT3,powE,sqr,_360,degrees_to_rad,rad_to_degrees,cosD,sinD,sinF,cosF,fract,mod,sin_60,cos_60,rotate2_deg,float_to_hex2,mix,_Math,_parseInt,regex_hex_greedy }