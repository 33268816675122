import React from "react-dom"
import useMeasure from 'react-use-measure'
import { useMemo, useRef, useState } from "react"
import { mergeRefs } from "react-merge-refs"
import { Control, ControlContent, ControlInner } from "../component/control.jsx"
import { useSurfControl, useSurfHandler } from "../context.jsx"

import { directionIsReversed, directionIsVertical, normalizeEventValue1D } from "../util"


const { min, max } = Math
const clamp = ( val, bot, top ) => max( bot, min( top, val ) )
const saturate = ( val ) => clamp( val, 0, 1 )

const defaultShapeForDirection = ( direction ) => {
  switch ( direction ) {
    case 'left':
    case 'right':
      return 'wide'

    case 'up':
    case 'down':
      return 'tall'
  }
}

const { styled } = require('../styled.js')

import { toNormalized, fromNormalized, toNumberString } from '../../src/func/numeric.mjs'

function SliderSide( { children, side, normValue, direction, ...props } ) {

  let outer = {}
  let inner = {}

  const size = 100
  const flipSide = directionIsReversed( direction )
  const pos = size * ( flipSide ? 1-normValue:normValue)

  const left = side ^ flipSide ? pos : pos - 100


  outer.position = 'absolute'
  inner.position = 'absolute'
  

  if ( directionIsVertical( direction ) ) {
    outer.top = `${left}%`
    inner.top = `${-left}%`
    outer.width = `100%`
  } else {
    outer.left = `${left}%`
    inner.left = `${-left}%`
    outer.height = `100%`
  }

  outer.width = inner.width = `100%`
  outer.height = inner.height = `100%`


  outer.overflow = 'hidden'


  return (<div style={outer}>
    <div style={inner}>
      { children } 
    </div>
  </div>)
}





function SliderListeners( { children, onNormValue, direction } ) {
  const [ boundsRef, bounds ] = useMeasure()

  const originRef = useRef()

  const onPointerDown = ( event ) => {
    if ( event.buttons == 1 ) {
      event.preventDefault()
      const origin = originRef.current
      const value = normalizeEventValue1D( { origin, event, direction } )
      onNormValue( value )
    }
  }

  const onPointerMove = ( event ) => {
    if ( event.buttons == 1 ) {
      const origin = originRef.current
      const value = normalizeEventValue1D( { origin, event, direction } )
      onNormValue( value )
    }
  }

  const listeners = { 
    onPointerDown,
    onPointerMove,
  }

  return <ControlInner  {...listeners} ref={mergeRefs( [ boundsRef, originRef ] )}>
    { children }
  </ControlInner>
}

export function Slider( { 
  path,
  direction,
  numeric,
  slideOn = false,
  slideOff = false,
  text,
  shape,
  ...remain 
} ) {

  shape = shape || defaultShapeForDirection( direction )

  const transform =  a => a
  const { state, emitEvent, mergeData } = useSurfControl( { path, transform, delay: 5 } )
  const value = parseFloat( state )

  const normValue = saturate( toNormalized( value, numeric ) )

  const contentProps = { ...remain, text: text || toNumberString( value, numeric ) }

  const onNormValue = ( value ) => {
    value = fromNormalized( value, numeric )
    const event = {
      type: 'slider',
      path,
      value,
    }
    mergeData( value, path )
  }

  return (<Control {...{direction,shape}} {...remain}>
    <SliderListeners  direction={direction} onNormValue={onNormValue}>
      <SliderSide {...{normValue,direction}} side={0}>
        <ControlContent {...contentProps} selected></ControlContent>
      </SliderSide>
      <SliderSide {...{normValue,direction}} side={1}>
        <ControlContent {...contentProps} ></ControlContent>
      </SliderSide>
    </SliderListeners>
  </Control>)
}