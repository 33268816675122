import { useEffect, useRef, useState } from "react"

import { styled } from "../styled.js"

const styleAbsoluteFill = {
  width: '100%',
  height: '100%',
  // position: 'absolute',
  // top: 0,
  // left: 0,
}

const ContentVideo = styled('video', styleAbsoluteFill )
const ContentCanvas = styled('canvas', styleAbsoluteFill )

const { abs, floor, PI, min, max, POSITIVE_INFINITY, NEGATIVE_INFINITY } = Math
const now = () => new Date().getTime()


async function getUserWebcamStream( { options } ) {
  const {
    widthIdeal = 1280,
    heightIdeal = 720,
  } = options || {}

  const video = {
    width: { ideal: widthIdeal },
    height: { ideal: heightIdeal },
  }

  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video })
    return stream
  } catch ( err ) {
    console.error( err )
  }

}

function useTimelapseTimers( { options, onFrameTimer, onFlushTimer } ) {
  const {
    frameDelay = 50,
    flushDelay = 5000,
  } = options || {}

  const tickDelay = 10
  const [ state, setState ] = useState([0,0])

  useEffect( () => {
    let active = true
    let [ frameLast, flushLast ] = state
    const onTick = () => {
      if ( !active ) return

      const time = now()
      let ran
      if ( time - frameLast >= frameDelay ) {
        onFrameTimer()
        frameLast = time
        ran = true
      }
      if ( time - flushLast >= flushDelay ) {
        onFlushTimer()
        flushLast = time
        ran = true
      }
      setTimeout( onTick, tickDelay ) 

      if ( ran ) {
        setState( [ frameLast, flushLast ] )
      }
    }
    setTimeout( onTick, tickDelay ) 

    return () => {
      active = false
    }
  })
}



function averageImageData( context, imageDataArray ) {
  const first = imageDataArray[0]
  const { width, height } = first

  const avgImageData = context.createImageData(width, height)

  const pixelData = new Uint32Array(width * height*4)
  pixelData.fill(0)

  let numImages = 0
  for ( let item of imageDataArray ) {
    if ( item.width == width && item.height == height ) {
      const { data } = item
      for (let j = 0; j < data.length; j++) {
        pixelData[j] += data[j]
      }
      numImages ++
    }
  }


  // Compute the average pixel value
  for (let i = 0; i < pixelData.length; i++) {
    pixelData[i] = Math.round(pixelData[i] / numImages)
  }

  // Copy the pixel data to the new ImageData object
  for (let i = 0; i < pixelData.length; i++) {
    const value = pixelData[i]
    avgImageData.data[i] = value
  }

  return avgImageData
}

function refToSizedContext( ref, width, height ) {
  const canvas = ref.current
  if ( canvas.width != width || canvas.height != height ) {
    canvas.width = width
    canvas.height = height
  }
  const context = canvas.getContext('2d')
  return context
}


// Helper function to convert a data URL to a blob
function dataURLToBlob(dataUrl) {
  const parts = dataUrl.split(',')
  const mimeType = parts[0].split(':')[1].split(';')[0]
  const byteString = atob(parts[1])
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i)
  }
  return new Blob([arrayBuffer], { type: mimeType })
}

function rotatedDimensions( width, height, rotate ) {
  switch ( abs( rotate % 360 ) ) {
    case 90:
    case 270:
      return { height, width }
  }

  return { width, height }
}


function rotateRadians( rotate ) {
  // Nerfed for ease
  rotate = floor( rotate / 90 )
  rotate *= PI / 2
  return rotate 
}

function uploadImage( dataURL, fields, uploadURL ) {
  // Create a new FormData object
  const formData = new FormData()
  for ( let key in fields ) {
    formData.append( key, fields[key] )
  }
  // formData.append('target', target )
  // Append the data URL to the form data as a blob
  const blob = dataURLToBlob(dataURL)
  formData.append('image', blob)

  // return

  // Send the form data to the API using fetch()
  fetch(uploadURL, {
    method: 'POST',
    body: formData
  })
    .then(response => {
      console.log('Upload successful', response.body  )
    })
    .catch(error => {
      console.error('Error uploading image', error)
    })

}

export default function TimelapseCameraComponent( { options, onSize, onImage, uploadURL } ) {

  const { 
    accumMax = 50,
    accumMin = 1,
    rotate = 0,
  } = options || {}

  const [ accum ] = useState([])
  const [ size ] = useState( [0,0])
  const [ accumTimes ] = useState([ NaN, NaN ])
  const [ stream, setStream ] = useState()
  const videoRef = useRef()
  const scratchRef = useRef()
  const canvasRef = useRef()
  


  function onFrameTimer() {
    if ( accum.length >= accumMax )
      return

    const video = videoRef.current
    const { videoWidth, videoHeight } = video

    if ( !videoWidth || !videoHeight )
      return

    const time = now()
    
    const { width, height } = rotatedDimensions( videoWidth, videoHeight, rotate )
    
    if ( width != size[0] || height != size[1] ) {
      size[0] = width
      size[1] = height
      onSize && onSize( size )
    }

    const context = refToSizedContext( scratchRef, width, height )
    context.setTransform(1, 0, 0, 1, 0, 0)
    context.translate(width / 2, height / 2)
    context.rotate(rotateRadians( rotate ) )
    context.translate(-videoWidth / 2, -videoHeight / 2)
    context.drawImage(video, 0, 0)

    const data = context.getImageData( 0,0, width, height )
    accum.push( data )
    accumTimes[0] = isNaN( accumTimes[0] ) ? time : min( accumTimes[0], time)
    accumTimes[1] = isNaN( accumTimes[1] ) ? time : max( accumTimes[1], time)
  }

  function onFlushTimer() {
    if ( accum.length >= accumMin ) {
      const batch = accum.splice(0, accum.length )
      const averagedFrames = batch.length
      const [ timeMin, timeMax ] = accumTimes
      accumTimes[0] = accumTimes[1] = NaN
      const first = batch[0]

      const { width, height } = first
      const context = refToSizedContext( canvasRef, width, height )
      const average = averageImageData( context, batch )

      context.clearRect(0,0,width,height)
      context.putImageData( average, 0, 0 )
      const canvas = canvasRef.current
      const dataURL = canvas.toDataURL('image/jpeg')
      onImage && onImage( dataURL )


      if ( uploadURL ) {
        const meta = {
          averagedFrames,
          timeMin,
          timeMax,
        }

        uploadImage( dataURL, meta, uploadURL )
        // console.log( meta )
      }
    }
  }

  useTimelapseTimers( { options, onFlushTimer, onFrameTimer } )

  useEffect( () => {
    if ( !stream ) {
      getUserWebcamStream( { options } )
        .then( ( stream ) => setStream( stream ) )
    } else {
      videoRef.current.srcObject = stream
      videoRef.current.play()
    }

    return () => {
      if ( videoRef.current ) {
        console.log( videoRef.current )
        // videoRef.current.stop()
      }
    }
  }, [ options, stream ] )

  return <> 
    <ContentVideo ref={videoRef} style={{display:'none'}}></ContentVideo>
    <ContentCanvas ref={scratchRef} style={{display:'none'}}></ContentCanvas>
    <ContentCanvas ref={canvasRef} ></ContentCanvas>
  </>
}