import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { ChakraProvider, Box, HStack, Spacer, Heading, ColorModeScript } from '@chakra-ui/react'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import "./index.css"
import ErrorPage from './routes/error'


import App from './routes/splash'
import { LoginPage, SignupPage } from './routes/login'
import RegisterPage from './routes/register'
import ViewPage from './routes/view'
import ViewProgramPage from './routes/viewProgram'
import MediaPage from './routes/media'
import ViewProgramIndex from './routes/viewProgramIndex'
import ViewProjectIndex from './routes/viewProjectIndex'
import ViewSnapshotIndex from './routes/viewSnapshotIndex'
import Playground from './routes/playground'
import EditProjectPage from './routes/editProject'
import ViewProjectPage from './routes/viewProject'

import { analytics } from './firebase'
import { logEvent } from 'firebase/analytics'


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "signin/",
    element: <LoginPage/>
  },
  {
    path: "signup/",
    element: <SignupPage/>
  },
  {
    path: "media/",
    element: <MediaPage/>
  },
  {
    path: "register/",
    element: <RegisterPage/>
  },  
  // {
  //   path: "program/:program_name",
  //   element: <ViewProgramPage/>
  // },
  // {
  //   path: "program/",
  //   element: <ViewProgramIndex/>
  // },
  {
    path: "project/:pid",
    element: <EditProjectPage/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "view/:pid",
    element: <ViewProjectPage/>,
    // errorElement: <ErrorPage/>
  },
  {
    path: "project/",
    element: <EditProjectPage/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "projects/",
    element: <ViewProjectIndex/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "snapshot/",
    element: <ViewSnapshotIndex/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "playground/",
    element: <Playground/>,
  },

])

import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({ config: {
  initialColorMode: 'dark',
  useSystemColorMode: false,
  colors: {
    overlay: {
      100: "rgba(0,0,0,65%)",
    },
  },
}})



function G84App() {
  useEffect( () => {
    const logPageView = () => {
      logEvent( analytics, 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: document.title
      })
    }

    logPageView()
    
  })
  return <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme} >
      {/* <StatefulProgram/> */}
      <RouterProvider router={router}>

      </RouterProvider>
      {/* <ViewMenu/> */}
    </ChakraProvider>
  </React.StrictMode>
}


const root = ReactDOM.createRoot(document.getElementById('app'))
root.render(
  <G84App/>
)

