
import useMeasure from 'react-use-measure'
import { sinDeg, cosDeg, PI, asin, floor, abs, min, max, fractDeg, saturate, sqrt, atan2, fract, isRealNumber, round, isClo } from '../shared/math'

import { resolveAxes } from '../shared/axes'
import { useSliderPointerEvents, useSliderPoints, useSliderValues } from '../shared/sliderUtils'
import { useState } from 'react'

import { resolveRoundingAndOffset, arcBounds, resolveRadialArgs, isAngleClosed, svgRadialBoxPath } from '../shared/radialUtil'


import Colour from 'deepcolour'
import { useComponentShading } from '../shades'
import { ColorWheel } from './colorwheel'
import { computeElementsForPoints, useParsedElements } from '../shared/elements'
import { useTrancerPresets } from '../presets'

function SVGRadialGradient( props ) {
  const {
    id,
    gradient,
    maxR,
    hole,
  } = props

  const stops = []
  const stopCount = gradient.length

  let stopColor = new Colour()


  for ( let i = 0; i < stopCount; i++ ) {
    let { stopColor, stopOpacity } = gradient[i]
    let stopX = hole + ( i / ( stopCount - 1 ) ) * ( 1 - hole )
    stops.push( <stop key={i} offset={`${stopX*100}%`} style={{ stopColor, stopOpacity }} /> )
  }


  return <radialGradient {...{id}}  cx="0" cy="0" r={maxR} gradientUnits='userSpaceOnUse'>
    <stop key={'stop'} offset={`0%`} style={{ stopColor:'red', stopOpacity:1 }} />
    { stops }
  </radialGradient>
}


function RadialElement( props ) {
  const { element } = props
  if ( element == 'arc' ) return <RadialArc {...props} />
  if ( element == 'colourWheel' ) return <ColorWheel {...props} />

}

function RadialArc( props ) {

  let { 
    index,
    radius,
    computeShadingSVG,
    stroke,
    strokeWidth,
    fill,
    maskId,
    maskWrite,
    maskRead,
    maxR = 1,
    minR = 0,
    minA = -5,
    maxA = 5,
  } = props

  console.log('Arc', minR, maxR, minA, maxA )

  const [ gradientId ] = useState( () => '_grad'+floor( Math.random() * 100000000000 ).toString( 16 ) )
  const d = svgRadialBoxPath( props )
  const hole = minR / maxR
 

  const {
    color:strokeColor,
    gradient:strokeGradient,
  } = computeShadingSVG( `${gradientId}_stroke`, stroke )

  const {
    color:fillColor,
    gradient:fillGradient,
  } = computeShadingSVG( `${gradientId}_fill`, fill )


  fill = fill || 'none'
  stroke = stroke || 'none'

  return <>
    { maskWrite && <mask id={`_mask_${maskId}`}>
      <path {...{d,stroke,strokeWidth }} fill="white" /> 
    </mask> }
    { ( strokeGradient || fillGradient ) && <defs>
      { strokeGradient && <SVGRadialGradient id={`${gradientId}_stroke`} gradient={strokeGradient} {...{maxR,hole}} /> }
      { fillGradient && <SVGRadialGradient id={`${gradientId}_fill`} gradient={fillGradient} {...{maxR,hole}}  /> }
     </defs> }
    { !maskWrite && <path {...{d,fill,stroke,strokeWidth }} fill={fillColor} stroke={strokeColor} mask={ maskRead ? `url(#_mask_${maskId})` : null } /> }
  </>
}



const useBoundsTranslateRadial  = ( { gutter, radial, radialBounds, bounds, axes, sizes, minArea } ) => {

  const { minX, minY, maxX, maxY, widX, widY, aspect } = arcBounds( radialBounds || radial )
  const { minA, maxA, hole } = radial
  const closed = isAngleClosed( minA, maxA )
  const { width, height } = bounds

  const viewW = bounds['width']
  const viewH = bounds['height']

  const scaleX = ( bounds['width'] - gutter * 2 ) / widX
  const scaleY = ( bounds['height'] - gutter * 2 ) / widY
  const scale = min( scaleX, scaleY )
  // const scale = 100
  const radius = min( width / widX, height / widY ) - gutter * 2 
  // const radius = 10
  const diameter = radius * 2

  const viewX = (minX+maxX) * radius / 2 - viewW/2
  const viewY = (minY+maxY) * radius * 0 - viewH/2
  console.log( "huh", { minY, maxY, widY, radius, viewY, viewH,  radial } )

  const viewBox = `${viewX} ${viewY} ${viewW} ${viewH}`
  // const viewBox = `${minX * radius - gutterVB } ${minY * radius - gutterVB } ${widX * radius + gutterVB * 2 } ${widY * radius +gutterVB * 2  }`

  const styleRadial = {
    // aspectRatio: aspect,
    display: 'flex',
    flexGrow: 1,
  }

  if ( minArea > 0 ) {
    if ( aspect > 1 ) {
      styleRadial['minWidth'] = [sqrt(minArea) / aspect, 'px'].join('')
      
    } else {
      styleRadial['minHeight'] = [sqrt(minArea) * aspect, 'px'].join('')

    }
  }

  const coordFromEvent = ( event ) => {
    const bx = ( event['clientX'] - bounds['x'] ) / ( bounds['width'] ) 
    const by = ( event['clientY'] - bounds['y'] ) / ( bounds['height']  )
    const cx = viewX + bx * viewW
    const cy = viewY + by * viewH

    let x = cx
    let y = cy
    let r = sqrt( x * x + y * y ) / radius
    let ca = closed ? minA : minA - ( 360 - abs( maxA - minA ) ) / 2
    let a = fract( atan2( x, -y ) / PI / 2 - ca / 360 ) * 360 + ca

    const coord = []
    axes.map( ( axis:AxisResolvedType, index ) => {
      const { distance, radial, reversed } = axis
      let value = 0
      if ( radial == 'angle' ) {
        value = ( a - minA ) / ( maxA - minA )
      }

      if ( radial == 'distance' ) {
        value = ( r - hole ) / ( 1 - hole )
      }

      if ( reversed ) value = 1 - value

      value = saturate( value )

      coord[index] = value || 0
    })

    return coord
  }

  const eventIsInside = ( event ) => {
    const { clientX, clientY } = event
    const { left, right, top, bottom } = bounds
    if ( clientX < left ) return false
    if ( clientX > right ) return false
    if ( clientY < top ) return false
    if ( clientY > bottom ) return false
    return true
  }

  return {
    coordFromEvent,
    eventIsInside,
    viewBox,
    aspect,
    radius,
    styleRadial,
  }
}

const radialElementDefaults = [
  // { 
  //   element: 'arc',
  //   round: 20,
  //   offset: 2,
  //   strokeWidth: 4,
  //   stroke: 1,
  //   fill: 5,
  // },
  // { 
  //   element: 'arc',
  //   fill: 'red',
  //   round: 10,
  //   maskWrite: true,
  // },
  { 
    element: 'arc',
    // element: 'colourWheel',
    fill: "red",
    // fill: "darkblue",
    round: 0,
    maskRead: true,
    shift: {
      // minA: { pointIndex: 0, axisIndex: 0 },
      maxA: { pointIndex: 0, axisIndex: 0 },
    },
    offset: 4,
  },

]


type RadialOptions = {
  minA?: number,
  maxA?: number,
  hole?: number,
}


type RadialProps = {
  radial?: RadialOptions,
  radialBounds?: RadialOptions,
} & SliderValueProps & ComponentShadingProps & ComponenentElementProps


export default function Radial( propsArg : RadialProps ) {
  const props = useTrancerPresets( propsArg, 'radial', {
    trigger: "down",
    invert: false,
    matchInvert: true,
    invertFalse: true,
    elements: radialElementDefaults,
  })
  
  const { 
    value:defaultValue = [0.5,0.5],
    onValue,
    sizes,
    radial:radialArgs,
    radialBounds,
    minArea = 100000,
    numeric
  } = props

  const { computeShadingSVG } = useComponentShading( props )

  const radial = resolveRadialArgs( radialArgs )

  const { axes } = resolveAxes( props, ['cw','out'] )
  const [ boundsRef, bounds ] = useMeasure( { scroll: false, debounce:100 })

  const { pointCoords, setPointCoord } = useSliderPoints( { value:defaultValue, onValue, sizes, axes, numeric } )
  let { elements, gutter } = useParsedElements( props )



  const { 
    coordFromEvent, 
    eventIsInside, 
    viewBox,
    radius,
    styleRadial,
  } = useBoundsTranslateRadial( { gutter, radial, radialBounds, bounds, axes, sizes, minArea } )



  const { drags, pointerEvents } = useSliderPointerEvents( {
    pointCoords,
    setPointCoord,
    coordFromEvent,
  })


  let status = {
    active: true,
    over: false,
    invert: false,
  }

  let rounding = resolveRoundingAndOffset( { round: 1 } )
  let elementsComputed = computeElementsForPoints( { radius, radial, elements, axes, pointCoords, gutter, bounds, status } )

  const [ maskId ] = useState( () => '_'+floor( Math.random() * 100000000000 ).toString( 16 ) )

  console.log( 'radial', radius )

  return <div {{...pointerEvents}} className="radial" style={ styleRadial } ref={boundsRef} >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
      { elementsComputed.map( ( track, index ) => {
        console.log( 'track', track )
        return track && <RadialElement key={index} {...{radius, index, maskId, computeShadingSVG,radial}} {...track}/>
      } ) }
    </svg>
  </div>
}