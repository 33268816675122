import { state_compress } from "G8BlockParty/dist/common_js/state_high.js"
import { useEffect, useState } from "react"

const defaultIgnoreEvents  = [ null, 'clock_step' ]

export function groupControls( state ) {
  const flat = []
  function addResult( state, path ) {
    const { meta, key } = state
    const { hide } = meta
    if ( hide ) return
    
    state.each( ( child ) => {
      const { key } = child
      const childPath = path ? `${path}/${key}` : key
      addResult( child, childPath )
    })
    
    const { control } = meta

    if ( control && path ) {
      flat.push( state )
    }
  }

  addResult( state, '' )

  const mainSelect = []
  const optionsList = []
  const sliders = []
  const media = []

  flat.map( ( subState ) => {
    const { meta, path, key } = subState
    const { control, options } = meta 
    if ( control == 'range' )
      sliders.push( subState )

    if ( control == 'media' ) {
      media.push( subState )
    } else if ( options )  {
      if ( path.length > 3 ) {
        optionsList.push( subState )
      } else {
        mainSelect.push( subState )
      }
    } else {
      // console.log('unlisted', key, path )
    }
  })

  return { mainSelect, sliders, options:optionsList, media }
}

export function useStateMeta( state ) {
  const [ stateMeta, setStateMeta ] = useState( (state && state.meta) || {} )
  function onStateReset() {
    setStateMeta( { ...state.meta } )
  }

  useEffect( () => {
    function onStateEvent( nil, event ) {
      if ( event == 'reset' )
        onStateReset()
    }

    if ( state ) {
      state.sub( onStateEvent )
      return () => state.unsub( onStateEvent )
    }
  })

  useEffect( () => {
    setStateMeta( { ...( state ? state.meta : {} ) } )
  }, [ state ] )

  return stateMeta
}

export function useStateValue( state ) {
  const [ value, setValue ] = useState( state && state.value )

  useEffect( () => {
    function onStateEvent( state, event ) {
      switch( event ) {
        case undefined:
        case 'clock_step':
          return
      }
      const { value } = state
      setValue( value )
    }

    if ( state ) {
      state.sub( onStateEvent )
      return () => state.unsub( onStateEvent )
    }
  })

  return value
}

export function useStateDebouncedValue( { state, ignore = defaultIgnoreEvents, debounce = 0 } ) {
  const [ bounce, setBounce ] = useState( () => state && state.value )

  useEffect( () => {
    const update = () => {
      const { value } = state || {}
      if ( bounce !== value ) 
        setBounce( value )
    }

    const onState = ( state, event ) => {
      for ( let ignoreEvent of ignore ) {
        if ( event == ignoreEvent )
          return
      }

      update()
    }

    state && state.sub( onState )

    update()

    return () => {
      state && state.unsub( onState )
    }
  }, [ state ] )

  const [ value, setDebounced ] = useState( () => bounce )
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(bounce)
    }, debounce )
 
    return () => {
      clearTimeout(handler)
    }
  }, [ bounce ] ) 

  const mergeValue = ( value, event ) => state.merge( value, event )

  return [ value, mergeValue ]
}

export function useStateCompressed( { state, ignore = defaultIgnoreEvents, debounce = 0 } ) {
  const [ value, setValue ] = useState( () => state_compress( state ) )
  const [ next ] = useStateDebouncedValue( { state, debounce, ignore } )

  useEffect( () => {
    setValue( state_compress( next ) )
  }, [ next ] )

  return value
}



export function useStateSelectedLabel( state ) {
  state = state.nav('selected')
  let [ value, setValue ] = useState( state && state.value )

  useEffect( () => {
    function onStateEvent( state, event ) {
      switch( event ) {
        // case undefined:
        // case 'clock_step':
        //   return
      }
      const { value } = state
      setValue( value )
    }

    if ( state ) {
      state.sub( onStateEvent )
      return () => state.unsub( onStateEvent )
    }
  })


  return value
}