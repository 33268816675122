// Auto-generated by library_common.js - Do not modify;
const { E,PI,PHI,half,EPS,third,sixth,round,floor,ceil,sin,cos,acos,pow,log,sqrt,min,max,clamp,SQRT2,SQRT3,powE,sqr,_360,degrees_to_rad,rad_to_degrees,cosD,sinD,sinF,cosF,fract,mod,sin_60,cos_60,rotate2_deg,float_to_hex2,mix,_Math,_parseInt,regex_hex_greedy } = require('./math');
const { noop,str_length,ob_length,clock_now } = require('./constant');
// ---------
// PRNG Core
// ---------

/*
  Mutable global containing the current seeded PRNG. 
  Will be set by functions `prng_seed_*` 
  Defaults to a wrapper around Math.random()
  Unlike standard random, must return a value between 0 and num_maxint_32
 */
var __prng = ()=>_Math[str_random]() * num_maxint_32

/* 
  Create new pseudo random number generator given 4 32-bit seeds.
  Result will be between 0 and num_maxint_32.

  Adapted from code at:  https://github.com/bryc/code/blob/master/jshash/PRNGs.md
*/
const prng_make_tyche = (a,b,c,d) => () => {
    a |= 0; b |= 0; c |= 0; d |= 0;
    b = (b << 25 | b >>> 7)  ^ c; c = c - d | 0;
    d = (d << 24 | d >>> 8)  ^ a; a = a - b | 0;
    b = (b << 20 | b >>> 12) ^ c; c = c - d | 0;
    d = (d << 16 | d >>> 16) ^ a; a = a - b | 0;
    return (a >>> 0)
}

/*
  Random number 0..<1.
  Replaces standard Math.random()
*/
const prng_float = () => __prng() / num_maxint_32


// -------------
// PRNG Wrappers
// -------------


const prng_25th = () => floor( prng_float() * num_25 ) / num_25
const prng_18th = () => floor( prng_float() * num_18 ) / num_18

/* Random in range -1..<1 */
const prng_dir = () => prng_float()*2-1


const prng_mix_step = ( a,b,s ) => mix( a,b, prng_int( s + 1 ) / s )

/* Random a..<b */
const prng_tween = ( a,b ) => prng_float()*(b-a)+a

/* Random a..<b with logarithmic distribution */
const prng_tween_log = ( a,b ) => powE( prng_tween(log(a),log(b)) )

/* Random integer a..b ( inclusive, logarithmic distribution ) */
const prng_tween_int = ( a,b ) => round( prng_tween_log( a, b ) )

/* Random scaling factor from 0.5 to 2 */
const prng_fac2 = () => pow(2,prng_dir())

/* Random float 0..<360. */
const prng_degrees = () => prng_int( _360 )

/* Random int 0..<max_val */
const prng_int = max_val => floor(prng_float()*max_val)

/* Random int 0..<256 */
const prng_uint8 = () => prng_int( 256 )

/*
  Boolean value with configurable 'chance'.
  For example prng_chance(0.75) has 75% chance of returning true.
*/
const prng_chance = split => prng_float()<split


/* Random boolean */
const prng_bool = () => prng_chance(0.5)


const prng_range = ( range ) => {
  let [ a,b,step ] = range
  let point = prng_tween_log( a, b ) 
  if ( step )
    point = a+round((point-a)/step)*step
  return point
}

const prng_meta = ( meta ) => {
  const { min = 0, max = 1, step } = meta || {}

  let point = prng_tween( min, max ) 
  if ( step )
    point = min+round((point-min)/step)*step
  return point
}


/* Random index of given array */
const prng_pick_index = arr => prng_int( ob_length( arr ) )
 
/* Random item of given array */
const prng_pick = arr => arr[prng_pick_index(arr)]

/* Get array element by a floating point. 0 is the start of the array, 0.99 the end. x must be >=0 <1 */
const array_get_float_index = ( arr, x ) => arr[floor(saturate( x )*arr[str_length])]

// -------------------
// PRNG seed functions
// -------------------


/* Seed from 4 32-bit int */
const prng_seed_tyche = (a,b,c,d)=>{
  __prng = prng_make_tyche(a,b,c,d)
}

/* Seed randomly ( use clock ) */
const prng_seed_random = ()=>{
  __prng = prng_make_tyche(clock_now(),clock_now(),clock_now(),clock_now())
}

/* Seed from the components of an Artblocks tokenData object */  
const prng_seed_tokenId_hash = ( hash, tokenId ) => {
  const hash_substr_to_number = ( start ) => _parseInt( hash.substr( start, 8 ), 16 )
  prng_seed_tyche(
    hash_substr_to_number( 2 ) ^ hash_substr_to_number( 34 ) ^ tokenId,
    hash_substr_to_number( 10 ) ^ hash_substr_to_number( 42 ),
    hash_substr_to_number( 18 ) ^ hash_substr_to_number( 50 ) ^ tokenId,
    hash_substr_to_number( 26 ) ^ hash_substr_to_number( 58 )
  )
}

/*
  Take the global `tokenData` object from window and use to seed the PRNG.
  To be used with Artblocks deploys.
    https://github.com/ArtBlocks/artblocks-docs/blob/main/creator-onboarding/readme/features.md#features-overview
*/
const prng_seed_from_window_tokenData = () => prng_seed_from_tokenData( _window[str_tokenData] )
const prng_seed_from_tokenData = ( tokenData ) => {
  const hash = tokenData[str_hash]
  const tokenId = tokenData[str_tokenId]
  prng_seed_tokenId_hash( hash, tokenId )
  __prng()
  __prng()
}

/* Seed from a string */
const prng_seed_from_string = ( str ) => {
  let rand = prng_make_tyche()

  for( let index = 0; index < ob_length( str ); index ++ ) {
    rand = prng_make_tyche( rand(), rand(), rand(), str.charCodeAt( index ) )
  }

  prng_seed_tyche( rand(), rand(), rand(), rand() )
}

/*
  Create a random Artblock tokenData object
*/
const tokenData_randomize = () => ({
  [str_hash]: prng_hash(),
  [str_tokenId]: prng_int( 10000 ),
})


/* Random 64 digit hex number */
const prng_hash = () => {
  let hash = "0x"
  for (var i = 0; i < 64; i++) {
    hash += ob_toString_16( prng_int(16) )
  }
  return hash
}

const html_get_search_params = ( keys ) => {
  let queryParams = new URLSearchParams(window.location.search)
  const list = keys.map( ( item_key ) => queryParams.get( item_key ) )
  return list
}



const tokenData_from_url = () => {
  let result = {}

  func_ignore_exception( () => {
    let [ seed, hash, tokenId ] = html_get_search_params( [str_seed,str_hash,str_tokenId] )
    if ( seed ) {
      prng_seed_from_string( seed )
      const not_actually_random = tokenData_randomize()
      result = not_actually_random
    }
    if ( hash ) result[str_hash] = hash
    if ( tokenId ) result[str_tokenId] = tokenId
  })

  return result
}




const prng_seed_from_anywhere = () => {
  prng_seed_random()

  let tokenData = { ...tokenData_randomize(), ...tokenData_from_url() }
  if ( !_window[str_tokenData] ) {
    _window[str_tokenData] = tokenData 
  }

  return prng_seed_from_window_tokenData()
}


const prng_seed_from_fxhash= () => {
  prng_seed_tyche( ...hashes )
}


// --- 
// DRY String & Number constants
// ---

const str_seed = 'seed'
const str_hash = 'hash'
const str_tokenId = 'tokenId'
const str_tokenData = 'tokenData'
const str_random = 'random'
const num_25 = 25
const num_18 = 18
const num_maxint_32 = 4294967296 // pow(2,32);
module.exports = { prng_float,prng_dir,prng_pick,prng_bool,prng_meta }