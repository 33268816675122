import { PanelResizeHandle } from "react-resizable-panels"

import styles from "./ResizeHandle.module.css"

export default function UIResizeHandle({
  className = "",
  collapsed = false,
  id,
}) {
  return (
    <PanelResizeHandle
      className={[styles.ResizeHandleOuter, className].join(" ")}
      id={id}
    >
      <div
        className={styles.ResizeHandleInner}
        data-collapsed={collapsed || undefined}
      >

      </div>
    </PanelResizeHandle>
  )
}
