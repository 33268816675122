export const styleMainElement = {
  bg: 'blackAlpha.800',
  borderRadius: 8,
  padding: 4,
  margin: 1
}

export const styleButton = {
  background: 'blackAlpha.800',
  border: '1px',
  borderColor: 'whiteAlpha.700',
  _hover: {
    background: 'blackAlpha.500',
  },
  _active: {
    background: 'whiteAlpha.600',
    color: 'black'
  },
  m: 1
}

export const styleAppHeader = {
  
}


export const buttonSelectStyles = ( selected ) => selected ?
  {
    variant: 'solid'
  } :
  {
    variant: 'outline'
  }
