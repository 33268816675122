import { IconButton } from "@chakra-ui/react";

import { RiEyeLine, RiEyeOffLine } from "react-icons/ri"
import { useStateDebouncedValue, useStateValue } from "../../lib/reactState"
import { buttonSelectStyles } from "../../styles";

export function ToggleEnable( { state, ...props } ) {
  state = state.nav( 'enabled' )
  const [ enabled, setEnabled ] = useStateDebouncedValue( { state } )

  return <IconButton
    {...buttonSelectStyles( enabled ) }
    icon={ enabled ? <RiEyeLine/> : <RiEyeOffLine/> }
    onClick={() => { 
      setEnabled( !state.value, 'control' ) 
    } }
  />
}