import { Control, ControlContent, ControlInner } from "../component/control.jsx"
import { TriggerListeners } from "../component/triggerlisteners.jsx"
import { useSurfControl } from "../context.jsx"
import { useEffect, useState } from "react"
import { isUndefined } from "../util.js"

export default function Checkbox( { path, text, icon, iconSelected, selected, onTrigger, ...remain } ) {
  
  const transform = ( dataValue ) => ({ selected: !!dataValue })
  const { state, emitEvent, mergeData } = useSurfControl( { path, transform } )
  selected = isUndefined( selected ) ? state.selected : selected

  const contentProps = { ...remain }

  if ( text ) {
    contentProps.text = text
  } else  if ( 'string' == typeof value ) {
    contentProps.text = value
  }

  contentProps.icon = ( selected && iconSelected ) || icon 
  contentProps.selected = selected

  const onSelfTrigger = ( event ) => {

    console.log('onSelfTrigger', path, state )
    if ( path )
      mergeData( !state.selected, path )

    onTrigger && onTrigger( event )
  }

  return (<Control {...remain}>
    <TriggerListeners onTrigger={onSelfTrigger} >
      <ControlContent {...contentProps}></ControlContent>
    </TriggerListeners>
  </Control>)
}
