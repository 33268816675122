import { stringify } from "yaml"
import { numberToString } from 'numerick'
import { PiSpinner } from "react-icons/pi"


function Spinner( props ) {
  return <div><PiSpinner/></div>
}

import contentStyles from './content.module.css'
import { isArray, isNumber } from "./util"

export function ControlContent( props ) {
  const {
    icon,
    iconLeft,
    iconRight,
    label,
    serial,
    children,
    spinner,
    placeholder,
  } = props

  return <div className={contentStyles.content}>
    { iconLeft && <div className={contentStyles.icon}>{ iconLeft }</div> }
    { label && <div className={contentStyles.label}>{ `${label}` }</div> }
    { placeholder && <div className={contentStyles.placeholder}>{ `${placeholder}` }</div> }

    { serial && <div className={contentStyles.serial}><pre>{ String( serial ) }</pre></div> }
    { children }
    { iconRight && <div className={contentStyles.icon}>{ iconRight }</div> }
    { spinner && <Spinner/> }
  </div>
}

export function propsToContent( props, value ) {
  let {
    spinner,
    icon,
    iconLeft,
    iconFalse,
    iconRight,
    label,
    numeric,
    showValue = true,
    showLabel = true,
    size,
    seperator = ': ',
    placeholder = '',
  } = props

  let serial = ''

  if ( showValue ) {
    if ( numeric && isNumber( value ) ) {
      serial = numberToString( value, numeric )
    } else if ( isArray( value ) && numeric ) {
      serial = value.map( v => numberToString( v, numeric ) ).join( ', ' )
    } else {
      const replacer = ( key, value ) => {
        if ( isNumber( value ) && numeric )
          value = numberToString( value, numeric )

        if ( !value ) 
          value = ''
        return value
      }
      serial = stringify( value, replacer )
    }
  }

  if ( !serial ) {
    serial = ''
  }

  if ( !showLabel ) {
    label = ''
  }

  if ( iconFalse && !value ) {
    icon = iconFalse
  }

  if ( icon && !iconLeft ) {
    iconLeft = icon
  }

  if ( isNumber( size ) ) {
    size = [ size, 1 ]
  }

  label = label || ''
  serial = serial || ''
  

  if ( ( !label && !iconLeft ) || !serial ) {
    seperator = ''
  }

  let text = label+seperator+serial
  if ( text ) {
    placeholder = ''
  }

  if ( !size ) {
    let textWidth = 0 
    let textHeight = 0

    const divider = '\n'
    const lines = text.split( divider )
    for ( let line of lines ) {
      textWidth = Math.max( textWidth, line.length )
      textHeight++
    }

    size = [ textWidth, textHeight ]

    if ( iconLeft ) {
      size[0] += 2
    }
  }

  

  return {
    size,
    iconRight,
    iconLeft,
    label,
    serial,
    spinner,
    text,
    placeholder,
  }
}
