import { useEffect } from 'react'
import { useProjectStore } from '../lib/store'
import { Box, background } from '@chakra-ui/react'
import { G84Program } from './G84Program'

export function StatefulProgram( { background }) {
  const programLoading = useProjectStore( state => state.programLoading )
  const programInitial = useProjectStore( state => state.programInitial )
  const programState = useProjectStore( state => state.programState )
  const loadProgram = useProjectStore( state => state.loadProgram )
  const actionSetFullscreen = useProjectStore( state => state.actionSetFullscreen )
  const onProgramLoaded = useProjectStore( state => state.onProgramLoaded )

  const isFullScreen = false

  const onProgramInit = ( event ) => {
    onProgramLoaded( event )
  }

  const onProgramClick = () => {
    if ( isFullScreen ) {
      actionSetFullscreen( false )
    }
  }

  useEffect( () => {
    loadProgram( { program_name: programLoading, program_state: programInitial })
  }, [programLoading,JSON.stringify(programInitial)])

  return (
    <G84Program program={ programLoading } data={programInitial} onInit={onProgramInit} onClick={onProgramClick}/>
  ) 
}
