import { createStitches } from '@stitches/react'

const colorsMeta = {
  grey: {
    sat: 0,
    lumMin: 10,
    lumMax: 96,
  },
  white: {
    sat: 0,
    lumMin: 60,
    lumMax: 100,
  },
  black: {
    sat: 0,
    lumMin: 0,
    lumMax: 60,
  },
  red: {
  },
  orange: {
    hue: 30,
  },
  yellow: {
    hue: 60,
  },
  green: {
    hue: 120,
  },
  cyan: {
    hue: 180,
  },
  blue: {
    hue: 240,
    lumMax: 70,
  },
  purple: {
    hue: 270,
  },
  pink: {
    hue: 330,
    sat: 80,
    lumMin: 30,
    lumMax: 90,
  },
  magenta: {
    hue: 300,
  },
}

const colors = ['grey','white', 'black', 'red','orange','yellow','green','cyan', 'blue', 'purple','pink','magenta']
const mix = (a, b, c ) => a + ( b-a ) * c

function buildColors() {
  const result = {}
  const levels = 10

  for ( let name in colorsMeta ) {
    let { 
      hue = 0, 
      sat = 90, 
      lumMin = 15, 
      lumMax = 60,
    } = colorsMeta[name]

    for ( let level = 0; level < levels; level ++ ) {
      let key = `${name}${level}`
      let color = `hsl(${hue} ${sat}% ${mix( lumMin, lumMax, level / ( levels-1) )}%)`
      result[key] = color
    }
  }

  return result
}


export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: buildColors(),
  },
  media: {
    bp1: '(min-width: 480px)',
  },
  utils: {
    marginX: (value) => ({ marginLeft: value, marginRight: value }),
  },
} )

export const globalStyles = globalCss( {
  html: {
    background: 'black',
    height: '100%'
  },
  body: {
    background: 'black',
    height: '100%'
  },
  '#app': {
    height: '100%'
  }
} )

export const combineVariants = ( ...args ) => {
  let variants = {}
  let compoundVariants = []

  for ( let arg of args ) {
    variants = { ...variants, ...( arg.variants || {})}
    compoundVariants = [ ...compoundVariants, ...( arg.compoundVariants || [])]
  }

  return { variants, compoundVariants }
}

export const makeColouredVariants = ( forColor, forSelected ) => {
  const defaultColor = '$grey'
  const variants = {}
  const compoundVariants = []

  for ( let color of colors ) {
    variants.color = variants.color || {}
    variants.color[color] = forColor( `$${color}` )

    if ( forSelected )
      compoundVariants.push({
        color,
        selected: true,
        css: forSelected( `$${color}`),
      })
  }

  if ( forSelected )
    variants.selected = { true: forSelected( defaultColor ) }

  const result = { variants, compoundVariants, ...forColor( defaultColor ) }
  return result
}
