export function directionIsVertical ( direction ) {
  switch ( direction ) {
    case 'up':
    case 'down':
      return true
  }
  return false
}

export function directionIsReversed ( direction ) {
  switch ( direction ) {
    case 'left':
    case 'up':
      return true
  }
  return false
}

export function normalizeEventValue1D( { origin, event, direction } ) {
  const vertical = directionIsVertical( direction )
  const clientKey = `client${vertical ? 'Y' : 'X'}`
  const clientPos = event[clientKey]
  const rect = origin.getBoundingClientRect()
  const rectKey = vertical ? 'y' : 'x'
  const sizeKey = vertical ? 'height' : 'width'
  const result = (clientPos - rect[rectKey]) / rect[sizeKey]
  return directionIsReversed( direction ) ? 1-result : result
}


export function splitPath( path ) {
  return (path||'').split( /[\.\/]+/ ).filter( a=>!!a )
}

export const isObject = ( ob ) => ob && 'object' == typeof ob
export const isPlainObject = ( ob ) => isObject( ob )  && ob.__proto__ == {}.__proto__
export const isUndefined = ( value ) => typeof value == 'undefined'

export const isEmpty = ( value ) => {
  if ( isUndefined( value ) )
    return true

  if ( isObject( value ) ) {
    for ( let key in value ) {
      if ( !isEmpty( value[key] ) )
        return false
    }
    return true
  }

  return false
}


export function mergeDataWithEvents ( data, value, path, changes ) {
  path = splitPath( path )
  const len = path.length

  const mergeValuesAt = ( data, value, path ) => {
    const orig = data
    if ( isPlainObject( value ) ) {
      let clone
      for( let key in value ) {
        const existing = isPlainObject( data ) ? data[key] : undefined
        const next = value[key]
        const merged = mergeValuesAt( existing, next, [...path,key]  )
        if ( merged != existing ) {
          if ( !clone ) 
            clone = isPlainObject( data ) ? { ...data } : {}
          clone[key] = merged
        }
      }
      data = clone || data
    } else if ( !isUndefined( value ) ) {
      data = value
    }

    if ( changes && data !== orig ) {
      changes.push( [ path, data, orig ] )
    }

    return data
  }

  if ( len == 0 ) {
    data = mergeValuesAt( data, value, [] )
  } else {
    let target = data
    let index = 0
    const trail = []
    for ( ; index < len; index ++  ) {
      if ( isPlainObject( target ) ) {
        const seg = path[index]
        trail[index] = target
        target = target[ seg ]
      } else {
        target = null
      }
    }

    const merged = mergeValuesAt( target, value, path )
    if ( merged != target ) {
      target = merged
      for( index--; index >= 0; index -- ) {
        const seg = path[index]
        const prev = trail[index] || {} 
        target = { ...prev, [seg]: target }
        if ( changes )
          changes.push( [ path.slice( 0, index ), target, prev ] )
      }

      data = target
    }
  }

  return data
}

export const mergeData = mergeDataWithEvents