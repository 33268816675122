import { useEffect, useState } from "react"
import { useSurfListener } from "../context.jsx"

export function SurfListener( {
  path = '/',
  delay = 0,
  event = 'change',
  onEvent,
} ) {

  const { state, emitEvent } = useSurfListener( { path, delay, type: event }, ( event ) => onEvent && onEvent( event ) )


}