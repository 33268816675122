import { FilterControls } from "../components/FilterControls"
import { StatefulProgram } from "../components/StatefulProgram"
import { UIAppMain } from "../components/UIAppMain"
import { useProjectStore } from "../lib/store"



export default function Playground({}) {
  const state = useProjectStore( state => state.programState )
  const display = <StatefulProgram/>
  const controls = <>
    <ProjectRegion/>
    <FilterControls state={state}/>
  </>

  return <UIAppMain {...{controls, display}} />
}