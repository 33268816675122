.group {
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin: 4px; */
  overflow: hidden;
}

.group div {
  margin: 0;
}

.groupRow {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  width: 100%;
}

.groupColumn {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
}

.groupInline {
  display: inline-flex;
}

.groupColumn > * {
  flex-grow: 1;
}

.groupStack {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.groupStackk > * {
  position: absolute;
  inset: 0px;
}