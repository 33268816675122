import { useState } from 'react'
import { getAuth, signOut } from 'firebase/auth'
import { 
  useAuthState, 
  useSignInWithEmailAndPassword, 
  useSignInWithGithub, 
  useSignInWithGoogle,
  useCreateUserWithEmailAndPassword
} from 'react-firebase-hooks/auth'
import { app } from '../firebase.js'

import {
  Alert,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  Text,
  InputRightElement,
  AlertIcon,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock, FaGoogle, FaGithub, FaDoorOpen, FaDoorClosed } from "react-icons/fa"
import { RiFolderLine, RiLoginBoxLine, RiLogoutBoxLine } from "react-icons/ri"
import ShowError from '../components/ShowError.jsx'
import { StatefulProgram } from '../components/StatefulProgram.jsx'
import { UIPageSingle } from '../components/UIAppMain.jsx'
import { LinkButton } from '../components/LinkButton.jsx'
import { Link } from 'react-router-dom'


const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const auth = getAuth(app)
const logout = () => {
  signOut(auth)
}

function EmailPasswordForm( { text, onSubmit } ) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const handleShowClick = () => setShowPassword(!showPassword)

  return <>
    <FormControl>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<CFaUserAlt color="gray.300" />}
        />
        <Input type="email" placeholder="email address" onChange={e=>setEmail( e.target.value )}/>
      </InputGroup>
    </FormControl>
    <FormControl>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="gray.300"
          children={<CFaLock color="gray.300" />}
        />
        <Input
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          onChange={e=>setPassword( e.target.value )}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleShowClick}>
            {showPassword ? "Hide" : "Show"}
          </Button>
        </InputRightElement>
      </InputGroup>
    </FormControl>
    <Button
      borderRadius={0}
      type="submit"
      variant="solid"
      width="full"
      leftIcon={<RiLoginBoxLine/>}
      onClick={ ()=>onSubmit( email, password ) }
    >
      { text }
    </Button>
  </>

}

function UserAlreadyLoggedIn( { user } ) {
  return <>
    <Box
      spacing={4}
      p="1rem"
      backgroundColor="blackAlpha.800"
      boxShadow="md"
    >
      <Alert>
        <AlertIcon/>
        Signed as { user.email }
      </Alert>
      <br/>
      <Stack>
        <LinkButton to={`/projects/`} leftIcon={<RiFolderLine/>}>My Projects</LinkButton>
        <Button 
          leftIcon={<RiLogoutBoxLine/>}
          onClick={()=>logout()}
        >
          Sign out
        </Button>
      </Stack>
    </Box>
  </>
}


function LoginTab() {
  const [ authStateUser, authStateLoading, authStateerror ] = useAuthState(auth)
  const [
    signInWithEmailAndPassword,
    signedInUser,
    emailLoading,
    emailError,
  ] = useSignInWithEmailAndPassword(auth)

  const [signInWithGithub, loadingGithub, errorGithub] = useSignInWithGithub(auth)
  const [signInWithGoogle, loadingGoogle, errorGoogle] = useSignInWithGoogle(auth)

  const error = errorGoogle || emailError || errorGithub
  const loading = emailLoading || loadingGithub

  const user = authStateUser

  return <Box 
    spacing={4}
    p="1rem"
    backgroundColor="blackAlpha.800"
    boxShadow="md"
  >
    { user && <UserAlreadyLoggedIn user={user}/> }
    { !user && !loading &&
      <Stack

      >
        { emailError && <ShowError title="Login error" error={error}/>}
        <EmailPasswordForm text="Sign In" onSubmit={( email, password ) => {
            signInWithEmailAndPassword( email, password )
        }}/>
        <Link to="/signup">New here? Sign up!</Link>
      </Stack>
    }
  </Box>
}

function SignupTab() {
  const [ authStateUser, authStateLoading, authStateerror ] = useAuthState(auth)
  const [
    signInWithEmailAndPassword,
    signedInUser,
    emailLoading,
    emailError,
  ] = useSignInWithEmailAndPassword(auth)

  const [
    createUserWithEmailAndPassword,
    createdUser,
    createdLoading,
    createdError,
  ] = useCreateUserWithEmailAndPassword(auth)

  const [signInWithGithub, loadingGithub, errorGithub] = useSignInWithGithub(auth)
  const [signInWithGoogle, loadingGoogle, errorGoogle] = useSignInWithGoogle(auth)

  const error = errorGoogle || createdError || emailError || errorGithub
  const loading = createdLoading || emailLoading || loadingGithub


  const user = authStateUser

  return <Box 
    spacing={4}
    p="1rem"
    backgroundColor="blackAlpha.800"
    boxShadow="md"
  >
    { user && <UserAlreadyLoggedIn user={user}/> }
    { !user && !loading &&
      <Stack

      >
        { error && <ShowError title="Login error" error={error}/>}
        <EmailPasswordForm text="Register" onSubmit={( email, password ) => {
            createUserWithEmailAndPassword( email, password )
        }}/>
        <Link to="/signin">Already signed up? Sign in!</Link>
      </Stack>
    }
  </Box>  
}

export function LoginPage () {

  const [ authStateUser, authStateLoading, authStateerror ] = useAuthState(auth)
  const user = authStateUser

  return <>
    <UIPageSingle>
    <StatefulProgram background/>
    <Flex
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="blackAlpha.600" />
        <Heading color="magenta.400">Sign In</Heading>
      </Stack>
      { !user && !authStateLoading && <LoginTab/> }
      { user && <UserAlreadyLoggedIn user={user}/> }
    </Flex>
    </UIPageSingle>
  </>

}

export function SignupPage () {

  const [ authStateUser, authStateLoading, authStateerror ] = useAuthState(auth)
  const user = authStateUser

  return <>
    <UIPageSingle>
    {/* <StatefulProgram background/> */}
    <Flex
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="blackAlpha.600" />
        <Heading color="magenta.400">Sign Up</Heading>
      </Stack>
      { !user && !authStateLoading && <SignupTab/> }
      { user && <UserAlreadyLoggedIn user={user}/> }
    </Flex>
    </UIPageSingle>
  </>

}