import { isUndefined, isArray, isObject, isPlainObject, isString, isNumber, isDefined, mutateObject } from "./util"

type PathType = string[]

enum MutantValueMode {
  Undefined = 'undefined',
  Atomic = 'atomic',
  Object = 'object',
  Array = 'array',
}

enum StateUpdateMode {
  Default = 'default',
  Set = 'set',
  Merge = 'update',
  Delete = 'delete',
}

enum EventBuiltinTypes {
  set = 'set',
  default = 'default',
  update = 'update',
  delete = 'delete',
  metaUpdate = 'metaUpdate',
}

type EventOptionsObjectType = {
  type?:string,
  bubbles?:boolean,
  bubbleTo?:MutantNode,
  target?:any,
}

export type EventOptionsType = null | string | EventOptionsObjectType

type EventType = {
  type:string,
  target:MutantNode,
  bubbles:boolean,
  bubbleTo?:MutantNode,
}

type ChildrenListType = Record<string,MutantNode>

type EventCallbackType = ( event: EventType, currentTarget:MutantNode ) => void

class Listeners {

  private byKey = {}

  hasListeners() : boolean {
    return false
  }

  emitLater( key:string, event:EventType, currentTarget:MutantNode ) {
    const timer = setTimeout( () => {
      const list = this.byKey[key]
      if ( list ) {
        for ( let listener of list ) {
          listener( event, currentTarget )
        }
      }
    }, 0 )
  }

  addListener( key:string, callback:EventCallbackType ) {
    const { byKey } = this
    const list = byKey[key] = byKey[key] || []
    list.push( callback )
  }

  removeListener( key:string, callback:EventCallbackType ) {
    const { byKey } = this
    const list = byKey[key] = byKey[key] || []
    
    do {
      let ind = list.indexOf( callback )
      if ( ind == -1 ) break
      list.splice( ind, 1 )
    } while ( 1 )
  }
}

const PathResolvedKey = Symbol()
const PathDelim = /[\/]/g



function argsToPath( args ) : PathType {
  if ( args.length == 1 && args[0] && args[0][PathResolvedKey] )
    return args[0]
    
  let result:string[] = []

  function addArg( arg ) {
    if ( isArray( arg ) ) {
      arg.map( addArg )
    } else if ( isString( arg ) || isNumber( arg ) ) {
      if ( !isString( arg ) ) 
        arg = String( arg )

      const list = arg.split( PathDelim )
      list.map( item => result.push( item ) )
    }
  }

  addArg( args )
  result = result.filter( v => !!v )
  Object.defineProperty( result, PathResolvedKey, { enumerable: false, value: true })
  return result
}

const allDots = /^\.+$/


function checkAtomicDefault( value:any ) : boolean {
  if ( isArray( value ) )
    return false

  if ( isPlainObject( value ) )
    return false

  return true
}


function resolveValueModeForRoot( value: any, root:StateRoot ) : MutantValueMode {
  const { checkAtomic } = root

  if ( checkAtomic( value ) )
    return MutantValueMode.Atomic

  if ( isUndefined( value ) )
    return MutantValueMode.Undefined

  if ( isArray( value ) )
    return MutantValueMode.Array

  if ( 'object' == typeof value )
    return MutantValueMode.Object

  return MutantValueMode.Atomic
}

function resolveEvent( event : EventOptionsType, target:MutantNode ) : EventType {
  if ( event === null ) return null

  if ( isString( event ) ) {
    event = { type: String( event ) }
  }

  let { type = '*', bubbles = true, bubbleTo, ...remain } = event as EventOptionsObjectType || {}
  type = type || '*'
  return { target, type, bubbles, bubbleTo, ...remain }
}

export class MutantNode {
  checkAtomic: ( value:any ) => boolean = checkAtomicDefault
  
  readonly path : PathType
  readonly key : string
  readonly parent? : MutantNode
  readonly root : StateRoot
  readonly children : ChildrenListType
  readonly listeners : Listeners
  readonly meta : object = {}

  private _value : any = undefined
  valueMode : MutantValueMode = MutantValueMode.Undefined

  get value():any {
    return this._value
  }

  set value( value:any ) {
    this.set( value )
  }

  constructor ( parent:MutantNode|undefined, key:string ) {
    Object.defineProperty( this, 'root', { enumerable: false, value: parent ? parent.root : this })
    Object.defineProperty( this, 'parent', { enumerable: false, value: parent })

    this.key = key
    this.path = parent ? [ ...parent.path, key ] : []

    this.listeners = new Listeners()
    this.children = {}
  }

  nav( ...args ) : MutantNode | undefined {
    const path = argsToPath( args )
    let target : MutantNode = this

    for ( let seg of path ) {
      if ( allDots.exec( seg ) ) {
        for ( let dot = 0; dot < seg.length - 1; dot ++ ) {
          target = target.parent
          if ( !target ) return
        }
      } else if ( seg ) {
        target = target.child( seg )
      }
    }

    return target
  }

  get() : any {
    return this._value
  }

  set( value : any, event : EventOptionsType = EventBuiltinTypes.set ) {
    const eventResolved = resolveEvent( event, this )

    this.setNoBubble( value, eventResolved )
    this.bubbleChange()

    if ( eventResolved )
      this.emit( eventResolved )
  }

  defaults( value : any, event : EventOptionsType = EventBuiltinTypes.default ) {
    if ( this.valueMode == MutantValueMode.Undefined ) {
      this.set( value, event )
    }
  }

  child( key:string ) : MutantNode {
    if ( !this.children[key] )
      this.children[key] = new MutantNode( this, key )

    return this.children[key]
  }

  eachChild( callback : ( node:MutantNode, key:String ) => void ) {
    for ( let key in this.children ) {
      callback( this.children[key], key )
    }
  }



  update( value : any, event : EventOptionsType = EventBuiltinTypes.update ) : boolean {
    const eventResolved = resolveEvent( event, this )

    const changed = this.updateWithoutBubbling( value, eventResolved )

    if ( changed ) {
      this.bubbleChange()
    }

    return changed
  }


  delete( event : EventOptionsType = EventBuiltinTypes.delete ) {
    const eventResolved = resolveEvent( event, this )
    if ( this.deleteNoBubble( eventResolved ) ) {
      this.bubbleChange()
      if ( eventResolved )
        this.emit( eventResolved )
    }
  }


  deleteNoBubble( event : EventType ) {
    // If is undefined, deletion is redundant.
    if ( this.valueMode == MutantValueMode.Undefined )
      return false

    const childEvent = event && { ...event, bubbleTo: this }
    this.deleteChildren( childEvent )
    this._value = undefined
    this.valueMode = MutantValueMode.Undefined

    if ( event )
      this.emit( event )

    return true
  }


  async flush() {
    // Hack
    const delay = async ( ms ) => new Promise( ( resolve ) => setTimeout( resolve, ms ) )
    await delay( 2 )
  }

  private deleteChildren( event : EventType ) {
    this.eachChild( ( child, key ) => {
      child.deleteNoBubble( event )
    })
  }

  
  private updateWithoutBubbling( value : any, event : EventType ) : boolean {
    const { root } = this
    const nextValueMode = resolveValueModeForRoot( value, root )

    if ( nextValueMode != this.valueMode || nextValueMode == MutantValueMode.Atomic || nextValueMode == MutantValueMode.Undefined ) {
      const orig = this._value
      if ( value != orig ) {
        this.setNoBubble( value, event )
        return true
      } else {
        return false
      }
    }

    const childEvent = event && { ...event, bubbleTo: this }
    let clone

    for ( let key in value ) {
      const child = this.child( key )
      if ( child.updateWithoutBubbling( value[key], childEvent ) ) {
        if ( !clone ) {
          clone = this.valueMode == MutantValueMode.Array ? [ ...this._value ] : { ...this._value }
        }
        clone[key] = child.get()
      }
    }

    if ( clone ) {
      this.valueMode = nextValueMode
      this._value = clone

      return true
    }

    return false
  }

  private setNoBubble( value : any, event? : EventType ) {
    const { root } = this
    const nextValueMode = resolveValueModeForRoot( value, root )
    const childEvent = event && { ...event, bubbleTo: this }

    this._value = value
    this.valueMode = nextValueMode
    if ( nextValueMode == MutantValueMode.Array || nextValueMode == MutantValueMode.Object ) {
      this.eachChild( ( child, key ) => {
        if ( !value.hasOwnProperty( key )  )
          child.delete( childEvent )
      })

      for ( let key in value ) {
        this.child( key ).setNoBubble( value[key], childEvent )
      }
    } else {
      this.deleteChildren( childEvent )
    }
    this.emit( childEvent )
  }


  private bubbleChange() {
    const { parent, key, value } = this
    if ( !parent ) return
    
    switch ( parent.valueMode ) {
      case MutantValueMode.Atomic:
        // Possible bad behaviour: Inner child overriding atomic value
      case MutantValueMode.Undefined:
        // Convert to object by default
        parent.valueMode = MutantValueMode.Object
        parent._value = {}
    }

    switch ( parent.valueMode ) {
      case MutantValueMode.Array:
        parent._value = [ ...parent._value ]
        parent._value[key] = value
      break
      case MutantValueMode.Object:
        parent._value = { ...parent._value, [key]: value }
      break
    }

    parent.bubbleChange()
  }

  metaUpdate( value:object = {}, event:EventOptionsType = 'metaUpdate' ) {
    mutateObject( this.meta, value )
    this.emit( event )
  }

  emit( event : EventOptionsType ) {
    const eventResolved = resolveEvent( event, this )

    let currentTarget : MutantNode = this

    do {
      const { type = '*' } = eventResolved

      if ( type != '*' ) {
        currentTarget.listeners.emitLater( type, eventResolved, currentTarget )
      }

      currentTarget.listeners.emitLater( '*', eventResolved, currentTarget )

      if ( !eventResolved.bubbles ) break
      currentTarget = currentTarget.parent

      if ( eventResolved.bubbleTo == currentTarget ) break
    } while( currentTarget )
  }

  on( eventQuery : EventOptionsType, callback : EventCallbackType ) {
    const event = resolveEvent( eventQuery, this )
    const key = event.type || '*'
    this.listeners.addListener( key, callback )
  }

  async once( eventQuery : EventOptionsType = '*', callbackInner? : EventCallbackType ) {
    const event = resolveEvent( eventQuery, this )
    const { listeners } = this
    const key = event.type || '*'
    let result 
    let resolver 
    const onEvent = async ( event, currentTarget ) => {
      listeners.removeListener( key, onEvent )
      if ( isDefined( event ) ) {
        result = event
      }

      if ( callbackInner ) {
        let callbackResult = await callbackInner( event, currentTarget )
        if ( isDefined( callbackResult ) )
          result = callbackResult
      }

      resolver()
    }
    listeners.addListener( key, onEvent )
    const promise = new Promise( ( resolve ) => {
      resolver = resolve 
    })

    await promise
    return result
  }


  off( eventQuery : EventOptionsType, callback : EventCallbackType ) {
    const event = resolveEvent( eventQuery, this )
    const key = event.type || '*'
    this.listeners.removeListener( key, callback )
  }

  async wait( eventQuery : EventOptionsType ) {
    const eventQueryResolved = resolveEvent( eventQuery, this )
    const { type = '*' } = eventQueryResolved
    const event = await new Promise( (resolve,reject) => {
      this.once( eventQuery, ( event ) => resolve( event ) ) 
    })
    return event
  }
}

type StateRootOptionsType = {
  $checkAtomic?:( value:any ) => boolean,
}

export class StateRoot extends MutantNode {


  constructor( options?:StateRootOptionsType ) {

    super( undefined, '' )
  }
}