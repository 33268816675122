import { ChakraProvider, Icon, IconButton, HStack, Menu, MenuButton, MenuList, MenuItem, ButtonGroup, Button, MenuDivider } from '@chakra-ui/react'
import { FaCamera, FaExpand, FaFulcrum, FaPause, FaPlus } from 'react-icons/fa/index'
import { useProjectStore } from '../lib/store'
import { HamburgerIcon } from '@chakra-ui/icons'
import {RiLayoutBottomLine, RiLayoutLeftLine, RiLayoutRightLine, RiFullscreenFill, RiFileDownloadLine, RiLogoutBoxLine } from 'react-icons/ri'
import { useLayoutStore } from '../lib/layoutStore'
import { buttonSelectStyles } from '../styles'

function ScreenModeControl() {

  const isFullscreen = useProjectStore( state => state.isFullscreen ) 
  const actionSetFullscreen = useProjectStore( state => state.actionSetFullscreen ) 
  

  return <IconButton variant="tool" aria-label='Full screen' icon={<FaExpand/>} onClick={ ()=> actionSetFullscreen( true ) }/>
}

function CaptureControl() {

  const actionCaptureViewport = useProjectStore( state => state.actionCaptureViewport ) 
  return <MenuItem icon={<Icon as={RiFileDownloadLine}/>} onClick={ ()=> actionCaptureViewport() }>
    Save Viewport as PNG
  </MenuItem>

  return <IconButton variant="tool" aria-label='Capture canvas' icon={<FaCamera/>} />
}

function PlaybackControl() {
  return <IconButton variant="tool" aria-label='Pause playback' icon={<FaPause/>}/>
}

function NewProjectControl() {
  const actionSaveProjectAsNew = useProjectStore( state => state.actionSaveProjectAsNew )
  return <IconButton variant="tool" colorScheme="blackAlpha" icon={<FaPlus/>} onClick={ async () => { 
    const pid = await actionSaveProjectAsNew() 
    // router.push(`/project/${pid}`)
  } }>New Project</IconButton>
}

import { app } from '../firebase.js'
import { getAuth, signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
const auth = getAuth(app)
const logout = () => {
  signOut(auth)
}

function LogoutMenuItem( { } ) {
  const navigate = useNavigate()
  return <MenuItem icon={<RiLogoutBoxLine/>} onClick={()=>{
    signOut( auth )
    navigate('/')
  }}>Sign out</MenuItem>
}

export default function ViewMenu() {
  const { layout, setLayout } = useLayoutStore()


  return <Menu>
    <MenuButton
      as={IconButton}
      aria-label='Options'
      icon={<HamburgerIcon />}
      variant='outline'
    />
    <MenuList>
      {/* <MenuItem>
        <ButtonGroup>
          <IconButton icon={<Icon as={RiFullscreenFill}/>} onClick={()=>setLayout( 'full' ) } {...buttonSelectStyles( layout == 'full' )} />
          <IconButton icon={<Icon as={RiLayoutBottomLine}/>} onClick={()=>setLayout( 'bottom' ) } {...buttonSelectStyles( layout == 'bottom' )} />
          <IconButton icon={<Icon as={RiLayoutLeftLine}/>} onClick={()=>setLayout( 'left' ) } {...buttonSelectStyles( layout == 'left' )} />
          <IconButton icon={<Icon as={RiLayoutRightLine}/>} onClick={()=>setLayout( 'right' ) } {...buttonSelectStyles( layout == 'right' )} />
        </ButtonGroup>
      </MenuItem>
      <MenuDivider/> */}
      <CaptureControl/>
      <MenuDivider/>
      <LogoutMenuItem/>
    </MenuList>
  </Menu>

  return (
    <HStack
      position='fixed'
      background='whiteAlpha.700'
      zIndex={1000}
      color='black'
      borderBottomLeftRadius={10}
      top={0}
      p={0}
      right={0}
    >
      {/* <NewProjectControl/> */}
      {/* <PlaybackControl/> */}
      <ScreenModeControl/>
    </HStack>
  )
}