import { useStateMeta, groupControls as groupFilterControls, useStateValue, useStateSelectedLabel } from "../lib/reactState.js"
import { } from 'surferx'
import { Accordion, AccordionPanel, Box, AccordionItem, AccordionButton, Slider as ChakraSlider } from "@chakra-ui/react"
import { state_compress } from 'G8BlockParty/dist/common_js/state_high'

import { Checkbox, Col, Row, ControlLabelStyle, OptionsWheel, SurfProvider, SurfListener, Option } from "surferx"
import { useProjectStore } from "../lib/store.js"
import { useMemo } from "react"
import { MediaControl } from "./MediaControl.jsx"
import { MdFilterAlt } from 'react-icons/md'
import { FaCamera } from 'react-icons/fa'
import { Region, RegionButton, RegionContent } from "./ui/Region.jsx"
import { diceButtonIconProps, diceButtonProps } from "./Dice.jsx"
import { ToggleEnable } from "./ui/ToggleEnable.jsx"
import { ImageSource } from "./controls/ImageSource.jsx"

import { Select, Button, MutantValue, Slider, Group } from "trancer"
import { useBlockpartyState } from "../lib/wrapBlockpartyState.js"
import { PiPaintBrush } from "react-icons/pi"


const CameraLink = '$camera'


export function FilterControls( { state,label } ) {

  const meta = useStateMeta( state )

  const enableControls = []
  const programState = useProjectStore( state => state.programState )
  const programValue = useStateValue( programState )
  const [ data ] = useMemo( () => {
    const data = programState && state_compress( programState )
    return [ data ]
  }, [ programValue ] ) 

  const onSurfEvent = ( event ) => {
    const { value } = event

    if ( programState  ) {
      programState.merge( value, 'controls' )
    }
  }
  
  return <SurfProvider data={data}>
    <SurfListener path="/" event="change" delay={0} onEvent={onSurfEvent}/>
    <ControlsAccordion state={state}/>
  </SurfProvider>
}

export function ControlsAccordion( { state, filter } ) {
  const [ rootControls ] = useMemo( () => {
    const rootControls = []
    if ( state ) {
      state.each( ( sub ) => {
        const { key, meta } = sub
        const { label } = meta
        if ( label )
          rootControls.push( [ sub, label ] )
      })
    }
    
    return [ rootControls ]

  }, [ state ] )

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      { rootControls.map( ( [ state, label ], key ) => <ControlRegion {...{state, key }}/> )}
    </Accordion>
  )
}

export function ControlRegion( { state, isInput } ) {
  const { path, key } = state
  const enabledState = state.nav('enabled')
  const hasEnabled = enabledState.meta && enabledState.meta.control
  const isFilter = key != 'stage_0'
  const meta = useStateMeta( state )
  const sublabel = useStateSelectedLabel( state )

  let { label } = meta
  let icon = <MdFilterAlt/>
  const buttons = <>
    {/* { hasEnabled && <ToggleEnable {...{state}}/> }
    { hasDice && <RegionButton {...diceButtonProps( { state } ) } {...diceButtonIconProps()}/> } */}
  </>

  return <Region {...{label, sublabel, icon, buttons }}>
    <ControlsRegionItem state={state}/>
  </Region>
}


export function ControlsRegionItem( { state } ) {
  const meta = useStateMeta( state ) 
  const { mainSelect = [], enabled = [], options = [], sliders = [], media = [] } = state ? groupFilterControls( state ) : {}

  return <>
    { media.map( (state,key) => <MediaControl {...{key,state}}/>) }
    <RegionContent>
      <Group layout="column">
        { mainSelect.map( (state,key) => <ControlsUnit {...{key,state}}/>) }
        { options.map( (state,key) => <ControlsUnit {...{key,state}}/>) }
        <Col>
          { sliders.map( (state,key) => <ControlsUnit {...{key,state}}/>) }
        </Col>
      </Group>
    </RegionContent>
  </>
}

function controlDecorations( { label, path } ) {
  const res = {}

  res.icon = <PiPaintBrush/>

  return res
}

export function ControlsUnit( { state, index } ) {
  const { path } = state
  const { meta, value, trancerState } = useBlockpartyState( state )
  const { control, label } = meta

  console.log( 'UNIT', meta.control )

  switch ( control ) {
    case 'range':
      // return <ChakraSlider key={index} path={path} direction='right' text={label}/>

      return <>
        <MutantValue state={trancerState} key={index}>
          <Slider {...controlDecorations({label,path})} {...{label}}/>
        </MutantValue>
      </>

    case 'media':
      // return <ImageSource state={state}/>
      return <MediaControl state={state}/>

    case 'checkbox':
      return
      return <Checkbox key={index} {...{path}} text={label}/>

    case 'options':
      const { options } = meta

      return <>
      <MutantValue state={trancerState} key={index}>
        <Select {...controlDecorations({label,path})} {...{label}} {...{options}}/>
      </MutantValue>
    </>
    default:
      return <pre key={index}>Unknown control {`${control}`}</pre>
  }
}