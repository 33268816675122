import {
  Alert,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  Text,
  FormHelperText,
  InputRightElement,
  Spacer
} from "@chakra-ui/react"

import { useProjectStore } from "../lib/store"
import { useParams } from 'react-router'
import { useEffect } from "react"
import programs from 'g8blockparty/dist/common_js/programs.js'
import { Link } from 'react-router-dom'
import { state_roll_dice } from "G8BlockParty/dist/common_js/state_high.js"

import { LinkButton } from "../components/LinkButton.jsx"

import { styleMainElement } from "../styles"

import { FaEdit, FaEye, FaDice } from 'react-icons/fa'

export default function ViewProgramPage() {
  const loadProgram = useProjectStore( state => state.loadProgram )
  const programLoading = useProjectStore( state => state.programLoading )
  const programState = useProjectStore( state => state.programState )



  const program_keys = Object.keys( programs )
  const program_keys_sorted = program_keys
    .filter( ( key ) => !isNaN( parseFloat( programs[key].meta.menuOrder ) ) )
    .sort( ( a, b ) => programs[a].meta.menuOrder - programs[b].meta.menuOrder )



  return  <>

  <Flex
    flexDirection="column"
    width="calc( max( 50vw, 320px ) )"
    height="100vh"
    pt={14}
    pl={4}
  >
    { program_keys_sorted.map( key => { 
      const program = programs[key]
      const { meta, name, hash } = program
      const { title, author, description } = meta
      return <Box 
        {...styleMainElement}
      >
        <Heading>{ title }</Heading>
        <Heading size='sm'>{ name } @ {hash}</Heading>
        <Heading size='sm'>{ author }</Heading>
        <Spacer height='1em'/>
        <Text>{ description }</Text>

        <Spacer height='1em'/>

        <Flex
          flexDirection="row"
        >
        { programLoading != key &&
          <Button leftIcon={<FaEye />} onClick={()=>{
            loadProgram( { program_name: key })
          }}>Display</Button>
        }
        { programLoading == key &&
          <Button leftIcon={<FaDice />} onClick={()=>{
            programState && state_roll_dice( programState, 6 )
          }}>Randomize</Button>
        }
        <Spacer height='0.25em'/>

        <LinkButton leftIcon={<FaEdit />} to={`/program/${key}`}>Tune</LinkButton>
        </Flex>
      </Box> 
    } ) }

  </Flex>
  </>
}