import { useMemo } from "react"
import { useTrancerPresets } from "../presets"

import { parse as parseYaml } from 'yaml'
import { Labeled } from "./labeled"
import Button from "./button"
import { Tabs } from "./tabs"
import { isArray, isObject, isString } from "../util"
import { Select } from "./select"

enum ProgramLang {
  Yaml = 'yaml',
  Markdown = 'md',
  Json = 'json',
}



import Slider from './slider'
import Radial from './radial'
import { Display } from './display'
import { Grid } from './grid'
import { Input } from './input'
import Button from './button'
import { Group } from './group'
import { Select } from './select'
import Code from './code'
import { MutantValue, useMutantValue } from ".."


function ProgramSubs( { sub, subs } ) {
  subs = subs || []

  if ( !isArray( subs ) && isObject( subs ) ) {
    const keys = Object.keys( subs )
    subs = keys.map( key => ({ ...subs[key], key }) )
  }

  return subs.map( ( item, index ) => <TypedControl key={index} {...sub} {...item} /> )
}

enum TypedControlType {
  Button = 'button',
  Code = 'code',
  Display = 'display',
  Grid = 'grid',
  Group = 'group',
  Input = 'input',
  Radial = 'radial',
  Select = 'select',
  Slider = 'slider',
  Tabs = 'tabs',
}

function TypedControl( props ) {
  let { type, sub, subs, path, debounce, event, labelled, ...remain } = props
  let control = null

  switch( type ) {
    case TypedControlType.Button:
      control = <Button {...remain}/>
    break

    case TypedControlType.Code:
      control = <Code {...remain}/>
    break

    case TypedControlType.Display:
      control = <Display {...remain}/>
    break

    case TypedControlType.Grid:
      control = <Grid {...remain}/>
    break

    case TypedControlType.Group:
      control = <Group {...remain}>
        { ProgramSubs( { sub, subs } ) }
      </Group>
    break

    case TypedControlType.Tabs:
      control = <Tabs {...remain}>
        { ProgramSubs( { sub, subs } ) }
      </Tabs>
    break

    case TypedControlType.Radial:
      control = <Radial {...remain}/>
    break
    
    case TypedControlType.Select:
      control = <Select {...remain}/>
    break

    case TypedControlType.Slider:
      control = <Slider {...remain}/>
    break

    case TypedControlType.Input:
      control = <Input {...remain}/>
    break

    default:
      control = <div>Unknown control type {type}</div>
    break
  }

  if ( labelled ) {
    control = <Labeled {...labelled}>
      { control }
    </Labeled>
  }


  if ( path || debounce || event ) {
    control = <MutantValue path={path} debounce={debounce} event={event}>
      { control }
    </MutantValue>
  }


  return control
}


export default function Program( propsArg ) {
  const props = useTrancerPresets( propsArg, 'program', {} )


  const {
    source, 
    language = ProgramLang.Yaml,
    ...remain
  } = props

  const [ value, setValue ] = useMutantValue( props )


  const [ compiled, error ] = useMemo( () => {
    let program = null
    let error = null

    if ( isObject( source ) ) {
      program = source
    } else if ( isString( source ) ) {
      try {
        switch( language ) {
          case ProgramLang.Yaml:
            program = parseYaml( source )
            break
          case ProgramLang.Json:
            program = JSON.parse( source )
            break
          case ProgramLang.Markdown:
            program = source
            break
          default:
            error = `Unknown language ${language}`
        }
      } catch( e ) {
        error = e
      }
    }

    if ( isArray( program ) ) {
      program = {
        type: 'group',
        subs: program,
      }
    }

    return [ program || {}, error ]
  }, [ JSON.stringify( source ) ] )


  const content = <MutantValue {...remain}>
    <TypedControl {...compiled}/>
  </MutantValue>


  return content
}