import { Control, ControlContent, ControlInner } from "../component/control.jsx"
import { useSurfControl } from "../context.jsx"
import { PadListeners } from "../component/padlisteners.jsx"
import { useEffect, useRef } from "react"
import { styled } from "../styled.js"
import useMeasure from "react-use-measure"

const { min } = Math

const ContentCanvasItem = styled('canvas', {
  width: '100%',
  height: '100%',
})
export default function Draw( { path, ...remain } ) {

  const transform = a => a
  const { state, emitEvent } =  useSurfControl( { path, transform } )

  const lines = state


  const canvasRef = useRef()

  const onPaintStart = ( event ) => {
    const canvas = canvasRef.current
    const bounds = canvas.getBoundingClientRect()

    const width = Math.floor( bounds.width )
    const height = Math.floor( bounds.height )

    if ( canvas.width != width )
      canvas.width = width

    if ( canvas.height != height )
      canvas.height = height

  }

  const onPaintMove = ( event ) => {
    const { padX, padY } = event

    if ( event.pointerType == 'mouse' && !event.buttons )
      return

    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    const { width, height } = canvas
    const aspect = width / height
    const ax = aspect > 1 ? 1 : aspect
    const ay = aspect < 1 ? 1 : 1/aspect

    ctx.fillStyle = 'white'
    ctx.beginPath()
    ctx.ellipse( (padX/2/ax+0.5) * width, (padY/2/ay+0.5) * height, 2,2, Math.PI / 4, 0, 2 * Math.PI)
    ctx.fill()
  }

  const onPaintEnd = ( event ) => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    const { width, height } = canvas
    // ctx.clearRect( 0, 0, width, height )
  }

  const onPadEvent = ( pointer ) => {
    const event = {
      path,
      time: new Date().getTime(),
      type: 'pointer',
      pointer,
    }
    emitEvent( event )

    const { type, padX, padY } = pointer
    // console.log( canvasRef.current.getContext("2d") )

    switch( type ) {
      case 'pointerdown':
        onPaintStart( pointer )
      break

      case 'pointermove':
        if ( pointer.pressure )
          onPaintMove( pointer )
      break

      case 'pointerup':
        onPaintEnd( pointer )
      break
    }
  }

  useEffect( () => {
    if ( lines ) {
      const linesResolved = 'string' == typeof lines ? JSON.parse( lines ) : lines

      const canvas = canvasRef.current
      const ctx = canvas.getContext("2d")
      const { width, height } = canvas
      const aspect = width / height
      const ax = aspect > 1 ? 1 : aspect
      const ay = aspect < 1 ? 1 : 1/aspect
      
      ctx.clearRect( 0, 0, width, height )
      
      let stroking 

      for ( let row = 0; row < linesResolved.length; row ++ ) {
        const line = linesResolved[row]
        let lastStrokeStyle

        if ( line )
        for( let index = 0; index < line.length - 1; index++ ) {
          const vert = line[index]
          if ( !vert ) continue
          const next = line[index+1]
          const alpha = min( vert[2], line[index+1][2])
          const span = vert[3]
          let [ x, y, p, t, r, g, b, a ] = vert
          let [ nx, ny ] = next
          let strokeStyle = `rgba( ${r*100}%, ${g*100}%, ${b*100}%, ${alpha*a*100}% )`

          if ( lastStrokeStyle != strokeStyle ) {
            if ( stroking ) {
              ctx.stroke()
            }
            stroking = true
            ctx.beginPath()
            ctx.strokeStyle = strokeStyle
            ctx.lineWidth = 15
            ctx.moveTo( (x/2/ax+0.5) * width, (y/2/ay+0.5) * height )
            lastStrokeStyle = strokeStyle
          } 
          
          ctx.lineTo( (nx/2/ax+0.5) * width, (ny/2/ay+0.5) * height )
        }

        if ( stroking ) {
          ctx.stroke()
          stroking = false
        }
      }
    }
  }, [ lines ] )


  return (<Control {...remain} 
    // style={{ aspectRatio: '2'}}
  >
    <PadListeners onPadEvent={onPadEvent}>
      <ControlContent {...remain}>
        <ContentCanvasItem ref={canvasRef}/>
      </ControlContent>
    </PadListeners>
  </Control>)
}