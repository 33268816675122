// Auto-generated by library_common.js - Do not modify;
const { prng_float,prng_dir,prng_pick,prng_bool,prng_meta } = require('./prng');
const { state_root,state_value,state_nav,state_path,state_each,state_listen,state_merge_value,state_merge_path_value,merge_values } = require('./state');
const { is_Array,is_defined,is_function,is_null,is_empty,is_plain_object,is_arraylike,is_number,for_key_value,new_Promise,ob_map,ob_indexOf,ob_reduce,ob_add,ob_replace,ob_sort,ob_pop,ob_push,ob_getImageData,ob_userData,ob_set_width_height,ob_keys,for_each,for_count,for_mirror_count,new_Symbol,array_create } = require('./object');
const { E,PI,PHI,half,EPS,third,sixth,round,floor,ceil,sin,cos,acos,pow,log,sqrt,min,max,clamp,SQRT2,SQRT3,powE,sqr,_360,degrees_to_rad,rad_to_degrees,cosD,sinD,sinF,cosF,fract,mod,sin_60,cos_60,rotate2_deg,float_to_hex2,mix,_Math,_parseInt,regex_hex_greedy } = require('./math');
const state_merge_from_global = ( state ) => {
  state_merge( state, global_state_merge )
}


const state_has_dice = ( state ) => {
  if ( state_has_dice_self( state ) ) return true
  const { branch, hide } = state || {}
  if ( hide ) return false

  if ( !branch ) return false
  for ( let key in branch ) {
    if ( state_has_dice( branch[key] ) )
      return true
  }
}

const state_has_dice_self = ( state ) => {
  const { meta } = state || {}
  const { control, hide, options, dice } = meta || {}

  if ( hide ) return false
  if ( dice === false ) return false

  if ( is_plain_object( options ) && !is_empty( options ) ) return true 
 
  if ( control == 'range' ) return true
  if ( control == 'position' ) return true

  return false
}

const state_roll_dice = ( state, rolls = 1 ) => {
  let result = false
    
  if ( state_has_dice_self( state ) ) {
    const value = state_dice_value( state )
    if ( is_defined( value ) ) {
      state_merge_value( state, value, 'dice' )
      result = true 
    }
  } else if ( rolls > 0 ) {
    const { branch } = state
    const keys = ob_keys( branch )
    for_count( rolls, () => {
      let tries = 10
      while( tries-- > 0 ) {
        const child = branch[prng_pick( keys )]
        if ( child && state_has_dice( child ) && state_roll_dice( child ) ) {
          result = true
          break
        }
      }
    })
  } else {
    state_each( state, ( state, key ) => {
      if ( state_roll_dice( state, 0 ) ) 
        result = true
    } )
  }

  return result
}

const state_dice_default = ( state ) => {
  state_default( state, state_dice_value( state ) )
}

const state_dice_deep_default = ( state ) => {
  state_walk( state, ( state ) => state_default( state, state_dice_value( state ) ) )
}

const vec_length = ( vec ) => {
  let total = 0
  for_each( vec, scal => {
    total += ( scal * scal ) || 0
  })
  return sqrt( total )
}

const prng_norm = ( size = 3 ) => {
  const result = array_create( 3, prng_dir )
  const len = vec_length( result )
  return ob_map( result, a => a / len )
}

const state_dice_value = ( state ) => {
  const { meta } = state
  const { control, options } = meta
  let value
  if ( is_plain_object( options ) && !is_empty( options ) ) {
    const keys = ob_keys( options || {} )
    value = options[ prng_pick( keys ) ]
  } else if ( control == 'range' ) {
    value = prng_meta( meta )
  } else if ( control == 'checkbox' ) {
    value = prng_bool()
  } else if ( control == 'position' ) {
    const pos = array_create( 2, () => prng_dir() )
    const len = vec_length( pos )
    const rad = prng_float()
    const circ = ob_map( pos, a => a / len * rad )
    value = circ
  }
  return value
}

const state_compress_quant = 100
const state_compress_value = ( value ) => 
  // is_number( value ) ? round( value * state_compress_quant ) / state_compress_quant : 
  value

const state_compress = ( state ) => {
  const { meta, value } = state
  const { ignore, keep } = meta
  if ( ignore ) return
  if ( !is_null( value ) && keep ) return state_compress_value( value )
  let result
  state_each( state, ( child ) => {
    const { key } = child
    const value = state_compress( child )
    if ( is_defined( value ) ) {
      result = result || {}
      result[key] = value
    }
  })
  return result
}


const state_minify_state_value2string = ( state ) => {
  const { meta, value } = state
  const { options } = meta || {}
  let result
  if ( options ) {
    const keys = ob_keys( options )
    for_each( keys, ( key, index ) => {
      if ( options[key] == value ) 
        result = `@${index}`
    } )
  }

  if ( !result ) {
    result = state_minify_value2string( value )
  }

  return result
}

const state_minify = ( state ) => {


};
module.exports = { state_roll_dice,state_compress }