import { useState } from "react"

import {
  Alert,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  Text,
  FormHelperText,
  InputRightElement
} from "@chakra-ui/react"

import { useProjectStore } from "../lib/store.js"
import { useParams, useNavigate } from 'react-router-dom'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useEffect } from "react"

import { styleMainElement, styleButton } from "../styles.js"
import { state_roll_dice } from "G8BlockParty/dist/common_js/state_high.js"
import { FaDice, FaPlus } from 'react-icons/fa'
import { firestore } from "../firebase.js"
import { doc } from 'firebase/firestore'
import { useRemoteStateWrite, useRemoteStateRead } from "../lib/useRemoteState.js"

import { FilterControls } from "../components/FilterControls.jsx"
import { AppHeader, UIAppMain } from "../components/UIAppMain.jsx"
import { StatefulProgram } from "../components/StatefulProgram.jsx"
import { ProjectRegion } from "../components/ProjectRegion.jsx"

const { stringify } = JSON

import { useProjectWriteUpdates } from "../lib/useProjectWriteUpdates.js"
import { Viewport } from "../components/Viewport.jsx"

export default function EditProjectPage() {
  const loadProject = useProjectStore( state => state.loadProject )
  const state = useProjectStore( state => state.programState )
  const actionSaveProjectAsNew = useProjectStore( state => state.actionSaveProjectAsNew )
  const actionSaveSnapshot = useProjectStore( state => state.actionSaveSnapshot )

  const navigate = useNavigate()
  const { pid } = useParams()


  useEffect( () => {
    if ( pid ) {
      console.log("Loading project", pid )
      loadProject( pid )
    }
  }, [pid])

  useRemoteStateWrite( { state, pid } )
  useRemoteStateRead( { state, pid } )
  useProjectWriteUpdates( { projectId: pid, state } )


  const display = <Viewport showMenu>
    <StatefulProgram/>
  </Viewport>

  const controls = <>
    <AppHeader/>
    <ProjectRegion/>
    <FilterControls state={state}/>
  </>
  return <UIAppMain {...{display,controls}}></UIAppMain>
}