import create from 'zustand'
import { state_compress } from 'G8BlockParty/dist/common_js/state_high'
import { collection, getDoc, updateDoc, addDoc, doc, Timestamp } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import { getAuth } from 'firebase/auth'


import { app, firestore, storage } from '../firebase.js'
import { extname, basename } from 'path-browserify'
import { make_slug_name } from './words'
const projectCollection = collection( firestore, 'projects' )
const snapshotCollection = collection( firestore, 'fragments' )

export const useProjectStore = create( ( set, get ) => ({
  canvas: null,
  programState: null,
  programLoaded: null,
  programInitial: null,
  projectId: null,
  projectData: null,
  onProgramLoaded: ( programLoaded ) => {
    const { state, loop, item, canvas } = programLoaded
    const { name } = item
    const current = get()
    const { programInitial, programLoading } = current

    if ( programInitial ) {
      state.merge( programInitial )
    }
    set( { programLoaded, programState: state, canvas, programInitial, programName: name || programLoading } )
  },
  actionSetFullscreen: ( value ) => {
    const current = get()

    let isFullScreen = !!value
    set( { isFullScreen } )
  },
  actionCaptureViewport: () => {
    const current = get()
    const { canvas, programName } = current

    console.log('download', canvas )
    canvas.toBlob( ( blob ) => {
      console.log('blob', blob )

      var downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.download = `g84_${programName}_${new Date().getTime()}.png`;

      // create an object URL from the Blob
      var URL = window.URL || window.webkitURL;
      var downloadUrl = URL.createObjectURL(blob);

      // set object URL as the anchor's href
      downloadLink.href = downloadUrl;

      // append the anchor to document body
      document.body.append(downloadLink);

      // fire a click event on the anchor
      downloadLink.click();
    } )
  },
  loadProgram: async ( item ) => {
    const { program_name, program_state } = item
    const current = get()
    const { programState, programLoaded = {} } = current
    const { name } = (programLoaded || {}).item || {}

    if ( name != program_name ) {
      console.log('loadProgram', { programLoading: program_name, programInitial: program_state } )
      set( { programLoading: program_name, programInitial: program_state } )
    } else if ( programState )
      programState.merge( program_state )
  },
  setProjectProgram: ( programLoaded ) => {
    const { state } = programLoaded
    set( { programLoaded, programState: state } )
  },

  loadProject: async ( pid ) => {
    const current = get()
    let { projectData } = current
    const { loadProgram } = current
    const docRef = doc( projectCollection, pid )
    const docSnap = await getDoc( docRef )

    if ( docSnap.exists() ) {
      projectData = docSnap.data()
      await loadProgram( projectData )
      const { slug_name, title } = projectData
      set( { projectId: pid, projectName: title || slug_name, projectData } )
      // console.log( '*******', data )
    } 
  },
  getProjectData: () => {
    const { programState, programLoaded } = get()
    if ( !programState )
      return {}

    const { item } = programLoaded
    const program_state = state_compress( programState )
    const program_name = item.name
    const program_hash = item.hash
    const timestamp = Timestamp.fromDate(new Date())
    return { program_name, program_hash, program_state, created: timestamp, updated: timestamp, app_source: 'g84rethunk' }
  },
  updateProjectState: async ( program_loaded ) => {
    const current = get()
    const { projectId } = current
    if ( !projectId ) return
    const { program_state } = program_loaded
    if ( !program_state ) return
    const docRef = doc( projectCollection, projectId )
    console.log( 'updateState', projectId, { program_state } )

    await updateDoc( docRef, { program_state }  )
  },
  updateProjectData: async ( fields ) => {
    const current = get()
    let { projectId, projectData } = current

    if ( !projectId ) return
    const docRef = doc( projectCollection, projectId )
    await updateDoc( docRef, fields )
    console.log( 'updateDoc', projectId, fields )

    set( { projectData: {...projectData, ...fields}})
  },
  actionSaveProjectAsNew: async () => {
    const auth = getAuth()
    const { currentUser = {} } = auth
    const { uid = '_nouser' } = currentUser || {}
    const slug_name = make_slug_name()
  
    const project_data = {
      ...(get().getProjectData()),
      public: false,
      hidden: false,
      slug_name,
      uid,
    }
    const doc = await addDoc( projectCollection, project_data )
    const pid = doc.id
    set( { projectId: pid, projectName: slug_name } ) 
    return pid
  },

  actionSaveSnapshot: async ( { fragment_source = 'snapshot', app_source='g84rethunk' } = {} ) => {
    const { projectId, getProjectData, projectName } = get()

    const timestamp = new Date().toJSON().substr(11,5)
    const slug_name = (projectName || 'untitled') + ' ' + timestamp
    const project_data = {
      ...(getProjectData()),
      project_id: projectId,
      fragment_source,
      app_source,
      slug_name,
    }
    const doc = await addDoc( snapshotCollection, project_data )
    const pid = doc.id
    set( { } ) 
    return pid
  },  
}))


export const useSessionStore = create( ( set, get ) => ({

}))