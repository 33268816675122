import { isUndefined, isArray } from "../util"

export function useSizedControl( props, contentSize ) {
  let { size, gutter } = props

  if ( isUndefined( size ) ) {
    size = contentSize || 0
  }

  

  if ( !isArray( size ) ) {
    size = parseFloat( size ) || 0
    size = [ size, 1 ]
  }

  console.log('useSizedControl', size )

  const sizeStyles = {
    minWidth: size[0] ? `calc( ${size[0]+1}rem)` : '100%',
    minHeight: size[1] ? `calc( ${size[1]+1}rem)` : undefined,
  }

  const sizeClassname = ''

  return { sizeStyles, sizeClassname }
}