import { useState, useEffect } from "react"
import { useProjectStore } from "./store"

const { stringify } = JSON

export function useProjectWriteUpdates( { projectId } ) {
  const [ projDump, setProjDump ] = useState( stringify( {foo:'bar'} ) )
  const state = useProjectStore( state => state.programState )
  const getProjectData = useProjectStore( state => state.getProjectData )
  const updateProjectState = useProjectStore( state => state.updateProjectState )

  const onStateUpdate = () => {
    const project_data = getProjectData()
    const nextProjDump = stringify( project_data )
    if ( nextProjDump != projDump )
      setProjDump( nextProjDump )

    updateProjectState( project_data )
  }

  useEffect( () => {
    let active = true
    let timer = null
    const debounce = 200

    const onState = ( state, event ) => {
      if ( !event || event == 'clock_step' || event == 'default' ) return
      tick()
    }

    const tick = () => {
      if ( timer ) {
        clearTimeout( timer ) 
        timer = null
      }
      if ( active ) {
        timer = setTimeout( () => {
          if ( active )
            onStateUpdate()
          timer = null
        }, debounce)
      }
    }
    state && state.sub( onState )

    return () => {
      state && state.unsub( onState )
      active = false
    }
  }, [ state ] )
}