import React, { Children, cloneElement, createContext, isValidElement, useContext, useEffect, useState } from "react"

import { ControlContent, propsToContent } from "../content"

import labeledStyles from './labeled.module.css'



export function Labeled( props ) {
  const {
    label = '',
    icon,
    leftIcon,
    rightIcon,
    children,
    color,
    ...remain
  } = props

  const contentProps = propsToContent( { ...props, showValue:false }, null )


  const revisedChildren = Children.map( children, ( child ) => {
    return isValidElement( child ) ? cloneElement( child, { ...remain, color, ...(child.props) } ) : child
  })
  return <div className={`labeled ${labeledStyles.labeledStyle}`} color={color}>
    <span className={`label ${labeledStyles.labelStyle}`}>
      <ControlContent {...contentProps}>
      </ControlContent>
    </span>
    {/* <div className="content"> */}
      { revisedChildren }
    {/* </div> */}
  </div>
}