// Auto-generated by library_common.js - Do not modify;
// order: -10
const _one = ()=>1
const noop = () => {}


const str_length = 'length'
const ob_length = ob => ob[str_length]
const clock_now = () => new Date().getTime()

;
module.exports = { noop,str_length,ob_length,clock_now }