import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Textarea,
  Button,
  Select,
  VStack,
  Heading,
  Checkbox,
  Flex,
  Box,
  HStack,
  Text,
} from '@chakra-ui/react'

import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'


import { collection, getDoc, updateDoc, addDoc, doc, Timestamp, setLogLevel } from 'firebase/firestore'
import { app, firestore, storage } from '../../firebase.js'
import { useState } from 'react'
import { queryPromptMedia, queryRecentPrompts } from '../../lib/queries.js'
import { useCollection } from 'react-firebase-hooks/firestore'
import { getAuth } from 'firebase/auth'
import { MediaList } from '../MediaControl.jsx'
const promptCollection = collection( firestore, 'prompt' )


const Text2ImageModels = [
  "SG161222/RealVisXL_V4.0_Lightning",
  "stabilityai/stable-diffusion-3-medium-diffusers",
  "runwayml/stable-diffusion-v1-5",
  "ByteDance/SDXL-Lightning",
  "stabilityai/stable-diffusion-xl-base-1.0",
  "SG161222/RealVisXL_V4.0",
  "SG161222/Realistic_Vision_V6.0_B1_noVAE",
  "black-forest-labs/FLUX.1-dev",
  "black-forest-labs/FLUX.1-schnell",
]

const sizes = [
  "1024x1024",
  "1024x630",
  "630x1024",
]


async function addPromptDoc( fields ) {
  try {
    const auth = getAuth()
    const { currentUser = {} } = auth
    const { uid = 'anon' } = currentUser || {}
    const docRef = await addDoc( promptCollection, {
      ...fields,
      uid,
      status: 'pending',
      created: Timestamp.now(),
      updated: Timestamp.now(),
    })
    return [ docRef.id, null ]
  } catch ( error ) {
    console.error( error )
    return [ null, error ]
  }
}

function PublicCheckbox( { value, onValue, children } ) {
  return <Checkbox isChecked={value} onChange={( e ) => onValue( !!e.target.checked)}>{ children }</Checkbox>
}

function PromptItem( { document, onSelect } ) {
  const prompt_id = document.id
  const data = document.data()
  const { prompt, model_id, width, height, seed, status } = data
  
  const truncateLength = 50
  const promptTruncated = prompt.length > 50 ? prompt.slice(0,50) + '...' : prompt

  const query = queryPromptMedia( prompt_id )

  return <Box borderWidth='1px' borderRadius='lg' padding={2} width="100%">
    <HStack>
      <Text>{ promptTruncated }</Text>
      <Text> : </Text>
      <Text>{ seed }</Text>
    </HStack>
    <MediaList {...{onSelect,query}}/>
  </Box>
}

function PromptList( { onSelect }) {
  // List prompts from promptCollection
  const query = queryRecentPrompts()

  const [value, loading, error] = useCollection(
    query,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )


  console.log("ERROR", error) 

  return (
    <div>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Loading...</span>}
        {value && (<>
          <Flex flexWrap={"wrap"}>
            {value.docs.map((doc,key) => <PromptItem key={key} document={doc} onSelect={onSelect}/>)}
          </Flex>
        </>)}
    </div>
  )


}

export default function MediaPrompt( { state, onCreated, onSelect } ) {

  const [ fields, setFields ] = useState( {
    public: true,
    model_id: Text2ImageModels[0],
    prompt: 'regenerate',
    width: 1024,
    height: 1024,
    guidance_scale: 7.5,
    negative_prompt: '',
    safety_check: false,
    seed: 0,
    num_inference_steps: 20,
    num_images_per_prompt: 4,
  } )

  const [ loading, setLoading ] = useState( false )
  const handleCreateDocument = async () => {
    const data = { ...fields }
    data.seed = data.seed || Math.floor( Math.random() * 1000000 )
    setLoading( true )
    const [ docId, error ] = await addPromptDoc( data )
    setLoading( false )

  }


  return <>
        <VStack spacing={4} align="stretch">
          {/* <Heading as="h2" size="small" textAlign="center">Powered by livepeer</Heading> */}
          <PublicCheckbox value={fields.public} onValue={( v ) => setFields( { ...fields, public:v })}>This prompt is public</PublicCheckbox>
          <FormControl>
            <FormLabel>Text to Image Prompt</FormLabel>
            <Textarea value={fields.prompt} onChange={( e ) => setFields( { ...fields, prompt: e.target.value })}/>
          </FormControl>
          <FormControl>
            <FormLabel>Model</FormLabel>
            <Select
              placeholder="Select AI Model"
              value={`${fields.model_id}`}
              onChange={(e) => { setFields( { ...fields, model_id: e.target.value })}}
            >
              {Text2ImageModels.map( model => <option key={model} value={model}>{model}</option>)}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Size</FormLabel>
            <Select
              placeholder="Select image size"
              value={`${fields.width}x${fields.height}`}
              onChange={(e) => {
                let [width, height] = e.target.value.split('x').map( n => parseInt( n ) )
                setFields( { ...fields, width, height })
              }}

            >
              {sizes.map( size => <option key={size} value={size}>{size}</option>)}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Number of results</FormLabel>
            <NumberInput defaultValue={4} min={1} max={10}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {/* <Input value={fields.num_images_per_prompt} onChange={( e ) => setFields( { ...fields, num_images_per_prompt: parseFloat( e.target.value ) })}/> */}
          </FormControl>

          <FormControl>
            <Button
              isLoading={loading}
              onClick={handleCreateDocument}
            >Create Prompt</Button>
          </FormControl>
          
          <PromptList onSelect={onSelect}/>
        </VStack>
  </>
}


