import { useEffect, useRef, useState } from "react"
import { ref, update, get, onValue } from "firebase/database"
import { state_compress } from 'G8BlockParty/dist/common_js/state_high'
import { Timestamp } from 'firebase/firestore'
import { database } from "../firebase.js"

export function useRemoteStateWrite( { state, pid } ) {
  return
  const cooldown = 2000
  const cooldownStart = useRef(0)
  const onStateUpdate = () => {
    if ( Date.now() - cooldownStart.current < cooldown ) {
      console.log('cooldown')
      return
    }
    if ( pid ) {
      console.log('onStateUpdate', pid )
      const program_state = state_compress( state )
      const updated = Timestamp.fromDate(new Date())
      const project_data = { program_state, updated }
      update(ref(database, `project/${pid}` ),project_data )
    }
  }
  
  useEffect( () => {
    cooldownStart.current = Date.now()
    let active = true
    let timer = null
    const debounce = 200

    const onState = ( state, event ) => {
      if ( !event || event == 'clock_step' ) return

      onStateUpdate()
    }

    state && state.sub( onState )

    // tick()
    return () => {
      state && state.unsub( onState )
      active = false
    }
  }, [ state ] )
}

export function useRemoteStateRead( { state, pid } ) {
  return
  useEffect( () => {
    const item = ref(database, `project/${pid}` )
    return onValue(item, (snapshot) => {
      const data = snapshot.val()
      const { program_state } = data || {}
      if ( state ) {
        state.merge( program_state )
      }
    })
  })
}