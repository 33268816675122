import { useParams } from "react-router-dom"
import { StatefulProgram } from "../components/StatefulProgram"
import { Viewport } from "../components/Viewport"
import { useProjectStore } from "../lib/store"
import { useEffect, useMemo } from "react"
import { useRemoteStateRead } from "../lib/useRemoteState"

import { app, firestore, storage } from '../firebase.js'
import { collection, doc } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { G84Program } from "../components/G84Program.jsx"
import { program } from "G8BlockParty/dist/common_js/program/g84gateway_SPGGPpDpnDh.js"
const projectCollection = collection( firestore, 'projects' )


export default function ViewProjectPage( props ) {
  const loadProject = useProjectStore( state => state.loadProject )
  const state = useProjectStore( state => state.programState )
  const params = useParams()
  const { pid } = params
  const projectRef = pid && doc( projectCollection, pid )

  console.log( 'ViewProjectPage', pid, params, props )
  const [ projectData, projectLoading, projectError ] = useDocumentData( projectRef )



  // const project_data = {}

  useEffect( () => {
    if ( pid )
      loadProject( pid )
  }, [pid])

  // useRemoteStateRead( { state, pid } )

  

  const programLoading = projectData && projectData.program_name
  const programInitial = projectData && projectData.program_state
  const onProgramInit = ( event ) => {
  }
  const onProgramClick = () => {
  }

  // const program = useMemo( () => <G84Program program={ programLoading } programInitial={programInitial} onInit={onProgramInit} onClick={onProgramClick}/>, [ programLoading ] )
  const program = <StatefulProgram/>
  useRemoteStateRead( { state, pid } )


  return <>
    <Viewport showMenu background>{ program }</Viewport>
    {/* <pre>{ JSON.stringify( projectData, null, 2 ) }</pre> */}
    {/* <pre>{ JSON.stringify( projectError ) }</pre> */}
  </>
}