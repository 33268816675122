import { Box, Button, Icon, IconButton, Center, Collapse, Flex, Heading, Stack, Text } from "@chakra-ui/react"
import { useState } from "react"
import { MdExpandMore, MdExpandLess } from 'react-icons/md'
import useLocalStorage from "react-use-localstorage"


export function Region( { level = 0, label, sublabel, icon, children, size, buttons, showDefault = true } ) {
  const localStorageId = `persistRegion_${label}`
  const [showString, setShow] = useLocalStorage( localStorageId, showDefault )
  const show = showString != 'false'
  const handleToggle = () => setShow(!show)


  size = size || ['sm','xs'][level]

  return (
    <>
      <Stack mt={2} py={1} px={2+level * 2} direction="row" alignItems={'center'} background={"blackAlpha.300"}>

        <IconButton 
          icon={show? <MdExpandLess/> : <MdExpandMore/> } 
          onClick={handleToggle}
          variant={ show ? 'outline' : 'solid'}
          {...{size}}
        />
        <Flex flexGrow={2} placeItems={'center'}>
          <Text {...{size}} >
            { label }
            { sublabel && <Text as="b" ml={2} {...{size}} >
              : { sublabel }
            </Text> }
          </Text>
        </Flex>
        { buttons }
      </Stack>

      <Collapse in={show}>
        { children }
      </Collapse>
    </>
  )
}

export function RegionButton( { ...props } ) {
  return <IconButton {...props}>

  </IconButton>
}

export function RegionContent( { children } ) {
  return <Box margin={3}>
        { children }
  </Box>
}