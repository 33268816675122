import React, { createContext, useContext, useEffect, useState } from "react"
import { mergeDataWithEvents, splitPath } from "./util"

const SurfContext = createContext( {} )

export function SurfProvider ( props ) {
  const { data, children } = props
  const [ state ] = useState( () => ({ 
    listeners: {}, 
    data: props.data || {},
  }) )

  const { listeners } = state
  const getListenerGroup = ( { path, type } ) => {

  }

  const attachListener = ( { path = '*', type = '*', delay = 0 }, callback ) => {
    const key = `${type}::${path}`


    const delayList = listeners[key] = listeners[key] || {}
    const delayOb = delayList[delay] = delayList[delay] || { delay, list: [], timer: null, event: null }
    const { list } = delayOb

    list.push( callback )

    const remove = () => {
      const ind = list.indexOf( callback )
      if ( ind != -1 )
        list.splice( ind, 1 )
    }

    return remove
  }

  const emitEvent = ( event ) => {
    const { listeners } = state

    // console.log( 'emit', event, listeners )

    const { path, type } = event
    const keys = ['*::*']
    if ( type && type != '*' ) keys.push( `${type}::*`)
    if ( path && path != '*' ) keys.push( `*::${path}`)
    if ( type && path && type != '*' && path != '*' ) keys.push( `${type}::${path}`)

    for ( let key of keys ) {
      const delayList = listeners[key]

      if ( delayList ) for ( let delay in delayList ) {
        const delayOb = delayList[delay]
        const { list, timer } = delayOb
        if ( !delay ) {
          for ( let listener of list ) {
            listener( event )
          }
        } else {
          delayOb.event = event
          if ( !timer ) {
            delayOb.timer = setTimeout(() => {
              const { event, list } = delayOb
              delayOb.timer = null
              delayOb.event = null
              for ( let listener of list ) {
                listener( event )
              }              
            }, delay )
          }
        }
      }
    }
  }

  const getData = ( path ) => {
    const segs = splitPath( path )
    let target = state.data
    for ( let seg of segs ) {
      target = target && target[seg]
    }
    return target
  }

  const mergeData = ( value, path ) => {
    const { data } = state
    const changes = []
    const newData = mergeDataWithEvents( data, value, path, changes )
    state.data = newData

    for( let change of changes ) {
      let [ path, value ] = change
      path = path.join('/') || '/'
      const event = {
        path,
        type: 'change',
        value,
      }
      emitEvent( event )
    }

  }

  const contextValue = {
    attachListener,
    emitEvent,
    getData,
    mergeData,
  }

  useEffect( () => {
    if ( 'undefined' != typeof data )
      mergeData( data )
  }, [ data ] )
  
  return (
    <SurfContext.Provider value={contextValue}>
      { children }
    </SurfContext.Provider>
  )
}

export function useSurfContext() {
  return useContext( SurfContext )
}

export function useSurfControl( { path, transform, event = 'change', delay = 1 } ) {

  const context = useSurfContext()
  const { attachListener, emitEvent, mergeData, getData } = context

  const [ state, setState ] = useState( () => {
    const { getData } = context
    const value = getData( path )
    let result = value
    if ( transform ) {
      result = transform( result )
    }
    return result || {}
  }  )

  const onListener = ( event ) => {
    let result = event.value 
    if ( transform ) {
      result = transform( result )
    }

    if ( result ) {
      setState( result )
    }
  }

  useEffect( () => attachListener( { path, delay, type: event }, onListener ) )

  return { state, emitEvent, mergeData, getData }
}

export function useSurfListener( opt, callback ) {

  const context = useSurfContext()
  const { attachListener, emitEvent, mergeData, getData } = context
  const [ state, setState ] = useState( {} )


  useEffect( () => attachListener( opt, callback ) )

  return { state, context, emitEvent, mergeData, getData }
}