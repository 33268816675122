.select select {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: none;
  font-size: 0;
  position: absolute;
  inset: 0;
  opacity: 0;
}