import { Button } from "@chakra-ui/react";
import { useState } from "react";

export function UIButton( { ...props } ) {

  return <Button {...props}/>
}


export function UIActionButton( { onAction, ...props } ) {
  const [ isLoading, setLoading ] = useState( false )

  const onClick = async ( event ) => {
    setLoading( true )
    await onAction( event )
    setLoading( false )
  }

  return <Button {...{isLoading, onClick,}} {...props}/>
}

