import boxStyles from './box.module.css'
import { useBoundsTranslateCartesian } from './pointer'
import { useComponentShading } from './shades'
import { ElementsBoxes, computeElementsForPoints } from './shared/elements'

export function ControlBox( props ) {
  const {
    events,
    elements,
    gutter,
    className,
    style,
    children,
  } = props

  const { shadingToStyle } = useComponentShading( props )

  const { bounds, coordFromEvent, eventIsInside, boundsRef } = useBoundsTranslateCartesian( { gutter } ) 
  let elementsComputed = computeElementsForPoints( { elements, gutter, bounds, status } )

  return <div className={`box ${boxStyles.box} ${className}`} style={style} {...events} ref={ boundsRef }>
    <ElementsBoxes  {...props} elements={elementsComputed} {...{shadingToStyle, bounds}}/>
    { children }
  </div>
}