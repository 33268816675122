import Slider from "./slider"
import { resolveAxes } from "../shared/axes"
import { useVectorValue } from "../vector"
export function Grid( props ) {
  const {
    value,
    onValue,
    axes:axesArgs = 'right',
    count = 3,
    ...remain
  } = props
  const axes = resolveAxes( axesArgs )

  console.log( "grid axes", axes )
  const stride = axes.length

  const { getVector, setVector } = useVectorValue( { value, onValue, axes })



  const items = Array.from( { length: count }, ( _, index ) => {
    const cellValue = getVector(index * stride, stride )
    return <Slider 
      key={index} value={cellValue} onValue={(value)=>setVector(value,index * stride )} slide='on' 
      {...remain} axes={axes}
    />
  })

  const gridStyle = {
    display:'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr '
  }

  return <div style={gridStyle}>
    { items }
  </div>
}