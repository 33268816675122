export interface SizesProps {
  gutter?:number,
  track?:number,
  point?:number,
  round?:number,
}

export enum TriggerMode {
  None = 'none',
  // Button modes
  Down = 'down',
  Up = 'up',

  // Input modes
  Change = 'change',
  Submit = 'submit',
  Blur = 'blur',
}
