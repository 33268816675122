import { Children, cloneElement, isValidElement } from "react"
import Button from "./button.js"
import { isArray, isObject } from "../util.js"
import { floor, isRealNumber } from "../shared/math.js"
import { resolveOptions } from './options.js'

import groupStyles from './group.module.css'


export function OptionsList( props ) {
  const {
    options,
    ...remain
  } = props

  const optionsResolved = resolveOptions( options )

  return optionsResolved.map( ( option, key ) => (<Button key={key} {...remain} {...option}/> ) )
}


enum GroupLayout {
  Row = 'row',
  Column = 'column',
  Stack = 'stack',
  Inline = 'inline',
}


function resolveGrid( props, count ) {
  let { 
    rows = 0, 
    cols = 0,
    layout = GroupLayout.Inline,
  } = props


  if ( props.count ) {
    count = props.count
  }



  if ( !rows && !cols ) {
    switch( layout ) {
      default:
      case 'row':
        cols = count
        rows = 1
      break

      case 'column':
        rows = count
        cols = 1
      break
    }

  } else if ( !rows ) {
    rows = floor( count / cols )
  } else if ( !cols ) {
    cols = floor( count / rows )
  }

  count = rows * cols

  return { count, rows, cols, layout }
}

function itemAttachment( index, groupAttach, cols, rows ) {

  const row = cols && floor( index / cols )
  const col = index - row * cols

  let dirs = []

  if ( groupAttach != false ) {
    if ( rows > 1 && row >= 1 ) {
      dirs.push( 'top' )
    }
    if ( rows > 1 && row < rows-1 ) {
      dirs.push( 'bottom' )
    } 

    if ( cols > 1 && col >= 1 ) {
      dirs.push( 'left' )
    }
    if ( cols > 1 && col < cols-1 ) {
      dirs.push( 'right' )
    } 
  }



  return dirs.join('_')
}

type GroupProps = {
  children?: React.ReactNode
  options?: any
  count?: number
  rows?: number
  cols?: number
  layout?: 'row' | 'column' | 'stack' | 'inline'
  attach?: boolean
}

function layoutToClassname( layout ) {
  switch ( layout ) {

    case 'row':
      return groupStyles.groupRow
    case 'stack':
      return groupStyles.groupStack

    default:
    case 'inline':
      return groupStyles.groupInline

    case 'column':
      return groupStyles.groupColumn
  }
}

export function Group( props : GroupProps ) {
  const {
    children,
    attach,
    options:optionsProps,
    ...remain
  } = props

  const options = resolveOptions( optionsProps )
  const childCount = Children.count( children )
  const optionsCount = options.length

  const { count, cols, rows, layout } = resolveGrid( props, optionsCount + childCount  )


  const layoutClassname = layoutToClassname( layout )


  const childProps = ( index ) => {
    const props = { ...remain }
    props.attach = itemAttachment( index, attach, cols, rows )
    return props
  }

  const revisedChildren = []
  
  Children.forEach( children, ( child, key ) => {
    revisedChildren[key] = isValidElement( child ) ? cloneElement( child, { ...childProps( key ), ...child.props, key } ) : child
  })

  for ( let optionIndex = 0; optionIndex < optionsCount; optionIndex++ ) {
    const option = options[optionIndex]
    const key = childCount + optionIndex 
    revisedChildren[ key ] = <Button key={key} {...remain} attach={itemAttachment( key, attach, cols, rows )} {...option}/>
  }

  if ( layout == GroupLayout.Inline )
    return revisedChildren

  return <div className={`box group ${groupStyles.group} ${layoutClassname}`}>
    { revisedChildren }
  </div>

}