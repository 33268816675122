.box {
  display: inline-block;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  padding: 0;
}

.box::after {
  content: "";
  display: table;
  clear: both;
}


.box textarea {
  color: inherit;
  font-size: inherit;
  font-family: 'Source Code Pro', monospace;
  border: none;
  outline: none;
  /* width: 100%;
  height: 100%; */
  /* padding: 1rem; */
  background: none;
}