import { useState } from "react"
import Option from './option.jsx'
import { Row } from "./layout.jsx"

export function Page( { ...remain } ) {
  
}



export function PageSelect( { selected } ) {

}

export function Tabs( { labels = [], selected = 0, children } ) {
  const [ selectedIndex, setSelectedIndex ] = useState( selected )
  return <>
    <Row>
      { labels.map( ( text, index ) => {
        return <Option 
          key={index} 
          {...{text}} 
          selected={selectedIndex == index} 
          onTrigger={ () => setSelectedIndex( index )}
        />
      })}
    </Row>
    { labels.map( ( text, index ) => index == selectedIndex ? children && children[index] : undefined ) }
  </>
}