import { firestore } from '../firebase.js'
import { doc, addDoc, collection, query, where, limit, orderBy } from 'firebase/firestore'
import { useEffect } from 'react'
import { useCollectionOnce } from 'react-firebase-hooks/firestore'


export default function MediaPage( {...remain } ) {
  const mediaQuery = query( 
    collection( firestore, 'media' ), 
    limit(20) 
  )

  useEffect( () => {
    console.log('********** SAVING')
    addDoc( collection( firestore, 'foo' ), { bar:'baz'} )
    .then( ( value ) => {
      console.log('value',value)
    })
    .catch( ( error ) => {
      console.error( error )
    })
  })

  const [result, loading, error] = useCollectionOnce(
    mediaQuery,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  return <span>
    {error && <strong>Error: {JSON.stringify(error)}</strong>}
    {loading && <span>Collection: Loading...</span>}
    { result && result.docs.map( ( doc ) => 
      
      <pre>D::{ JSON.stringify( doc.data(), null, 2 ) }</pre>
    )}
  
  </span>
}