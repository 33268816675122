import { Control, ControlContent, ControlInner } from "../component/control.jsx"
import { TriggerListeners } from "../component/triggerlisteners.jsx"
import { useSurfControl } from "../context.jsx"
import { useEffect, useState } from "react"
import { isUndefined } from "../util.js"

export default function Option( { path, value, text, icon, iconSelected, selected, onTrigger, ...remain } ) {
  
  const transform = ( dataValue ) => ({ selected: dataValue == value })
  const { state, emitEvent, mergeData } = useSurfControl( { path, transform } )
  selected = isUndefined( selected ) ? state.selected : selected

  const contentProps = { ...remain }

  if ( text ) {
    contentProps.text = text
  } else  if ( 'string' == typeof value ) {
    contentProps.text = value
  }

  contentProps.icon = ( selected && iconSelected ) || icon 
  contentProps.selected = selected

  const onSelfTrigger = ( event ) => {
    if ( path ) 
      mergeData( value, path )

    onTrigger && onTrigger( event )
  }

  return (<Control {...remain}>
    <TriggerListeners onTrigger={onSelfTrigger} >
      <ControlContent {...contentProps}></ControlContent>
    </TriggerListeners>
  </Control>)
}

export function Options( { options, ...remain } ) {
  const list = []
  for ( let key in options ) {
    const text = Array.isArray( options ) ? String( options[key] ) : key
    const value = options[key]
    list.push( [ key, text, value ] )
  }

  return <>
    { list.map( ( [ key, text, value ] ) => <Option key={key} {...remain} value={ value } text={ text }/> ) }
  </>
}