export const adverbs = [
  "whimsically", "effortlessly", "furtively", "obnoxiously", "stridently", "famously", "garrulously", "inquisitively", "obliviously", "pensively", "quixotically", "caustically", "thoroughly", "fascinatingly", "tenaciously", "intrepidly", "furtively", "blasphemously", "adamantly", "defiantly", "flippantly", "furtively", "jovially", "inscrutably", "insipidly", "insidiously", "intuitively", "irritably", "jestingly", "jubilantly", "lasciviously", "lugubriously", "mellifluously", "minutely", "morosely", "nefariously", "nonchalantly", "nostalgically", "overtly", "pensively", "perfunctorily", "precariously", "prosaically", "querulously", "quizzically", "rakishly", "ravenously", "reflexively", "reproachfully", "reverently", "sanctimoniously", "sardonically", "seductively", "serendipitously", "solemnly", "somberly", "steadfastly", "stridently", "superciliously", "swaggeringly", "taciturnly", "tenuously", "timorously", "trenchantly", "unabashedly", "unconsciously", "unequivocally", "unwittingly", "vehemently", "vexatiously", "vivaciously", "voraciously", "wanton", "wistfully", "yearningly", "youthfully", "zestfully", "zealously", "zestily", "zippily", "zestily", "zoologically"
]

export const adjectives = [
  "wonky", "spunky", "fluffy", "zany", "quirky", "boisterous", "pungent", "funky", "wiggly", "bubbly", "jazzy", "cheeky", "jolly", "groggy", "goofy", "silly", "slippery", "snazzy", "smelly", "sneaky", "snoozy", "silly", "snappy", "squirmy", "swanky", "tacky", "twisty", "wacky", "whimsical", "wiggy", "wild", "zesty", "zoomy", "funny", "bizarre", "crazy", "dorky", "eccentric", "flamboyant", "hilarious", "kooky", "lively", "ludicrous", "madcap", "mirthful", "outlandish", "ridiculous", "surreal", "wacky", "weird", "witty"
]

export const animals = [
  "kinkajou", "quokka", "numbat", "axolotl", "platypus", "wombat", "pangolin", "hoopoe", "okapi", "binturong", "potoo", "coati", "blobfish", "shoebill", "serval", "lemming", "iguana", "gibbon", "sloth", "narwhal", "aardvark", "bongo", "tapir", "echidna", "goosefish", "quetzal", "gazelle", "kangaroo", "mandrill", "wildebeest", "marmoset", "kouprey", "meerkat", "gnu", "bonobo", "cassowary", "platybelodon", "puma", "tarsier", "kiwi", "barracuda", "toucan", "pangolin", "armadillo", "gnu", "gopher", "giraffe", "elk", "vicuna", "caribou", "rhinoceros", "lynx", "penguin", "spider monkey", "anteater", "anteater", "capybara", "iguana", "hyena", "wallaby", "chinchilla", "bison", "alpaca", "wombat", "tapir", "dodo", "puma", "beaver", "seahorse", "quokka", "possum", "cormorant", "opossum", "mink", "mule", "potoo", "stinkbug", "lemur", "mandrill", "skunk", "porcupine"
]

const { random, floor } = Math
const pick = list => list[floor( list.length * random() )]

export const make_slug_name = () => [ pick( adverbs ), pick( adjectives ), pick( animals ) ].join('_')