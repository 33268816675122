import { createContext, useContext } from "react"
import { isArray, isObject, isString } from "./util"
import { presetsDefaults } from "./presetDefaults"

export const PresetsContext = createContext( presetsDefaults )

type ControlPresetProps = {
  preset?: string | string[],
  presets?: {
    [key:string]: object
  }
}


type PresetType = string | object
type PresetGroupType = PresetType | PresetType[] | { [key:string]: PresetType }

function usePresetsContext() {
  return useContext( PresetsContext ) || presetsDefaults
}


export function useTrancerPresets( propsArg:ControlPresetProps, control:string, defaults:{ [key:string]: any } ) {

  const presets = usePresetsContext()

  let props = null
  if ( isObject( defaults) ) {
    applyPreset( defaults )
  }

  function applyPreset( preset:PresetType ) {
    if ( isString( preset ) ) {
      preset = presets[preset]
    }

    if ( isArray( preset ) ) {
      for ( let p of preset ) {
        applyPreset( p )
      }
      return
    }

    if ( isObject( preset ) ) {
      props = props || {}
      props = { ...preset, ...props }
    }
  }

  applyPreset( "control" )
  applyPreset( control )
  applyPreset( propsArg.preset )


  return props ? { ...props, ...propsArg } : propsArg
}


export function TrancerPresets( props: any ) {
  const defaultPresets = usePresetsContext()
  console.log( "defaultPresets", defaultPresets )
  let { presets } = props
  presets = presets || {}
  if ( defaultPresets ) {
    presets = { ...defaultPresets, ...presets }
  }

  const { children } = props
  return <PresetsContext.Provider value={presets}>
    { children }
  </PresetsContext.Provider>
}