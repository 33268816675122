
import { stringify } from "yaml"
import { ControlContent, propsToContent } from "../content"
import { ControlBox } from "../box"
import { useTrancerPresets } from "../presets"
import { useComponentShading } from "../shades"
import { elementsDefault, useParsedElements } from "../shared/elements"
import { useSizedControl } from "../shared/sizes"



export function Display( propsArg ) {
  const props = useTrancerPresets( propsArg, 'display', {
    elements: elementsDefault,
  } )
  const { value } = props
  const { shadingToStyle } = useComponentShading( props )
  const { elements, gutter } = useParsedElements( props )
  const status = {
    over: false,
    active: false,
    invert: false,
  }

  const contentProps = propsToContent( props, value )
  const { size } = contentProps
  const content = <ControlContent {...contentProps}/>
  const { sizeStyles, sizeClassname } = useSizedControl( props, contentProps.size )

  return <ControlBox
    {...props}
    size={ size }
    style={ sizeStyles }
    className={`display ${sizeClassname}`}
    gutter={ gutter }
    elements={ elements }
    content = { content }
    status = { status }
  >

  </ControlBox>

}