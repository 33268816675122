
const { min, max, sqrt } = Math

function weighedDist( A, B, weight ) {
  const count = min( A.length, B.length, weight.length ) 
  let sum = 0
  for ( let i = 0; i < count; i ++ ) {
    let acc = A[i] - B[i]
    acc *= acc
    acc *= weight[i]
    sum += acc || 0
  }
  return sqrt( sum )
}

function lerpVert( A, B, mux ) {
  return A.map( ( aVal, ind ) => aVal + (B[ind]-aVal) * mux )
}

export function compressLine( line, length = 16, weight = [ 1,1 ] ) {
  let span = 0
  let prev
  let steps = []

  for ( let index in line ) {
    const vert = line[index]
    if ( !vert ) continue
    if ( prev ) {
      let dist = weighedDist( prev, vert, weight )
      steps[index-1] = dist
      span += dist
    }
    prev = vert
  }

  const result = []
  let lx = 0
  let lind = 0
  for ( let index = 0; index < length; index ++ ) {
    const wx = index * span / (length-1)
    do {
      if ( lind >= line.length - 1 ) break
      const step = steps[lind]
      if ( wx <= lx + step ) break
      lx += step
      lind++
    } while ( line[lind] )

    const step = steps[lind]
    if ( lind < line.length - 1 ) {
      result[index] = lerpVert( line[lind], line[lind+1], (wx-lx)/step )
    } else {
      result[index] = lerpVert( line[lind], line[lind], 0 )
    }
  }

  return result
}

export function lineNormalizeField( line, field, write = field ) {
  let prev
  let bot 
  let top
  for ( let index in line ) {
    const vert = line[index]
    if ( !prev ) {
      top = bot = vert[field]
    } else {
      top = max( vert[field], top )
      bot = min( vert[field], bot )
    }
    prev = vert
  }

  return line.map( vert => {
    vert = [...vert]
    vert[write] = ( vert[field] - bot ) / ( top - bot )
    return vert
  })
}

export function fadeLine( line ) {
  return line.map( vert => {
    vert = [...vert]
    vert[2] *= 15/16
    return vert 
  } )
}

export function measureLine( line ) {
  const bot = []
  const top = []
  const { length } = line
  for ( let index = 0; index < length; index ++ ) {
    const vert = line[index]
    for ( let k = 0; k < vert.length; k ++ ) {
      if ( index == 0 ) {
        bot[k] = top[k] = vert[k]
      } else {
        bot[k] = min( bot[k], vert[k] )
        top[k] = max( top[k], vert[k] )
      }
    }
  }

  return [ bot, top ]
}

