import { isDefined, triggerMatches } from "../util"
import { TriggerMode } from "../types"
import { useComponentShading } from "../shades"
import { computeElementsForPoints, elementsDefault, useParsedElements } from "../shared/elements"

import { useBoundsTranslateCartesian, usePointerOverStatus } from '../pointer'
import { ElementsBoxes } from '../shared/elements'

import { propsToContent, ControlContent } from "../content"
import styles from "../styleBits.module.css"
import { useTrancerPresets } from "../presets"
import { ControlBox } from "../box"
import { useSizedControl } from "../shared/sizes"



type useTriggerListenersProps = {
  trigger?:TriggerMode
}

function useTriggerListeners( props:useTriggerListenersProps, callback ) {
  const {
    trigger,
  } = props

  console.log("useTriggerListeners", trigger)

  const onEvent = ( event ) => {
    console.log( 'onEvent', event )
    callback()
  }

  const events = {}

  switch( trigger ) {
    case 'down':
      events.onMouseDown = onEvent
      break
    case 'up':
      events.onMouseUp = onEvent
      break
  }


  return { pointerEvents: events }
}

export default function Button( propsArg ) {
  const props = useTrancerPresets( propsArg, 'button', {
    trigger: "down",
    invert: false,
    matchInvert: true,
    invertFalse: true,
    gutter: 2,
    elements: elementsDefault,
  } )

  const {
    value,
    onValue,
    onClick,
    triggerValue,
    triggerFunction,
    matchInvert = true,
    invertFalse = true,
  } = props


  const { elements, gutter } = useParsedElements( props )
  const { over, overEvents } = usePointerOverStatus()

  let { invert } = props
  let active = true
  invert = !!invert

  if ( matchInvert )  {
    if ( triggerMatches( value, triggerValue ) ) 
      invert = !invert
  } else if ( invertFalse && !value )  {
    invert = !invert
  }

  const triggerValueActual = () => {
    let val = triggerFunction ? triggerFunction( value ) : triggerValue
    console.log( 'triggerValueActual', triggerValue, props )

    if ( isDefined( val ) && onValue )
      onValue( val )
  }

  const onTrigger = ( event ) => {
    triggerValueActual()
    onClick && onClick( event )
  }

  const contentProps = propsToContent( props, isDefined( triggerValue ) ? triggerValue : value )
  const { size } = contentProps
  const { pointerEvents } = useTriggerListeners( props, onTrigger )
  const content = <ControlContent {...contentProps}/>
  let status = {
    over,
    active,
    invert,
  }

  console.log("button contentProps", contentProps )

  const { sizeStyles, sizeClassname } = useSizedControl( props, contentProps.size )

  return <ControlBox
    {...props}
    {...{gutter,elements,content,status,size}}
    className={`button ${styles.button} ${sizeClassname}`}
    style={sizeStyles}
    events = { pointerEvents }
  />


}