import useMeasure from 'react-use-measure'
import { useState } from 'react'
import { Input, Button, MutantValue, Select } from 'trancer'
// const Input = () => null
import { HStack } from '@chakra-ui/react'
import { Group } from 'trancer'

import { RiFileDownloadFill, RiFileDownloadLine, RiFullscreenFill, RiZoomInLine, RiZoomOutLine } from 'react-icons/ri'
import { PiFullscreenFill, PiMagnifyingGlassPlus, PiMagnifyingGlassMinus } from 'react-icons/pi'
import { useProjectStore } from '../lib/store'

const { floor, round, min, max } = Math

function useViewportLogic( ) {

  const [ measureRef, containerBounds ] = useMeasure( { scroll: false } )

  const { width:contWidth, height:contHeight } = containerBounds
  const contAspect = contWidth / contHeight

  const [ state, setRealState ] = useState( () => ({
    aspect: 0,
    width: 0,
    height: 0,
    scale: 0,
    x: 0,
    y: 0,
  }))

  function setState( value ) {
    console.log('set state', value)
    setRealState( value )
  }

  let { aspect, width, height, x, y, scale } = state

  let derivAspect = ''
  let derivScale = ''
  let derivDim = ''
  const derivChar = `~`


  if ( !width && !height ) {
    derivDim = derivChar

    if ( false && aspect ) {
      if ( contAspect > aspect ) {
        width = contWidth
        height = round( contWidth / aspect )
      } else {
        width = round( contHeight *  aspect )
        height = contHeight
      }
    } else {
      aspect = contAspect
      derivAspect = derivChar
      width = contWidth
      height = contHeight
    }
  } else if ( width && height ) {
    derivAspect = derivChar
    aspect = width / height
  }

  if ( !scale ) {
    derivScale = derivChar
    if ( true ) {
      scale = min( contWidth / width, contHeight / height ) || 0
    }
  }

  const tx = contWidth / 2 + x - width * scale / 2
  const ty = contHeight / 2 + y - height * scale / 2
  const transform = `translate( ${tx}px, ${ty}px ) scale(${scale})`

  width = round( width )
  height = round( height )

  const contentStyle = {
    background:'transparent',
    width: `${width}px`,
    height: `${height}px`,
    top: '0',
    left: '0',
    position: 'absolute',
    transformOrigin: 'top left',
    transform,

  }

  const contentProps = {
    style: contentStyle,
    "data-realsize": `${width}x${height}`,
  }

  const settings = {
    aspect: `${derivAspect}${aspect}`,
    scale: `${derivScale}${scale*100}%`,
    dimension: `${derivDim}${width}x${height}`
  }

  const setSetting = ( key, value ) => {

    value = value || ''
    value = value.split( /[x\/,]/ )
    value.map( parseNumber )

    function parseNumber( value ) {
      while ( value[0] == '~' )
        value = value.substring( 1 )

      value = max( 0, parseFloat( value ) || 0 )
      return value
    }  

    const [x,y] = value
    
    switch( key ) {
      case 'aspect':
        let aspect
        if ( x ) {
          if ( y ) {
            aspect = x / y
          } else {
            aspect = x
          }
        } else if ( y ) {
          aspect = 1/y
        }
        if ( aspect )
          setState( { ...state, aspect } )
      break

      case 'dimension':
        let width = x || 0
        let height = y || 0
        setState( { ...state, aspect: 0, width, height } )
      break

      case 'scale':
        let scale = x / 100
        setState( { ...state, scale } )
      break

    }
  }



  return {
    measureRef,
    contentProps,
    settings,
    setSetting,
  }
}

function ViewportMenu( { settings, setSetting } ) {

  const inputProps = {
    // size: 'sm',
    colour: 'rgba(20,20,20,0.8)',
  }

  const actionCaptureViewport = useProjectStore( state => state.actionCaptureViewport ) 
  const dimensionOptions = [
    "",
    '1024x1024',
    '1920x1080',
    '1920x1920',
    '1080x1920',
    '3840x2160',
  ]


  return <div style={{
    position: 'absolute',
    // width: '400px',
    height: '30px',
    zIndex: '10',
  }}>
    <Group spacing={0} layout="row">
      {/* <Input label="Aspect Ratio" value={settings.aspect} onValue={( value ) => setSetting('aspect', value )}/> */}
      <Button {...inputProps} label="Zoom 100%" triggerValue={0} size={10} onValue={(value)=> { setSetting('scale',"100%")}} showValue={null}/>
      <Button {...inputProps} label="Zoom to view" triggerValue={0} size={10} onValue={(value)=> { setSetting('scale',"0")}} showValue={null}/>
      <Button attach="left" {...inputProps} icon={<PiMagnifyingGlassPlus/>} label="Zoom In" value={settings.scale} size={8} triggerFunction={(value)=>{
        if ( value[0] == '~' )
          value = value.substring( 1 )

        value = parseFloat( value )
        console.log( 'zoom in', value)

        value *= 2
        return String(value)
      }} onValue={(value)=> { setSetting('scale',value)}} showValue={null}/>
      <Button {...inputProps} icon={<RiZoomOutLine/>} label="Zoom Out" value={settings.scale} size={8} triggerFunction={(value)=>{
        if ( value[0] == '~' )
          value = value.substring( 1 )
        value = parseFloat( value )
        value /= 2
        return String(value)
      }} onValue={(value)=> { setSetting('scale',value)}} showValue={null}/>

      {/* <Input {...inputProps} label="Zoom" inputSize={6} value={settings.scale} onValue={( value ) => setSetting('scale', value )}/> */}
      {/* <Input {...inputProps} label="Dimensions" value={settings.dimension} onValue={( value ) => setSetting('dimension', value )}/> */}
      <Select {...inputProps} options={dimensionOptions} onValue={(value)=> { setSetting('dimension',value) }} label="Size" seperator=":"/>
      <Button {...inputProps} icon={<RiFileDownloadLine/>} size={10} onClick={ ()=> actionCaptureViewport() } label="Save to PNG"/>
    </Group>
  </div>
}




export function Viewport( {
  children,
  background = false,
  showMenu = false,
} ) {

  const {
    measureRef,
    contentProps,
    settings,
    setSetting,
  } = useViewportLogic()

  return <div ref={measureRef} className="viewport" style={{
    width:'100%', height:'100%', position: background ? 'fixed' : 'relative',
    zIndex: background ? -1 : 'inherit'
  }}>
    { showMenu && <ViewportMenu {...{settings,setSetting}}/> }
    <div className="viewport-content" {...contentProps}>
      { children }
    </div>
  </div>
}