import { useEffect, useState, useMemo } from "react"
import { isRealNumber, floor, distance, saturate } from "./math"
import { isArray } from "../util"
import { numberToNorm, normToNumber  } from "numerick"

const { stringify } = JSON


export function applyAxisValueToCoord( value:number, coord:number[], index:number, axis:AxisType, numeric?:NumerickConfig ) {
  const { vertical, numeric:axisNumeric } = axis

  if ( numeric || axisNumeric ) {
    const config = !numeric ? axisNumeric : !axisNumeric ? numeric : { ...numeric, ...axisNumeric }
    value = numberToNorm( value, config )
    if ( config.clamp !== false )
      value = saturate( value )
  }

  if ( !isRealNumber( value ) )  return

  // Mutates coord
  coord[index] = value
}

export function axisValueFromCoord( axis:AxisType, coord:number[] ) {
  const { reversed, vertical } = axis
  const index = vertical ? 1 : 0
  let value = coord[index]

  return value
}

export function negateCss( str:string ) {
  return str.startsWith('-') ? str.substring(1) : '-'+str
}



export const useSliderPoints = ( { axes, value:defaultValue, numeric, onValue, valueIsArray } )=> {

  const [ value, setValue ] = useSliderValues( defaultValue )

  const setPointCoord = ( pointId, coord ) => {
    const orig = value
    let next
    axes.map( ( axis, axisIndex ) => {
      const index = axisIndex + pointId * axes.length
      const { coordKey, reversed, numeric:axisNumeric  } = axis
      let value = coord[axisIndex]

      if ( numeric || axisNumeric ) {
        const config = !numeric ? axisNumeric : !axisNumeric ? numeric : { ...numeric, ...axisNumeric }
        value = normToNumber( value, config )
      }

      const current = ( next || orig )[index]
      if ( isRealNumber( value ) && value != current ) {
        next = next || [ ...orig ]
        next[index] = value
      }
    })

    if ( next ) {
      setValue( next )

      if ( valueIsArray === false || ( axes.length == 1 && !valueIsArray ) ) {
        next = next[0]
      }
      onValue && onValue( next )
    }
  }

  const pointCoords = useMemo( () => {
    const pointCount = floor( value.length / axes.length )
    return new Array( pointCount ).fill(0).map( ( nil, index ) => {
      const valueIndex = index * axes.length
      const coord = [NaN,NaN]
      axes.map( ( axis, index ) => applyAxisValueToCoord( value[valueIndex+index], coord, index, axis, numeric ) )
      return coord
    } ) 
  }, [ value, axes ] ) 


  return {
    value,
    pointCoords,
    setPointCoord,
  }
}


export const useSliderPointerEvents = ( {
  slide = 'off',
  coordFromEvent,
  eventIsInside,
  pointCoords,
  setPointCoord,
} ) => {


  let [ drags, setDrags ] = useState( [] )
  const closestPointIndex = ( coord ) => {
    let index = -1
    let best = Number.POSITIVE_INFINITY

    for ( let test = 0; test < pointCoords.length; test++ ) {
      let dist = distance( coord, pointCoords[test] )
      if ( dist < best ) {
        index = test
        best = dist
      }
    }

    return index
  }

  const [ windowEvents, setWindowEvents ] = useState( false )

  const onPointerEvent = ( event ) => {
    const { pointerId } = event
    const coord = coordFromEvent( event )
    let dragsUpdated

    var isPointerDown = false

    if (event.buttons !== undefined) {
      // For mouse events
      isPointerDown = event.buttons > 0
    } else if (event.touches !== undefined) {
      // For touch events
      isPointerDown = event.touches.length > 0
    }

    let startDrag
    let endDrag 

    switch( event.type ) {
      case 'pointerdown':
        startDrag = true
      break
      case 'pointerenter':
        if ( slide == 'on' && isPointerDown )
          startDrag = true
      break

      case 'pointerup':
        endDrag = true
      break
    }

    if ( slide != 'off' && !eventIsInside( event ) )
      endDrag = true

    if ( endDrag ) {
      for ( let dragIndex = drags.length - 1; dragIndex >= 0; dragIndex-- ) {
        const drag = drags[dragIndex]
        if ( drag.pointerId == pointerId ) {
          dragsUpdated = dragsUpdated || [ ...drags ]
          dragsUpdated.splice( dragIndex, 1 )
        } 
      }
    }

    if ( startDrag ) {
      const pointId = closestPointIndex( coord )
      if ( pointId >= 0 ) {
        const drag = {
          pointerId,
          pointId,
        }

        dragsUpdated = [ ...drags, drag ]
      }
    }

    if ( dragsUpdated ) {
      setDrags( dragsUpdated )
      drags = dragsUpdated
    }

    const nextWindowEvents = drags.length > 0
    if ( nextWindowEvents != windowEvents )
      setWindowEvents( nextWindowEvents )

    for ( let dragIndex = 0; dragIndex < drags.length; dragIndex++ ) {
      const drag = drags[dragIndex]
      if ( drag.pointerId == pointerId ) {
        const { pointId } = drag
        setPointCoord( pointId, coord )
      } 
    }    
  }

  useEffect( () => {
    if ( windowEvents ) {
      window.addEventListener('pointerup', onPointerEvent )
      window.addEventListener('pointermove', onPointerEvent )

      return () => {
        window.removeEventListener('pointerup', onPointerEvent )
        window.removeEventListener('pointermove', onPointerEvent )
      }
    }
  })

  return {
    drags,
    active: drags.length > 0,
    pointerEvents: { 
      onPointerMove: onPointerEvent,
      onPointerDown: onPointerEvent,
      onPointerUp: onPointerEvent,
      onPointerEnter: onPointerEvent,
    },
  }
}

export function useSliderValues( defaultValue : number | number[] ) {

  const defaultValueResolved = !isArray( defaultValue ) ? [ defaultValue ] : defaultValue
  const [ value, setValue ] = useState( defaultValueResolved )
  const defaultValueString = stringify( defaultValueResolved )
  useEffect( () => {
    if ( stringify( value ) != defaultValueString )
      setValue( defaultValueResolved )
  }, [ defaultValueString ])

  return [ value, setValue ]
}
