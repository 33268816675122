import useMeasure from "react-use-measure"
import { useRef } from "react"
import { mergeRefs } from "react-merge-refs"
import { normalizeEventValue1D } from "../util"
import { Control, ControlContent, ControlInner } from "./control.jsx"


export function PadListeners( { children, onPadEvent } ) {
  const [ boundsRef, bounds ] = useMeasure()

  const originRef = useRef()

  const onPointerEvent = ( event ) => {
    console.log( 'pointer', event )
    const origin = originRef.current
    const bounds = origin.getBoundingClientRect()
    const { width, height } = bounds
    const aspect = width / height
    let x = ( event.clientX - bounds.x ) / width * 2 - 1
    let y = ( event.clientY - bounds.y ) / height * 2 - 1
    
    if ( aspect > 1 )
      y /= aspect

    if ( aspect < 1 )
      x *= aspect

    event.padX = x
    event.padY = y

    // if ( event.pointerType == 'touch' ) {
      event.pressure = 0.5
    // }

    onPadEvent( event )
  }

  const listeners = { 
    onPointerDown: onPointerEvent,
    onPointerUp: onPointerEvent,
    onPointerMove: onPointerEvent,
  }

  return <ControlInner  {...listeners} ref={mergeRefs( [ boundsRef, originRef ] )}>
    { children }
  </ControlInner>
}


// export function normalizeEventValue1D( { origin, event, direction } ) {
//   const vertical = directionIsVertical( direction )
//   const clientKey = `client${vertical ? 'Y' : 'X'}`
//   const clientPos = event[clientKey]
//   const rect = origin.getBoundingClientRect()
//   const rectKey = vertical ? 'y' : 'x'
//   const sizeKey = vertical ? 'height' : 'width'
//   const result = (clientPos - rect[rectKey]) / rect[sizeKey]
//   return directionIsReversed( direction ) ? 1-result : result
// }

