import { Card, Editable, EditableInput, EditablePreview, Stack, ButtonGroup, FormControl, FormLabel, Checkbox, useClipboard, Flex, Input, Button } from "@chakra-ui/react"
import { UIActionButton, UIButton } from "./UIButton"
import { Region, RegionButton, RegionContent } from "./ui/Region"
import { useProjectStore } from "../lib/store"
import { useNavigate } from 'react-router-dom'
import { diceButtonIconProps, diceButtonProps } from "./Dice"
import { FaFolderOpen, FaFolderPlus } from "react-icons/fa"


function ProjectNames( {} ) {
  const { 
    projectId,
    projectData = {},
    actionSaveProjectAsNew,
    updateProjectData,
  } = useProjectStore()

  const { title = '', slug_name = '', public:isPublic } = projectData || {}

  return <>
    <Checkbox isChecked={isPublic} onChange={( e ) => updateProjectData( { public: !!e.target.checked })}>This project is public</Checkbox>
    <FormControl>
      <FormLabel>Project Name</FormLabel>
      <Editable defaultValue={title || slug_name} size="md" onSubmit={( title ) => updateProjectData( { title } )}>
        <EditablePreview />
        <EditableInput  />
      </Editable>
    </FormControl>
  </>
}

function ProjectLink( { linkType="view", projectId } ) {
  const link = `${window.location.origin}/${linkType}/${projectId}`
  const { value, onCopy, hasCopied } = useClipboard( link )
  return (
    <>
      <FormLabel>Project Link</FormLabel>
      <Flex mb={2}>
        <Input
          // placeholder={placeholder}
          value={value}
          isReadOnly
          mr={2}
        />
        <Button onClick={onCopy}>{hasCopied ? 'Copied!' : 'Copy'}</Button>
      </Flex>

    </>
  )
}

export function ProjectRegion( { } ) {
  const { 
    projectId,
    programState,
    actionSaveProjectAsNew,
  } = useProjectStore()

  const navigate = useNavigate()

  async function actionNewProject() {
    const pid = await actionSaveProjectAsNew()
    if ( pid ) 
      navigate( `/project/${pid}`)
  }

  const buttons = <>
    <RegionButton {...diceButtonProps( { state: programState } ) } {...diceButtonIconProps()}/>
  </>

  return <Region label={"Project"} {...{buttons}}>
    <RegionContent>
      <Stack>
        <ButtonGroup isAttached>
        <UIButton onClick={()=> {
          navigate('/projects')
        }} leftIcon={<FaFolderOpen/>}>Load Project</UIButton>
        { <>
          <UIActionButton onAction={actionNewProject} leftIcon={<FaFolderPlus/>}>New Project</UIActionButton>
        </>}
        </ButtonGroup>
        { projectId && <>
          {/* <UIActionButton onAction={actionSaveProjectAsNew}>Clone Project</UIActionButton> */}
          <ProjectNames/>
          <ProjectLink {...{projectId}}/>
        </> }
      </Stack>
    </RegionContent>
  </Region>
}