import { useEffect, useRef, useState } from "react"
import { useTrancerPresets } from "../presets"
import { ControlBox } from "../box"
import { TriggerMode } from "../types"
import { useInputListeners } from "../form"

export default function Code( propsArg ) {
  const props = useTrancerPresets( propsArg, 'code', {
    language: 'yaml',
    readonly: true,
    trigger: TriggerMode.Change,
  } )

  const {
    trigger,
    value,
    onValue,
    language,
    readonly,
  } = props

  const [ source, setSource ] = useState( value )
  const contentRef = useRef()

  const onInputValue = ( value ) => {
    onValue && onValue( value )
    setSource( value )
  }

  const onInputStatus = ( status ) => {

  }

  const { onSubmit, onChange, onFocus, onBlur } = useInputListeners( { trigger, onInputStatus, onInputValue } )

  const content = <textarea {...{ onChange, onFocus, onBlur } } ref={contentRef}/>

  useEffect( () => {
    const { current } = contentRef
    if ( current ) {
      current.value = value
    }
  }, [ value ] )

  return

  return <>
    <ControlBox {...props}>
      { content }
    </ControlBox>
  </>
}