.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
  gap: 0.5rem;
}

.label {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 1rem;
}

.serial {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin: 0.5em;

}