import { ControlBounds, ControlLabelStyle } from "../component/control.jsx"
import { styled } from "../styled.js"


export const ColStyled = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'justify',
  placeItems: 'stretch',
  // width: '100%',
  height: '100%',
  flexGrow: 1,
})

export const ColBounds = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  placeItems: 'stretch',
  // width: '100vw',
  height: '100%',
  flexGrow: 1,
})


export function Col( { label, children, color, ...remain } ) {
  return <ColBounds>
    { label && <ControlLabelStyle color={color}>{ label }</ControlLabelStyle> }
    <ColStyled {...remain}>
      { children }
    </ColStyled>
  </ColBounds>
}

export const RowStyled = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'justify',
  placeItems: 'stretch',
  width: '100%',
  flexGrow: 1,
})

export const RowBounds = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'justify',
  placeItems: 'stretch',
  width: '100%',
  flexGrow: 1,
})


export function Row( { label, children, color, style, ...remain } ) {
  return <RowBounds {...{style}}>
    { label && <ControlLabelStyle color={color}>{ label }</ControlLabelStyle> }
    <RowStyled {...remain}>
      { children }
    </RowStyled>
  </RowBounds>
}
