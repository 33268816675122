import { useProjectStore } from "../lib/store"
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect } from "react"
import programs from 'g8blockparty/dist/common_js/programs.js'

import { FilterControls } from "../components/FilterControls.jsx"
import { UIAppMain } from "../components/UIAppMain"
import { StatefulProgram } from "../components/StatefulProgram"
import { ProjectRegion } from "../components/ProjectRegion"

const mainViewStyle = {
  bg: 'black.700',
}

export default function ViewProgramPage() {
  const loadProgram = useProjectStore( state => state.loadProgram )
  const state = useProjectStore( state => state.programState )
  const actionSaveProjectAsNew = useProjectStore( state => state.actionSaveProjectAsNew )
  const navigate = useNavigate()
  const { program_name } = useParams()

  useEffect( () => {
    if ( programs[program_name] ) {
      loadProgram( { program_name } )
    }
  }, [program_name])

  const display = <StatefulProgram/>
  const controls = <>
    <ProjectRegion/>
    <FilterControls state={state}/>
  </>
  return <UIAppMain {...{display,controls}}></UIAppMain>
}