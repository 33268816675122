import { Control, ControlContent, ControlInner } from "../component/control.jsx"
import { PadListeners } from "../component/padlisteners.jsx"
import { useSurfControl } from "../context.jsx"
import ColourState from 'deepcolour'
import { useEffect, useState } from "react"

export function OptionColour( { path, value, text, icon, iconSelected, ...remain } ) {
  const [ colour ] = useState( () => new ColourState() )

  colour.set( value )

  const transform = ( dataValue ) => ({ selected: colour.toString() == (new ColourState( dataValue).toString())})
  const { state, emitEvent, mergeData } = useSurfControl( { path, transform } )
  const { selected } = state
  const contentProps = { ...remain }

  contentProps.background = colour.css 
  contentProps.icon = ( selected && iconSelected ) || icon 

  const onPadEvent = ( pointer ) => {
    const { type } = pointer
    switch( type ) {
      case 'pointerdown':
        let eventValue = value
        const event = {
          path,
          type: 'trigger',
          value: eventValue,
        }
        mergeData( value, path )
      break
    }
  }



  return (<Control {...remain}>
    <PadListeners onPadEvent={onPadEvent}>
      <ControlContent {...contentProps}></ControlContent>
    </PadListeners>
  </Control>)
}