const ShadingParsed = Symbol('ShadingParsed')
import Colour from 'deepcolour'

type ComponentShadingProps = {
  shading?:any,
  color?:string,
  colour?:string,
}



export function useComponentShading( props : ComponentShadingProps ) {
  let { shading } = props


  let colour = new Colour('#888')

  if ( shading ) {
    shading = parseShading( shading )
    colour.set( shading.color )
  }

  colour.set( props.color )
  colour.set( props.colour )


  const computeShadingSVG = ( gradientId, value ) => computeShadingForSVG( colour, shading, gradientId, value )
  const shadingToStyle = ( props ) => computeShadingToStyle( colour, shading, props )

  return {
    shadingToStyle,
    computeShadingSVG,
  }

}

function parseShading( shading = {} ) {
  if ( shading[ShadingParsed] ) {
    return shading
  }

  function parseSide( side, invert = false ) {
    side = side || {}
    let item = { ...shading, ...side }
    let offset = shading.offset || { lightness: 1 }
    if ( invert && isUndefined( side.offset ) ) {
      offset = invertOffset( offset )
    } 

    let {
      colour = null,
      pow = 1,
    } = item

    return {
      colour,
      offset,
      pow,
    }
  }

  let pos = parseSide( shading.pos, false )
  let neg = parseSide( shading.neg, true )

  return {
    [ShadingParsed]: true,
    pos,
    neg,
  }
}

import { isDefined, isNumber, isObject, isString, isUndefined } from './util'
import { saturate } from './shared/math'

export function computeColourOffsets( colour, offsets, gain ) {
  for ( let key in offsets ) {
    let offset = offsets[key]
    if ( isNumber( offset ) ) {
      let value = colour.getChannel( key )
      value += offset * gain
      value = saturate( value )
      colour.setChannel( key, value )
    }
  }
  colour.setCh
}

function computeShadingForSVG( baseColour, shading, gradientId,  value ) {

  if ( isUndefined( value ) ) 
    return {
      color: 'none',
      gradient: null,
    } 

  let gradient = null
  let color = new Colour( baseColour )

  let offsets = {
    value: -0.1
  }

  if ( isNumber( value ) ) {
    computeColourOffsets( color, offsets, value )
  }

  if ( isString( value ) ) 
    value = { color: value }

  if ( isObject( value ) ) {
    gradient = gradient || value.gradient
    if ( value.color ) {
      color.set( value.color )
    }
  }

  if ( gradient ) {
    let nextGradient = []
    let gradLen = gradient.length

    gradient.forEach( ( stop, i ) => {
      let item = new Colour( color )
      if ( isNumber( stop ) ) {
        computeColourOffsets( item, offsets, stop )
      } else {
        item.set( stop )
      }
      let stopColor = item.toHexString()
      let stopOpacity = item.getChannel( 'alpha' )
      nextGradient[i] = { stopColor, stopOpacity }
    })
    gradient = nextGradient
    color = `url(#${gradientId})`
  } else {
    color = color.toCSS()
  }

  return {
    color,
    gradient,
  }
}

function applyShadingToColour( color, shading = 1 ) {

  let offsets = {
    value: -0.1
  }

  if ( isNumber( shading ) ) {
    computeColourOffsets( color, offsets, shading )
  }

  if ( isString( shading ) ) {
    shading = { color: shading }
  }

  if ( isObject( shading ) ) {
    if ( shading.color ) {
      color.set( shading.color )
    } else if ( shading.offset ) {
      computeColourOffsets( color, shading.offset, 1 )
    }
  }

  return color
}

function computeShadingToCSSGradient( baseColour, shading, gradient, offset ) {
  const gradientAngle = `135deg`
  const stopCount = gradient.length
  const stops = []


  gradient.forEach( ( stop, i ) => {
    let color = new Colour( baseColour )
    let stopPos = `${i / (stopCount-1) * 100}%`
    if ( isNumber( stop ) ) {
      computeColourOffsets( color, offset, stop )
    } else if ( stop ) {
      applyShadingToColour( color, stop )
    }
    let stopColor = color.toCSS()
    stops.push( `${stopColor} ${stopPos}` )
  })

  const result = `linear-gradient(${gradientAngle}, ${stops.join(', ')})`
  return result
}

function computeShadingToCSS( baseColour, shading ) {
  let color = new Colour( baseColour )
  let offset = shading && isObject( shading ) && shading.shift
  let gradient = shading && shading.gradient

  applyShadingToColour( color, shading )

  if ( gradient ) {
    color = computeShadingToCSSGradient( baseColour, shading, gradient, offset )
  } else {
    color = color.toCSS()
  }
  return color
}


function computeShadingToStyle( baseColour, shading, props ) {
  const { fill, stroke, text } = props

  const style = {}

  if ( isDefined( fill ) ) {
    style.background = computeShadingToCSS( baseColour, fill )
    // applyBackgroundToStyle( style, fill )
  } 

  if ( isDefined( stroke ) ) {
    const { strokeWidth = 1 } = props
    style.border = `${strokeWidth}px solid ${computeShadingToCSS( baseColour, stroke )}`
  }

  if ( isDefined( text ) ) {
    style.color = computeShadingToCSS( baseColour, text )
  }


  return style
}