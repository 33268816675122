import { useEffect, useState } from "react"

import { MutantNode } from "trancer"
import { useStateMeta, useStateValue } from "./reactState"

export const useBlockpartyState = ( state ) => {

  const meta = useStateMeta( state )
  const value = useStateValue( state )
  const [ trancerState ] = useState( () => { 
    const node = new MutantNode() 
    // node.update( value )
    return node
  })

  useEffect( () => {
    const onState = ( ) => {
      trancerState.update( state.get(), { type: 'initial' } )
    }
    state.sub( onState )

    onState()
    return () => state.unsub( onState )
  }, [ state ] )


  useEffect( () => {
    const onWrapped = ( event ) => {
      if ( event.type == 'initial' ) return 
      console.log('onWrapped', event.type )
      const { target } = event
      const { path, value } = target

      console.log('onWrapped', path, value, state )

      state.nav( path ).merge( value )
    }
    trancerState.on("*", onWrapped )
    return () => trancerState.off( "*", onWrapped )
  }, [ trancerState ] )


  return {
    meta,
    value,
    trancerState,

  }
}