import { useEffect, useState } from "react"
import { useSurfListener } from "../context.jsx"

export function DataHandler( {
  path = '/',
  delay = 10,
  onData,
} ) {

  const { state, emitEvent } = useSurfListener( { path, delay, type:'change' }, ( event ) => {
    const { value } = event
    onData && onData( value, event )
  } )


}