import React, { Children, cloneElement, createContext, isValidElement, useContext, useEffect, useState } from "react"
import { StateRoot, MutantNode } from "./mutant"
import { useDebouncedCallback } from 'use-debounce'

const { stringify } = JSON

type MutantContextProps = {
  state?:MutantNode,
  value:any,
  children?:React.ReactNode,
}


const MutantContext = createContext( null )

export function useMutantContext( props ) {
  let  {
    state,
    path,
  } = props
  const stateContext = useContext( MutantContext )
  const [ stateDefault ] = useState( () => new StateRoot() )
  state = state || stateContext || stateDefault

  if ( path ) {
    state = state.root.nav( path )
  }

  return state
}

export type useStateEventProps = {
  state: MutantNode,
  type?: string,
}


export function useStateEvent( props:useStateEventProps, callback ) {

  const {
    state,
    type = '*',
  } = props

  useEffect( () => {
    state.on( type, callback )
    return () => {
      state.off( type, callback )
    }
  } )
}

export function useMutantValue( { value:defaultValue, onValue, state, path, debounce = 0 } ) {

  state = useMutantContext( { state, path } )
  state.defaults( defaultValue, { type: 'init' } )
  const [ value, setValueState ] = useState( () => state.get() )

  const debounced = useDebouncedCallback( () => {
    const nextValue = state.get()
    if ( nextValue != value )
      setValueState( nextValue )

    onValue && onValue( nextValue )
  }, debounce )

  const setValue = ( value ) => {
    state.update( value, { type: 'change' } )
  }

  useStateEvent( { state }, debounced )

  return [ value, setValue ]
}

export function MutantValue( props ) {
  const { path, debounce, event, children, state, onValue:onValueOuter, value:defaultValue, ...remain } = props
  const stateResolved = useMutantContext( { state, path } )

  useEffect( () => {
    stateResolved.update( defaultValue )
  }, [ stateResolved, stringify( defaultValue ) ] )

  const [ value, setValue ] = useMutantValue( { value:defaultValue, onValue:onValueOuter, state:stateResolved, path, debounce } )


  const onValue = ( nextValue ) => {
    if ( nextValue != value ) {
      setValue( nextValue )
      onValueOuter && onValueOuter( nextValue )
    }
  }

  const revisedChildren = Children.map( children, ( child ) => {
    return isValidElement( child ) ? cloneElement( child, { ...remain, ...(child.props), value, onValue } ) : child
  })

  return <MutantContext.Provider value={stateResolved}>
    { revisedChildren }
  </MutantContext.Provider>
}


export function useStateMeta( { state, path } = {} ) {
  state = useMutantContext( { state, path } )
  const [ stateMeta, setStateMeta ] = useState( (state && state.meta) || {} )
  function onStateReset() {
    setStateMeta( { ...state.meta } )
  }

  useEffect( () => {
    function onStateEvent( nil, event ) {
      if ( event == 'reset' )
        onStateReset()
    }

    if ( state ) {
      state.sub( onStateEvent )
      return () => state.unsub( onStateEvent )
    }
  })

  return stateMeta
}
