// Auto-generated by library_common.js - Do not modify;
const { is_Array,is_defined,is_function,is_null,is_empty,is_plain_object,is_arraylike,is_number,for_key_value,new_Promise,ob_map,ob_indexOf,ob_reduce,ob_add,ob_replace,ob_sort,ob_pop,ob_push,ob_getImageData,ob_userData,ob_set_width_height,ob_keys,for_each,for_count,for_mirror_count,new_Symbol,array_create } = require('./object');
const state_root = () => {
  const root = new state_class()
  return root
}

const str_branch = 'branch'

const state_branch_each = ( state, func ) => state.each( func )

const state_value = ( state ) => state.value
const state_nav_value = ( state, path ) => state_value( state_nav( state, path ) )


const state_path = ( state ) => state.path

const state_merge = ( state, value, event ) => state.merge( value, event )

const state_listen = ( state, func ) => state.sub( func )


const state_set = ( state, new_value ) => state.merge( new_value )
const state_emit = ( state, event ) => state.emit( event )


const state_path_delim = '/'
const state_path_is_simple = ( path ) => !ob_includes( path, state_path_delim )
const state_path_join = ( a, b ) => !a ? b : !b ? a : a+state_path_delim+b
const state_path_split = ( path ) => is_null( path ) ? [] : is_Array( path ) ? path : path.split( state_path_delim ).filter( a=>!!a )


const state_nav = ( state, path ) => state.nav( path )
const state_nav_merge = ( state, path, ...args ) => state_merge( state_nav( state, path ), ...args )


const state_meta_assign = ( state, meta_value, path = '' ) => {
  state = state_nav( state, path )
  const { meta } = state
  // ob_assign( meta, meta_value )
  state.meta = { ...meta, ...meta_value }
}

const state_meta_get = ( state, meta_key ) => {
  const { meta } = state
  return meta[meta_key]
}

const state_meta_flatten = ( state, path = '', result = [] ) => {
  const { meta } = state
  state_branch_each( state, ( sub, key ) => state_meta_flatten( sub, state_path_join( path, key ), result )  )
  result.push( { ...meta, path } )

  return result
}


const state_flatten = ( state, path = '', result = [] ) => {
  ob_push( result, [ path, state ] )
  state_branch_each( state, ( sub, key ) => state_flatten( sub, state_path_join( path, key ), result )  )
  return result
}

const str_merge = 'merge'

const state_merge_value = ( state, new_value, event ) => state.merge( new_value, event )


const state_merge_path_value = ( state, path, new_value ) => {
  const target = state_nav( state, path )
  return state_merge_value( target, new_value )
}

const state_listen_call = ( state, event ) => {
  const { listen } = state
  for ( let func of listen ) {
    func( event, state )
  }
}

const state_default = ( state, default_value ) => state.default( default_value )

const state_branch = state => state[str_branch]

const state_each = ( state, func ) => {
  const branch = state_branch( state )

  for( let key in branch ) {
    func( branch[key], key )
  }
}

const state_walk = ( state, func ) => {
  func( state )
  state_each( state, child => state_walk( child, func ) )
}

const state_is_primitive = ( ob ) => is_Array( ob ) ? true : ob == null ? true : 'object' != typeof ob


const arrays_are_equal = ( arg0, arg1 ) => {
  const count = ob_length( arg0 )

  if ( count != ob_length( arg1 ) ) return false
  for ( let index = 0; index < count; index ++ ) 
    if ( arg0[index] != arg1[index] )
      return false

  return true
}
const state_is_equal = ( a,b ) => 
  a == b ? true :
  is_arraylike( a ) && is_arraylike( b ) ? arrays_are_equal( a, b ) :
  false

const state_option_key = ( state, value = state_value( state ) ) => {
  const { meta } = state
  if ( !meta ) return 
  const { options } = meta 
  if ( !options ) return
  for ( let key in options ) {
    if ( options[key] == value )
      return key
  }
}


class state_class {
  constructor( root, parent, key, val ) {
    const state = this
    state.key = key
    state.branch = {}
    state.meta = {}
    state.subs = []
    state.parent = parent
    state.root = root || state
    state.merge( val )

  }
  
  default( new_value ) {
    const state = this
    if ( !is_null( state.val ) ) 
      return false

    if ( is_plain_object( new_value ) ) {
      let was_applied = false
      for_key_value( new_value, ( key, val ) => {
        was_applied = state.nav( key ).default( val ) || was_applied
      })
      return was_applied
    }

    return state.merge( new_value, 'default' )
  }

  get leaf() {
    return !is_null( this.val ) || is_empty( this.branch )
  }

  get value() {
    return this.get()
  }

  get path() {
    let state = this
    let path = ''
    while( state ) {
      path = state_path_join( state.key, path )
      state = state.parent
    }
  
    return path
  }

  get() {
    const state = this

    if ( state.leaf ) {
      return state.val
    } 
    
    if ( state.cache ) {
      return state.cache
    }
    
    const tree = {}
    state.each( ( sub, key ) => tree[key] = sub.get() )
    return state.cache = tree
  }

  merge( new_value, event, no_bubble ) {
    const state = this
    let value = state.val
    let changed = false

    if ( !state_is_primitive( new_value ) && is_plain_object( new_value ) ) {
      value = null
      for_key_value( new_value, ( key, val ) => {
        const sub = state.nav( key )
        changed = sub.merge( val, event, true ) || changed

      } )
    } else if ( !state_is_equal( value, new_value ) ) {
      value = new_value
      changed = true
    }
  
    if ( changed ) {
      state.val = value
      state.clear()
      state.emit( event )

      if ( !no_bubble )
        state.bubble( event )
    }

    return changed
  }

  clear() {
    let state = this
    do {
      state.cache = null 
    } while( state = state.parent ) 
  }
  
  nav( path ) {
    path = state_path_split( path )
    let state = this
    for_each( path, ( key, index ) => {
      const next = state.branch[key] || new state_class( state.root, state, key )
      state.branch[key] = next
      state = next
    })
    return state
  }

  each( func ) {
    return state_each( this, func )
  }

  flatten() {

  }

  emit( event ) {
    const state = this
    const { subs } = state
    const actual = () =>
      for_each( subs, sub => sub( state, event ) )
    setTimeout( actual, 0 )
  }

  bubble( event ) {
    let target = this
    while( target = target.parent ) {
      target.emit( event )
    }
  }

  sub( func ) {
    const state = this
    const { subs } = state
    ob_push( subs, func )
  }

  unsub( func ) {
    const state = this
    const { subs } = state
    const index = ob_indexOf( subs, func ) 
    if ( index != -1 )
      ob_splice( subs, index, 1 )
  }
}

const ob_truthy = a => !!a

const ob_get_path = ( ob, path ) => {
  const segs = ob_filter( ob_split( path, state_path_delim ), ob_truthy ) 
  const len = ob_length( segs )
  for_count( len, ( index ) => {
    const seg = segs[index]
    const last = index == len - 1
    ob = ob[seg]
    if ( !is_plain_object( ob ) && !last )
      return
  })
  return ob
}

const ob_set_path = ( ob, value, path ) => {
  const segs = ob_filter( ob_split( path, state_path_delim ), ob_truthy ) 
  const len = ob_length( segs )

  assert( len > 0 )

  for_count( len - 1, ( index ) => {
    const seg = segs[index]
    let next = ob[seg]
    if ( !is_plain_object( next )  )
      next = ob[seg] = {}
    ob = next
  })

  const last = segs[len-1]
  ob[last] = value
}



const merge_values = ( ...args ) => {
  let value
  for ( let arg of args ) {
    if ( is_plain_object( arg ) ) {
      let was_plain_object = is_plain_object( value )
      let clone = was_plain_object ? { ...value } : {}

      for ( let key in arg ) {
        let value = arg[key] 
        let existing = ob_get_path( key )
        let next = merge_values( existing, value )
        if ( next != existing ) null
      }

    } else {
      value = arg
    }
  }

  return value
};
module.exports = { state_root,state_value,state_nav,state_path,state_each,state_listen,state_merge_value,state_merge_path_value,merge_values }