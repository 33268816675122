import { useColorMode, Button, Heading, Stack, Flex, Text, Center } from '@chakra-ui/react'
import { styleMainElement } from '../styles'
import { LinkButton } from '../components/LinkButton.jsx'
import { FaBinoculars, FaUser, FaPaintBrush } from 'react-icons/fa'
import SignedInArea from '../components/SignedInArea'
import { StatefulProgram } from '../components/StatefulProgram'
import { Viewport } from '../components/Viewport'

function App() {
  const { colorMode, toggleColorMode } = useColorMode()

  return (<>
    <Viewport background showMenu={false}><StatefulProgram/></Viewport>
    <Center>
    <Flex
      flexDirection="column"
      width="calc( max( 50vw, 320px))"
      height="100vh"
      justifyContent="center"
      placeItems="center"
    >
      <Stack
      {...styleMainElement}

        flexDir="column"
        mb="2"
        justifyContent="left"
        alignItems="left"
      >
        <Heading size='2xl'>Regenerate</Heading>
        <Text>Regenerate is a new application designed for artists to create and apply unique image filters to various media. With an intuitive interface, it encourages artists to experiment with generative filters using live or pre- existing images. G84 is compatible with web and mobile platforms, scalable for NFT generation and live performances, and allows users to combine bespoke and community-created imagery while ensuring proper attribution. It is optimized for NFT minting and generative drops, with planned future updates.</Text>
        
        {/* <SignedInArea> */}
          <LinkButton to={`/project/`} leftIcon={<FaPaintBrush/>}>Start Creating</LinkButton>
          <LinkButton to={`/projects/`} leftIcon={<FaBinoculars/>}>Projects</LinkButton>
        {/* </SignedInArea> */}
      </Stack>
    </Flex>
    </Center>
  </>)
}

export default App
