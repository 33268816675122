import { NumerickConfig } from "numerick"

export interface AxisType {
  direction: DirectionType,
  reversed: boolean,
  vertical: boolean,
  heightKey:string,
  loKey:string,
  hiKey:string,
  coordKey:number,
  numeric:NumerickConfig,
}

type DirectionType = 'up' | 'right' | 'down' | 'left' | 'cw' | 'ccw' | 'in' | 'out' | 'pressure'  | 'pointerId' | 'timeDown'| 'tiltX' | 'tiltY' | 'button' | 'pointerW' | 'pointerH'

interface AxisOptionProps {
  direction?: string,
}

type AxisOptions = string | AxisOptionProps
type AxesOptions = number | AxisOptions[] | AxisOptions

interface AxisResolvedType {
  index: number,
  direction: DirectionType,
  radial: 'angle' | 'distance' | null,
  reversed:boolean,
  vertical:boolean,
  heightKey:string,
  loKey:string,
  hiKey:string,
  coordKey:number,

}

const AxesResolved = Symbol()

function resolveDirection( direction: string ) : DirectionType {
  switch ( direction ) {
    case 'cw':
    case 'ccw':
    case 'out':
    case 'in':
    case 'up':
    case 'left':
    case 'down':
    case 'right':
    case 'pointerId':
    case 'timeDown':
    case 'pressure':
      return direction
  }

  throw new Error(`${direction} is not a valid direction`)
}

const directionToHeightKey = {
  up: 'height',
  down: 'height',
  left: 'width',
  right: 'width',
}

export function resolveAxis( axis:AxisOptions, index:number, numeric?:NumerickConfig ) : AxisResolvedType {
  

  if ( typeof axis == 'string' ) {
    axis = { direction: axis }
  }

  let directionOption = axis && axis.direction
  const direction = resolveDirection( directionOption )

  const radial = 
    direction == 'cw' || direction == 'ccw' ? 'angle' :
    direction == 'in' || direction == 'out' ? 'distance' : 
    null
  const reversed = direction == 'left' || direction == 'up' || direction == 'in' || direction == 'ccw'
  const vertical = direction == 'up' || direction == 'down'
  const heightKey = directionToHeightKey[direction]

  const aKey = vertical ? 'top' : 'left'
  const bKey = vertical ? 'bottom' : 'right'
  
  const [ loKey, hiKey ] = reversed ? [ aKey, bKey ] : [ bKey, aKey ]
  const coordKey = vertical ? 1 : 0

  return {
    index,
    direction,
    radial,
    reversed,
    vertical,
    heightKey,
    loKey,
    hiKey,
    coordKey,
  }
}

type resolveAxesType = {
  mainAxis: AxisResolvedType,
  axes: AxisResolvedType[] 
}

export type resolveAxesProps = {
  axes: AxesOptions,
  numeric?: NumerickConfig,
}

export function resolveAxes( props:resolveAxesProps, directionDefaults : string[] ) : resolveAxesType {
  let { 
    axes = 1, 
    numeric 
  } = props

  if ( !axes ) {
    axes = 1
  }

  if ( typeof axes == 'number' ) {
    axes = new Array( axes ).fill( undefined )
  } else if ( typeof axes == 'string' ) {
    axes = [ axes ]
  }
  const resolved = axes.map( ( axis, index ) => resolveAxis( axis || directionDefaults[index], index, numeric ) )

  const mainAxis = axes[0]

  return { axes:resolved, mainAxis }  
}
