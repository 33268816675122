// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'
import { getAnalytics } from "firebase/analytics"
import { getStorage } from "firebase/storage"
import { getFirestore, connectFirestoreEmulator, setLogLevel } from "firebase/firestore"
import { getDatabase } from "firebase/database"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAd26tJ-e4PUcKxpcTlti33zizf9VBB7G0",
  authDomain: "g84rethunk.firebaseapp.com",
  databaseURL: "https://g84rethunk-default-rtdb.firebaseio.com/",
  projectId: "g84rethunk",
  storageBucket: "g84rethunk.appspot.com",
  messagingSenderId: "332697020675",
  appId: "1:332697020675:web:04e41cef0246bca9bd2136",
  measurementId: "G-WLS807H7RY",
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth( app )
export const firestore = getFirestore( app )
export const functions = getFunctions( app )
// export const database = getDatabase( app )
export const storage = getStorage( app )
export const analytics = getAnalytics( app )


setLogLevel("debug")

import { ref, getDownloadURL } from "firebase/storage"

async function asset_url( link ) {
  return await getDownloadURL(ref(storage, link.substr(1)))
}

export const linkToDownloadURL = async ( link ) => 
  link.substr( 0, 8 ) == '/assets/' ? await asset_url( link )
  : link
