import { ControlInner } from "./control.jsx"

export function TriggerListeners( { children, onTrigger } ) {
  const onPointerDown = ( event ) => {
    onTrigger( event )
  }

  const listeners = { 
    onPointerDown,
  }

  return <ControlInner  {...listeners}>
    { children }
  </ControlInner>
}

