import { isObject } from "../../react/util.js"

export const isString = val => 'string' == typeof val

export const isArray = val => Array.isArray( val )

export const optionToLabel = ( option ) => {
  if ( isString( option ) ) {
    return option
  }

  if ( isObject( option ) ) {
    let { label } = option
    if ( label ) {
      return String( label )
    }
  }
}

export const optionToValue = ( option ) => {
  return option
}

export const optionsLabels = ( options ) => {
  if ( isArray( options ) ) {
    return options.map( optionToLabel )
  } else if ( isObject( options ) ) {
    return Object.keys( options )
  } else {
    return []
  }
}

export const optionsValues = ( options ) => {
  if ( isArray( options ) ) {
    return options.map( optionToValue )
  } else if ( isObject( options ) ) {
    return Object.values( options )
  } else {
    return []
  }
}

export const optionMatchesValue = ( option, value ) => option == value

export const optionsOffsetValue = ( options, value, offset ) => {
  let ind = -1
  const values = optionsValues( options )
  const { length } = values
  for ( let testIndex = 0; testIndex < length; testIndex++ ) {
    if ( optionMatchesValue( values[testIndex], value ) ) {
      ind = testIndex
      break
    }
  }

  ind += offset
  ind = ind % length
  if ( ind < 0 )
    ind += length

  return values[ind]
}