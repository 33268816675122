function dataURLToBlob(dataUrl) {
  const parts = dataUrl.split(',')
  const mimeType = parts[0].split(':')[1].split(';')[0]
  const byteString = atob(parts[1])
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i)
  }
  return new Blob([arrayBuffer], { type: mimeType })
}


async function fileToDataURL( file ) {
  return new Promise( ( resolve, reject ) => {

    const reader = new FileReader()
    reader.onload = ( event ) => {
      const fileData = event.target.result
      resolve( fileData )
    }

    reader.readAsDataURL( file )

  })
}

import { functions } from '../firebase'
import { httpsCallable } from "firebase/functions"
import { useMediaGlobalStore } from './mediaStore'


export async function addMedia( file, fields ) {
  const data = await fileToDataURL( file )
  const addFunction = httpsCallable(functions, 'addMedia')
  const response = await addFunction( { ...fields, data } )

  console.log( 'response', fields, response )
}

export async function uploadToMedia( file, fields = {}, uploadURL = 'https://g84rethunk.web.app/api/media' ) {

  // Create a new FormData object
  const formData = new FormData()
  for ( let key in fields ) {
    formData.append( key, fields[key] )
  }
  // formData.append('target', target )
  // Append the data URL to the form data as a blob
  formData.append('image', file )

  // Send the form data to the API using fetch()
  const response = await fetch(uploadURL, {
    method: 'POST',
    body: formData
  })

  const data = await response.json()

  console.log('Upload successful', data  )
}

function mediaLoadImage( link, resolve, reject ) {

}



export function useMediaSource() {

  const { getCachedInput, updateInputLink } = useMediaGlobalStore()

  return async (link, path) => {
    const input = getCachedInput( path )
    await updateInputLink( input, link )

    const { element } = input

    console.log( 'element', input, element )

    return element


    // return await __source_cache(link, () => new Promise(async (resolve, reject) => {
    //   if (link == '$camera') {
    //     const width = 1920
    //     const height = 1080
    //     const video = document.createElement('video')
    //     video.autoplay = true;
    //     video.controls = true;
    //     video.muted = false;
    //     video.width = width
    //     video.height = height
    //     const box = document.getElementById('unseen')
    //     box.appendChild(video)

    //     if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //       const constraints = { video: { width, height, facingMode: 'user' } };
    //       navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
    //         // apply the stream to the video element used in the texture
    //         video.srcObject = stream;
    //         video.play();
    //         console.log('Video should play')
    //       }).catch(function (error) {
    //         console.error('Unable to access the camera/webcam.', error);
    //       });
    //     } else {
    //       console.error('MediaDevices interface not available.');
    //     }
    //     resolve(video)
    //   } else {

    //   }
  }
}

