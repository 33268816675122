import { useState } from "react"

export function usePointerOverStatus() {
  const [ over, setOver ] = useState( false )
  const overEvents = {
    onMouseEnter: () => setOver( true ),
    onMouseLeave: () => setOver( false ),
  }

  return { over, overEvents }
}

import { useRef } from "react"
import useMeasure from "react-use-measure"
import { mergeRefs } from "react-merge-refs"
import { saturate } from "./shared/math"


export const useBoundsTranslateCartesian = ( { gutter, axes } ) => {
  const [ measureRef, bounds ] = useMeasure( { scroll: false } )
  const elemRef = useRef()
  const boundsRef = mergeRefs( [ elemRef, measureRef ])

  const coordFromEvent = ( event ) => {
    const bounds = elemRef.current.getBoundingClientRect()
    const x = saturate( ( event['clientX'] - bounds['x'] - gutter ) / ( bounds['width'] - gutter * 2 ) )
    const y = saturate( ( event['clientY'] - bounds['y'] - gutter ) / ( bounds['height'] - gutter * 2 ) )
    const coord = []
    axes.map( ( axis:AxisResolvedType, index ) => {
      const { heightKey, reversed } = axis
      let value = 0
      switch( heightKey ) {
        case 'width': value = x; break
        case 'height': value = y; break
      }

      if ( reversed ) value = 1 - value

      coord[index] = value || 0
    })
    return coord
  }

  const eventIsInside = ( event ) => {
    const bounds = measureRef.current.getBoundingClientRect()
    const { clientX, clientY } = event
    const { left, right, top, bottom } = bounds
    if ( clientX < left ) return false
    if ( clientX > right ) return false
    if ( clientY < top ) return false
    if ( clientY > bottom ) return false
    return true
  }

  return {
    bounds,
    coordFromEvent,
    eventIsInside,
    boundsRef
  }
}
