import { sinDeg, cosDeg, PI, asin, floor, abs, min, max, fractDeg, saturate, sqrt, atan2, fract, isRealNumber, round } from './math'

type RadialGeometry = {
  minA:number,
  maxA:number,
  hole:number,
}

const radialGeometryDefaults = {
  minA: 0,
  maxA: 180,
  hole: 0.5,
}




export function isAngleClosed( minA, maxA ) {
  return minA == maxA || abs( maxA - minA ) >= 360
}

export function arcBounds( { hole, minA, maxA } ) {
  
  let minX = Infinity
  let minY = Infinity
  let maxX = -Infinity
  let maxY = -Infinity
  
  const closed = isAngleClosed( minA, maxA )

  const addRad = ( rad, ang ) => {
    const sin = sinDeg( ang ) * rad
    const cos = cosDeg( ang ) * rad
    minX = min( minX, sin )
    minY = min( minY, -cos )
    maxX = max( maxX, sin )
    maxY = max( maxY, -cos )
  }

  if ( closed ) {
    minX = minY = -1
    maxX = maxY = 1
  } else {
    addRad( hole, minA )
    addRad( hole, maxA )

    let ang = minA
    do {
      addRad( 1, ang )
      ang = min( maxA, floor( ang / 90 ) * 90 + 90 )
    } while( ang < maxA && !isNaN( maxA ) )
    addRad( 1, maxA )
  }

  let widX = maxX - minX
  let widY = maxY - minY

  let aspect = ( widX ) / ( widY )
  return { minX, minY, maxX, maxY, widX, widY, aspect }
}

export function resolveRoundingAndOffset( args:OffsetRoundingArgs ) {
  let { 
    round = 4, 
    roundT, roundB, roundL, roundR, 
    roundTL, roundTR, roundBL, roundBR,
    offset = 0, offsetX, offsetY, 
    offsetT, offsetB, offsetL, offsetR,
  } = args || {}

  roundTL = firstNumber( roundTL, roundT, roundL, round )
  roundTR = firstNumber( roundTR, roundT, roundR, round )
  roundBL = firstNumber( roundBL, roundB, roundL, round )
  roundBR = firstNumber( roundBR, roundB, roundR, round )
  offsetT = firstNumber( offsetT, offsetY, offset )
  offsetB = firstNumber( offsetB, offsetY, offset )
  offsetL = firstNumber( offsetL, offsetX, offset )
  offsetR = firstNumber( offsetR, offsetX, offset )
  return { 
    roundTL,
    roundTR,
    roundBL,
    roundBR,
    offsetT,
    offsetB,
    offsetL,
    offsetR,
  }
}

export function firstNumber( ...args ) {
  for ( let arg of args ) {
    if ( isRealNumber( arg ) ) return arg
  }
  return undefined
}



export function resolveRadialArgs( args ) {

  let { minA = radialGeometryDefaults.minA, maxA = radialGeometryDefaults.maxA, hole = radialGeometryDefaults.hole } = args || {}

  return {
    minA,
    maxA,
    hole,
  }
}



export function svgRadialBoxPath( { 
  maxR = 8,
  minR = 0,
  minA = -5,
  maxA = 5,
  roundTL = 4,
  roundTR = 4,
  roundBR = 4,
  roundBL = 4,
  offsetL = 0,
  offsetT = 0,
  offsetR = 0,
  offsetB = 0,
} ) {

  const closed = isAngleClosed( minA, maxA )

  maxR += offsetT
  minR -= offsetB
  minR = max( 0, minR )

  let roundB = roundBL + roundBR
  let perimB = minR * ( maxA - minA ) / 360 * PI * 2
  roundBL = min( roundBL, ( roundBL / roundB * perimB ) || 0 )
  roundBR = min( roundBR, ( roundBR / roundB * perimB ) || 0 )

  let roundT = roundTL + roundTR
  let perimT = maxR * ( maxA - minA ) / 360 * PI * 2
  roundTL = min( roundTL, ( roundTL / roundT * perimT ) || 0 )
  roundTR = min( roundTR, ( roundTR / roundT * perimT ) || 0 )

  const thick = maxR - minR

  let roundL = roundTL + roundBL
  roundTL = min( roundTL, ( roundTL / roundL * thick ) || 0 )
  roundBL = min( roundBL, ( roundBL / roundL * thick ) || 0 )

  let roundR = roundTR + roundBR
  roundTR = min( roundTR, ( roundTR / roundR * thick ) || 0 )
  roundBR = min( roundBR, ( roundBR / roundR * thick ) || 0 )

  const angOff = ( rad, roundSize = 0 ) => asin( roundSize / rad ) / PI * 180

  const pathRadialCoord = ( rad, ang, sideways = 0, sideAng = ang ) => {
    return `${sinDeg( ang )*rad + cosDeg( sideAng ) * sideways } ${-cosDeg( ang ) * rad + sinDeg( sideAng ) * sideways }`
  }
  const pathRadius = ( rad ) => `${rad} ${rad}`

  const boxSideArc = ( rad, minA, maxA, flip = 1 ) => {
    let a
    let result = ''

    for ( a = minA + 45 * flip; ; a += 45 * flip ) {
      a = flip == 1 ? min( maxA, a ) : max( maxA, a )
      result += ` A ${pathRadius( rad )} ${flip == 1 ? '0 0 1' : '1 0 0 '} ${pathRadialCoord( rad, a )}`
      if ( fractDeg( a )  == fractDeg( maxA ) || isNaN( a ) ) break
    }
    return result
  }

  let d = ``
  if ( closed ) {
    d += `M ${pathRadialCoord( maxR, minA) } `
    d += boxSideArc( maxR, minA, minA + 360 )
    d += ' Z'
    if ( minR > 0 ) {
      d += ` M ${pathRadialCoord( minR, minA) } `
      d += boxSideArc( minR, minA, minA - 360, -1 )
      d += ' Z'
    }
  } else {
    const insetOA = max( 0, roundTL - offsetL )

    d += `M ${pathRadialCoord( maxR, minA + angOff( maxR-roundTL, insetOA), 0 )} `

    const insetOB = max( 0, roundTR - offsetR )
    d += boxSideArc( maxR, minA + angOff( maxR-roundTL, roundTL), maxA-angOff( maxR-roundTR, insetOB) )

    if ( offsetR > roundTR )
      d += `L ${pathRadialCoord( maxR, maxA-angOff( maxR-roundTR, insetOB ), max(0,offsetR-roundTR) )}`

    if ( roundTR > 0 )
      d += `A ${pathRadius( roundTR ) } 0 0 1 ${pathRadialCoord( maxR-roundTR, maxA, offsetR, maxA )}`

    if ( minR > 0 ) {

      const insetIB = max( 0, roundBR - offsetR )

      d += `L ${pathRadialCoord( minR+roundBR, maxA, offsetR )}`

      if ( roundBR > 0 )
        d += `A ${pathRadius( roundBR ) } 0 0 1 ${pathRadialCoord( minR, maxA-angOff( minR+roundBR, insetIB ), max(0, offsetR-roundBR ), maxA )}`

      if ( offsetR > roundBR )
        d += `L ${pathRadialCoord( minR, maxA, -max(offsetR-roundBR), maxA )}`

      const insetIA = max( 0, roundBL - offsetL )

      d += boxSideArc( minR, maxA - angOff( minR+roundBL, insetIA), minA+angOff( minR+roundBL, insetIB), -1 )


      if ( offsetL > roundBL )
        d += `L ${pathRadialCoord( minR, minA-angOff( minR+roundBL, insetIA ), -max(0,offsetL-roundBL), minA )}`

      if ( roundBL > 0 )
        d += `A ${pathRadius( roundBL ) } 0 0 1 ${pathRadialCoord( minR + roundBL, minA, -offsetL )}`

    } else {
      d += `L 0 0 `
    }

    d += `L ${pathRadialCoord( maxR - roundTL, minA, -offsetL )}`
    d += `A ${pathRadius(roundTL)} 0 0 1 ${pathRadialCoord( maxR, minA+angOff( maxR-roundTL, insetOA), -max(0,offsetL-roundTL), minA )}`
    d += `Z`
  }

  return d
}