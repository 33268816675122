import { isArray, isDefined, isObject, triggerMatches } from "../util"

export type ResolvedOption = {
  label?:string,
  triggerValue?:any,
  showValue?:boolean,
  icon?:any,
  color?:string,
}

export function resolveOptions( options, { addBlank } = {} ) {
  const result:ResolvedOption[] = []
  if ( addBlank ) {
    result.push( { disabled: true, label: ' ' })
  }
  if ( isArray( options ) ) {
    for ( let key in options ) {
      const option = options[key]
      if ( isObject( option ) ) {
        let {
          value, triggerValue,
          label,
          color,
          icon,
          showValue,
          ...remain
        } = option

        if ( !isDefined( showValue ) )
          showValue = !label

        triggerValue = triggerValue || value
        result.push( {
          triggerValue, label, color, icon,
        })
      } else {
        result.push( {
          triggerValue: option,
        })
      }
    }
  } else if ( isObject( options ) ) {
    for ( let label in options ) {
      result.push( {
        label,
        triggerValue: options[label]
      })
    }
  }

  return result
}

export function optionsMatchIndex( options, value ) {
  for ( const index in options ) {
    const option = options[index]
    const { triggerValue } = option
    if ( triggerMatches( value, triggerValue ) ) {
      return index
    }
  }

  return -1
}