import { useEffect, useRef, useState } from "react"
import { Control, ControlContent } from "../component/control.jsx"
import { styled } from "../styled.js"
import { useSurfControl } from "../context.jsx"
import { isObject } from "../util.js"
import { FaArrowUp, FaArrowDown } from "react-icons/fa"
import { makeColouredVariants } from "../styled.js"

const FormStyled = styled( 'form', {

})

const InputStyled = styled('input', {
  border: 'none !important',
  margin: '0.125em',
  background: 'transparent',
  color: 'inherit',
  '&:focus': {
    outline: 'none !important'
  },
})



function ArrowUp( { onClick } ) {
  return  <EndButtonStyled {...{ onClick } }><FaArrowUp/></EndButtonStyled>
}

const shadeSubbuttonBackground = '1'
const shadeSubbuttonIcon = '6'


const EndButtonStyled = styled('div', {
  padding: '4px',
  textAlign: 'center',
  fontSize: '1em',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  color: 'red',
  ...makeColouredVariants( 
    color => ({ 
      background: `${color}${shadeSubbuttonBackground}`,
      color: `${color}${shadeSubbuttonIcon }`,
    }),
    color => ({ color: `${color}${shadeSubbuttonBackground}`}),
  ),
})

function ArrowDown( { onClick } ) {
  return <EndButtonStyled {...{ onClick } }><FaArrowDown/></EndButtonStyled>
}

const WheelContent = styled('div', {
  flexGrow: 1,
  textAlign: 'center',
  margin: '0',
  padding: '0.25em 0.5em',
  overflowX: 'hidden',
  overflowY: 'scroll',
  maxHeight: '10em',
})

const WheelItem = styled('div', {
  ...makeColouredVariants( 
    color => ({ color: `${color}5`}),
    color => ({ color: `${color}9`}),
    
  ),
})

import { optionsLabels, optionsValues, optionMatchesValue, optionsOffsetValue } from "../../src/func/options.mjs"

export function OptionsWheel( { path, options, ...remain } ) {

  const [ focus, setFocus ] = useState( false )
  const transform =  a => a
  const { state, emitEvent, mergeData } = useSurfControl( { path, transform } )
  const value = state


  const labels = optionsLabels( options )
  const values = optionsValues( options )

  const setFromClick = ( value, event ) => {
    mergeData( value, path )
  }

  const onPrevClick = () => {
    const next = optionsOffsetValue( options, value, -1 )
    mergeData( next, path )
  }

  const onNextClick = () => {
    const next = optionsOffsetValue( options, value, 1 )
    mergeData( next, path )
  }

  const wheelRef = useRef()

  useEffect( () => {
    const { current } = wheelRef

    const wheelOffsetTop = current.offsetTop
    const wheelOffsetHeight = current.offsetHeight

    const { length } = values 
    let scrollTop = NaN
    let scrollBottom = NaN
    const selected = []
    for ( let index = 0; index < length; index ++ ) {
      if ( optionMatchesValue( values[index], value ) ) {
        const elem = current.querySelector(`:nth-child(${index+1})`)
        const { offsetTop, offsetHeight } =  elem
        const offsetBottom = offsetTop + offsetHeight
        scrollTop = isNaN( scrollTop ) ? offsetTop : Math.min( offsetTop )
        scrollBottom = isNaN( scrollBottom ) ? offsetBottom : Math.max( offsetBottom )
      }
    }

    if ( !isNaN( scrollTop ) ) {
      current.scrollTop = ( scrollTop + scrollBottom - wheelOffsetHeight ) / 2 - wheelOffsetTop 
    }

  })


  return (<Control {...remain}>
    <ControlContent>
      <ArrowUp onClick={onPrevClick}/>
      <WheelContent ref={wheelRef}>
        { labels.map( ( label, index ) => {
          return <WheelItem key={index} selected={optionMatchesValue( values[index], value )} onClick={( event )=>setFromClick( values[index], event )}>{label}</WheelItem>
        })}
      </WheelContent>
      <ArrowDown onClick={onNextClick}/>
    </ControlContent>
  </Control>)
}