import { useState } from 'react'
import { Control, ControlContent, ControlInner } from '../component/control.jsx'
import TimelapseCameraComponent from '../component/timelapsecamera.jsx'

function sizeToAspectStyle( size ) {
  const [ width, height ] = size
  const aspect = Math.round( width / height * 1000 ) / 1000
  const result = {}
  if ( aspect )
    result.aspectRatio = `${aspect}`

  return result
}

export default function TimelapseCamera( { uploadURL, options, ...remain } ) {
  const [ size, setSize ] = useState( [0,0] )

  const onSize = ( size ) => {
    setSize( size )
  }
  const aspectStyle = sizeToAspectStyle( size )

  return (<Control {...remain}>
    <ControlContent style={ aspectStyle }>
      <TimelapseCameraComponent onSize={onSize} {...{ uploadURL, options }}/>
    </ControlContent>
  </Control>)

}