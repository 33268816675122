import React from 'react'
import { useRef, useState, useEffect } from "react"

import { Button, IconButton, Switch, Stack, HStack, Select, Grid, Flex, Spacer, GridItem, Box, Image } from '@chakra-ui/react'
import { FaUpload, FaVideo, FaCamera, FaUndo, FaDice, FaDownload } from 'react-icons/fa/index'
import { buttonStyle } from './Controls.jsx'
import { Timestamp, addDoc, collection, query, where, limit, orderBy } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import { extname, basename } from 'path-browserify'

import { useCollection } from 'react-firebase-hooks/firestore'
import { getAuth } from "firebase/auth"


const now = () => new Date().getTime()

async function doCapture( state ) {
  if ( !state || state.get() != '$camera' ) return

  const source = state.parent.nav('source').get()
  console.log('doCapture', source )

  
  var canvas = document.createElement('canvas')
  const box = document.getElementById('unseen')
  box.appendChild(canvas)

  canvas.width = source.videoWidth
  canvas.height = source.videoHeight
  canvas.getContext('2d').drawImage(source, 0, 0, canvas.width,canvas.height)  

  const data = await new Promise( resolve => {
    canvas.toBlob( resolve, 'image/jpeg', 0.9 )
  })

  const time = new Date().toJSON()
  const timestamp = time.substr( 0, 11 )
  const path = `/captured_media/cd${timestamp}/cm${time.substr( 11 ) }`

  const fileRef = ref( storage, path )
  const fileSnap = await uploadBytes( fileRef, data )
  const url = await getDownloadURL( fileSnap.ref )


  const media_data = {
    time_created: Timestamp.fromDate(new Date()),
    url,
    name:`capture@${time}`,
  }
  const mediaCollection = collection( firestore, 'media' )
  const doc = await addDoc( mediaCollection, media_data )

  console.log( 'url', url, state.path)
  state.merge( url, 'captured')
  return url
}

async function actionUploadImage( file ) {

  const auth = getAuth()
  const { currentUser = {} } = auth
  const { uid = '_nouser' } = currentUser || {}

  const mediaCollection = collection( firestore, 'media' )

  const timestamp = new Date().toJSON().substr( 0, 11)
  const path = `media/user/${uid}/${timestamp}/${file.name}`

  const ext = extname( file.name )
  const name = basename( file.name, ext )

  const fileRef = ref( storage, path )
  const fileSnap = await uploadBytes( fileRef, file )
  const url = await getDownloadURL( fileSnap.ref )


  const media_data = {
    time_created: Timestamp.fromDate(new Date()),
    url,
    name,
    file_path: path,
    user_id: uid,
    is_public: true,
  }

  const doc = await addDoc( mediaCollection, media_data )

  return url
}


function MediaList({onSelect}) {
  const hotPicks = query( 
    collection( firestore, 'media' ), 
    orderBy( 'time_created', 'desc' ),
    limit(16) 
  )
  const [value, loading, error] = useCollection(
    hotPicks,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  return (
    <div>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Collection: Loading...</span>}
        {value && (
          <Flex flexWrap={"wrap"}>
            {value.docs.map((doc) => (
              <Box 
                key={doc.id} 
                colorScheme="blackAlpha" 
                onClick={()=>onSelect( doc.data().url)} 
                width={32}
              >
                <Image 
                  src={doc.data().url}     
                  boxSize='150px'
                  objectFit='cover'
                />
              </Box>
            ))}
          </Flex>
        )}
    </div>
  )
}

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'

function SelectMedia({onSelect}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button {...buttonStyle} leftIcon={<FaDownload/>}  onClick={onOpen} color='white'>Select Image</Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Media</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <MediaList onSelect={onSelect}/>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function UploadMedia( { state }) {
  const inputRef = useRef()
  const [file, setFile] = useState(null)
  const [isLoading,setIsLoading] = useState(false)
  function handleChange(e) {
    if (e.target.files[0])
        setFile(e.target.files[0]);
  }

  async function handleUpload(e) {
    inputRef.current?.click()
  }

  useEffect( () => {
    (async () => {
      if ( file ) {
        setIsLoading( true )
        const url = await actionUploadImage( file )
        state.merge( url, 'upload' )
        setFile(null)
        setIsLoading( false )
      }
    })()
  }, [ file ] )

  return <>
    <Button {...buttonStyle} isLoading={isLoading} leftIcon={<FaUpload/>} onClick={handleUpload}>Upload Image</Button>
    <form hidden onSubmit={handleUpload}>
      <input hidden type="file" accept="image/*" ref={inputRef} onChange={handleChange} size={10} />
    </form>
  </>
}


export function MediaControl( { state } ) {
  const { meta } = state

  const [url, setURL] = useState("")
  const [ capturedTime, setCapturedTime ] =  useState( 0 )


  async function onDefault() {
    const url = '/assets/refractionbg_refrac_02.png'
    state.merge( url, 'choose' )
  }

  async function onLiveCamera() {
    state.merge( '$camera', 'choose' )
  }

  async function onCaptureCamera() {
    const parent_state = state.parent
    // parent_state.merge( { link: '$camera', capture: now() }, 'capture')
    doCapture( state )
  }

  return <Stack className="wrap-input" spacing={2}>
    <SelectMedia onSelect={(url)=>state.merge( url, 'choose' )}/>
    <Button {...buttonStyle} leftIcon={<FaDice/>} onClick={onDefault} color='white'>Load Default</Button>
    {/* <Button {...buttonStyle} leftIcon={<FaUndo/>} onClick={onUndo} color='white'>Roll Back</Button> */}
    <UploadMedia state={state}/>
    <Button {...buttonStyle} leftIcon={<FaVideo/>} onClick={onLiveCamera} color='white'>Live Camera</Button>
    <Button {...buttonStyle} leftIcon={<FaCamera/>} onClick={onCaptureCamera} color='white'>Capture Camera</Button>
          

    {/* <RecentMedia onURL={ ( url ) => state.merge( url, 'choose' ) }/> */}
  </Stack>
}


export function MediaCapture( { state, hasCaptureCallback } ) {

  const [ capturedTime, setCapturedTime ] =  useState( 0 )

  async function onDefault() {
    const url = '/assets/refractionbg_refrac_02.png'
    state.merge( url, 'choose' )
  }


  async function doCapture( state ) {
    if ( !state || state.get() != '$camera' ) return

    const source = state.parent.nav('source').get()
    console.log('doCapture', source )

    
    var canvas = document.createElement('canvas')
    const box = document.getElementById('unseen')
    box.appendChild(canvas)

    canvas.width = source.videoWidth
    canvas.height = source.videoHeight
    canvas.getContext('2d').drawImage(source, 0, 0, canvas.width,canvas.height)  

    const data = await new Promise( resolve => {
      canvas.toBlob( resolve, 'image/jpeg', 0.9 )
    })

    const time = new Date().toJSON()
    const timestamp = time.substr( 0, 11 )
    const path = `/captured_media/cd${timestamp}/cm${time.substr( 11 ) }`

    const fileRef = ref( storage, path )
    const fileSnap = await uploadBytes( fileRef, data )
    const url = await getDownloadURL( fileSnap.ref )


    const media_data = {
      time_created: Timestamp.fromDate(new Date()),
      url,
      name:`capture@${time}`,
    }
    const mediaCollection = collection( firestore, 'media' )
    const doc = await addDoc( mediaCollection, media_data )

    console.log( 'url', url, state.path)
    state.merge( url, 'captured')
    return url
  }

  useEffect( () => {
    const capture_state = state.parent.nav('capture')
    const onState = ( state, event ) => {
      const value = state.get()
      console.log('onState', value, capturedTime )
      if ( value > capturedTime ) {
        doCapture()
      }
    }

    capture_state.sub( onState )

    return () => {
      capture_state.unsub( onState )
    }
  })

  hasCaptureCallback( doCapture )

  return null
}
