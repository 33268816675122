import { useEffect, useMemo, useRef, useState } from 'react'
import { saturate, min, max, distance, isRealNumber, floor } from '../shared/math'
import React = require('react')
import { useBoundsTranslateCartesian } from '../pointer'
import { resolveAxes } from '../shared/axes'

import { NumerickConfig, normToNumber, numberToNorm } from 'numerick'

import { styledClasses, StyledSlider, StyledSpan, StyledTrack, StyleInvert } from '../styles'

import { isArray, isString } from '../util'



import { mergeRefs } from 'react-merge-refs'
import { makeColouredVariants, styled } from '../styles'
import { ControlContent, propsToContent } from '../content'
import { useSliderPointerEvents, useSliderPoints, negateCss, axisValueFromCoord } from '../shared/sliderUtils'
import { computeElementsForPoints, useParsedElements } from '../shared/elements'
import { useComponentShading } from '../shades'
import { useTrancerPresets } from '../presets'



type BoundsType = RectReadOnly

interface SliderStylesType {
  track?:string,
  point?:string,
}

interface SubComponentStyling {
  className?: string,
  style?: React.CSSProperties,
}

interface TrackOptionsSelf {
  pointId:number,
}

interface SliderStyleProps {
  sizes?: SizesProps,
}

interface SpanType extends SubComponentStyling {
  pointId?: number,
  axisId?: number,
  sort?: boolean,
  pin?: boolean,
  content?: React.ReactNode,
}

type SlideType = false | 'none' | 'on' | 'off'
type onChangeType = ( value:number[] ) => undefined

interface SliderDataProps {
  defaultValue?: number[],
  onValue?: onChangeType,
}


function unitPx( value ) {
  value += 'px'
  return value
}


function sizesTrackInsetStyle( sizes: SizesProps ) {
  const { 
    gutter = sizesDefault.gutter, 
    track = sizesDefault.track, 
    point = sizesDefault.point, 
  } = sizes
  const tracksBuffer = unitPx( gutter + max( point - track, 0 ) / 2 * 0 )

  return { inset: tracksBuffer }
}

type SliderValueProps = {
  value?: number | number[],
  onValue?:( value:number[] ) => void,
  valueIsArray?: boolean,
}

type SliderPropsType = {

  className?:string,
  axes?:AxesOptions,
  children?:React.ReactNode,
  styles?:SliderStylesType,
  onClick?:( event:React.MouseEvent ) => void,
} & SliderValueProps & ControlPresetProps



import { sliderElementDefaults } from "../presetDefaults"

import styles from '../styleBits.module.css'
import { usePointerOverStatus } from '../pointer'
import { ElementsBoxes } from '../shared/elements'
import { off } from 'process'

export default function Slider ( propsArg:SliderPropsType ) {
  const props = useTrancerPresets( propsArg, 'slider', {
    elements: sliderElementDefaults,
    gutter: 8,
    showValue: false,
  } )

  const {
    value:defaultValue = [0],
    onValue,
    slide,
    numeric,
  } = props
  const { axes, mainAxis } = resolveAxes( props, ['right','down'] )
  const { shadingToStyle } = useComponentShading( props )
  const { elements, gutter } = useParsedElements(  props )
  const { bounds, coordFromEvent, eventIsInside, boundsRef } = useBoundsTranslateCartesian( { gutter, axes } ) 
  const { value, pointCoords, setPointCoord } = useSliderPoints( { value:defaultValue, onValue, axes, numeric } )
  const { pointerEvents, active } = useSliderPointerEvents({ slide, coordFromEvent, setPointCoord, pointCoords, eventIsInside })
  const { over, overEvents } = usePointerOverStatus()
  const contentProps = propsToContent( props, value )


  const { size } = contentProps
  const content = <ControlContent {...contentProps}/>
  let status = {
    over,
    active,
    invert: false,
  }

  let elementsComputed = computeElementsForPoints( { elements, axes, pointCoords, gutter, bounds, status } )

  const sizeStyle = {
    width: '100%',
    height: '3em',
  }

  return <div 
    className={`slider ${styles.slider}`} 
    ref={boundsRef} 
    style={sizeStyle}
    {...pointerEvents}
    {...overEvents}
  >
    <ElementsBoxes  elements={elementsComputed} {...{shadingToStyle, bounds, gutter, content, size }} absolute={true}/>
  </div>
}
