import React, { useState } from 'react'
import { TriggerMode } from './types'

export function useInputListeners( { trigger = TriggerMode.Submit, onInputValue, onInputStatus }) {
  const [ inputStatus, setInputStatus ] = useState( {} )

  const onSubmit = ( event ) => {
    event.preventDefault()

    if ( trigger == TriggerMode.Submit ) 
      onInputValue( value )

    return false
  }

  const onChange = ( event ) => {
    const { target } = event
    const nextValue = target.value

    if ( trigger == TriggerMode.Change ) 
      onInputValue( nextValue )
  }

  const onFocus = ( event ) => {
    const { target } = event
    console.log("onFocus", target.value )
  }

  const onBlur = ( event ) => {
    const { target } = event
    const nextValue = target.value

    if ( trigger == TriggerMode.Blur ) 
      onInputValue( nextValue )


  }


  return {
    inputStatus,
    onSubmit,
    onChange,
    onFocus,
    onBlur,
  }

}