import { Flex, Box,Grid, Heading, IconButton } from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels"
import UIResizeHandle from './ui/UIResizeHandle'
import { styleAppHeader } from "../styles"
import ViewMenu from "./ViewMenu"
import { useLayoutStore } from "../lib/layoutStore"
import { useNavigate } from 'react-router-dom'

const appTitle = 'Regenerate'

export function AppHeader() {
  const navigate = useNavigate()

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      padding={1}
      {...styleAppHeader}
      height="3em"
    >
      <Box>
        <Heading as="h1" size="md" onClick={()=>navigate('/')}>
          { appTitle }
        </Heading>
      </Box>

      <ViewMenu/>
    </Flex>
  )
}




export function UIAppMain( {
  display,
  controls,
} ) {

  const { layout, setLayout } = useLayoutStore()


  const direction = layout == 'bottom' ? 'vertical' : 'horizontal'
  const reverse = layout == 'bottom' || layout == 'right'

  const header = <></>

  const controlsWrapped = <Panel>
    <Box 
      height={"100%"}
      overflowY={"scroll"}
    >
      { controls }
    </Box>
  </Panel>

  const displayWrapped = <Panel>
    { display }
  </Panel>

  return <>
      {/* <AppHeader/> */}
      <Flex height={"calc(100%)"} position={"fixed"} top="0" left="0" right={0}>
        <PanelGroup key={`UIAppMain_${layout}`} autoSaveId={`persistUIAppMain_${layout}`} {...{direction}}>
          { reverse ? displayWrapped : controlsWrapped }
          <UIResizeHandle/>
          { reverse ? controlsWrapped : displayWrapped }
        </PanelGroup>
      </Flex>
  </>
}

export function UIPageSingle( {
  children
} ) {

  return <>
      <AppHeader/>
      <Flex height={"calc(100% - 3em)"} position={"fixed"} top="3em" left="0" right={0}>
        { children }
      </Flex>
  </>
}
