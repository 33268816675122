import { useState } from 'react'
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { useAuthState, useCreateUserWithEmailAndPassword, useSignOut } from 'react-firebase-hooks/auth'
import { app } from '../firebase.js'
const auth = getAuth(app)

import { Link } from 'react-router-dom'

import { FaUserAlt, FaLock } from "react-icons/fa"
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

import { Formik, Form, Field } from 'formik'


import {
  Alert,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  InputRightElement
} from "@chakra-ui/react";

export default function RegisterPage () {
  const [showPassword, setShowPassword] = useState(false)
  const handleShowClick = () => setShowPassword(!showPassword)

  const [ user, authStateLoading, authStateerror ] = useAuthState(auth)
  const [
    createUserWithEmailAndPassword,
    userCreated,
    loading,
    error,
  ] = useCreateUserWithEmailAndPassword(auth)
  console.log({error})

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="blackAlpha.600" />
        <Heading color="magenta.400">Join</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          { user && <Alert>{ user.email }</Alert>}
          { !user && !userCreated && <Formik
            initialValues={{ email:'', password:''}}
            onSubmit={ async ( values, actions ) => {
              console.log( values )
              const { email, password } = values
              await createUserWithEmailAndPassword( email, password )
              actions.setSubmitting(false)
            }}
          >
            {(props)=> (<Form>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="blackAlpha.600"
              boxShadow="md"
            >
              { error && <Alert>{ error.message }</Alert> }
              
              <Field name='email'>
              {({field,form}) => (<FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaUserAlt color="gray.300" />}
                    />
                      
                    <Input {...field}  type="email" placeholder="email address" />
                  </InputGroup>
                  </FormControl>)}
              </Field>
              <Field name='password'>
                {({field,form}) => (<FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<CFaLock color="gray.300" />}
                    />
                    <Input
                      {...field}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                        {showPassword ? "Hide" : "Show"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>)}
              </Field>
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                width="full"
                isLoading={props.isSubmitting}
              >
                Sign Up
              </Button>
            </Stack>
            </Form>)}
            </Formik> }
          </Box>
      </Stack>
      { !user && !userCreated && <Box>
        Already have an account? {" "}
        <Link color="magenta.500" to="/login">
          Log In
        </Link>
      </Box> }
    </Flex>
  )
}