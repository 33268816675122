import { useProjectStore } from "../lib/store"
import { firestore } from '../firebase.js'
import { collection, query, where, limit, orderBy } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'

import { Stack, HStack, Button, IconButton, Flex, Spinner } from "@chakra-ui/react"
import { useNavigate } from 'react-router-dom'
import { FaEdit, FaEye, FaDice } from 'react-icons/fa'
import { LinkButtonStyled } from "../components/LinkButton.jsx"
import { styleButton } from "../styles"

const RecentSnapshots = () => {
  const loadProgram = useProjectStore( state => state.loadProgram )
  const navigate = useNavigate()
  const hotPicks = query( 
    collection( firestore, 'fragments' ), 
    where('app_source','==','g84rethunk'),
    orderBy('created','desc'),
    limit(200) 
  )
  const [value, loading, error] = useCollection(
    hotPicks,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )
  if ( error )
    console.error( error )
  return <>
    <Flex
      flexDirection="row"
      width="calc( max( 50vw, 320px ) )"
      height="100vh"
      pt={14}
      pb={6}
      pl={4}
      flexWrap="wrap"
    >
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <Spinner size='xl' />}
        {value && (
          <Stack>
            {value.docs.map((doc) => {
              const sid = doc.id
              const data = doc.data()
              const { slug_name = '', title = '' } = data || {}
              const name = title || slug_name || sid
              return <HStack key={sid}>
                <Button {...styleButton} onClick={()=>{
                  const { program_name, program_state } = data
                  loadProgram( data )
                }}>{ name }</Button>
              </HStack>
          })}
          </Stack>
        )}
    </Flex>
  </>
}



export default function ViewProjectIndex() {
  return <>
    <RecentSnapshots/>
  </>
}