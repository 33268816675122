import { state_roll_dice } from "G8BlockParty/dist/common_js/state_high.js"
import { useEffect, useState } from "react"
import { FaDice } from "react-icons/fa"

const clockTime = () => ({ now: new Date().getTime() })

export function diceButtonProps( { 
  state,
  rolls = 1,
  repeat = 500,
} ) 
{
  const [ nextTime, setNextTime ] = useState( 0 )

  const trigger = () => {
    state && state_roll_dice( state, rolls )

    const { now } = clockTime()
    setNextTime( now + repeat )
  }

  const cancel = () => {
    setNextTime( 0 )
  }


  useEffect( () => {
    const { now } = clockTime()
    let timer

    if ( nextTime ) {
      if ( nextTime <= now ) {
        trigger()
      } else {
        timer = setTimeout( trigger, now - nextTime )
      }
    }

    return () => timer && clearTimeout( timer )
  } )

  const onPointerDown = () => {
    trigger()
  }

  const onPointerUp = () => {
    cancel()
  }

  return {
    onPointerDown,
    onPointerUp,
  }
}

export function diceButtonIconProps( {} = {} ) {
  return {
    icon: <FaDice/>
  }
}