import {
  Alert,
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  Text,
  FormHelperText,
  InputRightElement
} from "@chakra-ui/react"

export default function ViewPage() {
  return     <Flex
  flexDirection="column"
  width="100wh"
  height="100vh"
  justifyContent="center"
  alignItems="center"
  bg="blackAlpha.200"
  
/>
}